import styled from "styled-components";
import Color from "color";
import { GREY_01, GREEN_01 } from "../../../theme/colors";

export const ListContainer = styled.div`
  padding-left: 19px;
`;

export const ListWrapper = styled.ul`
  padding-bottom: 46px;
  h3 {
    padding-bottom: 12px;
  }
`;

export const ListItem = styled.li`
  position: relative;
  border-top: 1px solid ${() => Color(GREY_01)};
  margin: 0px;
  padding: 12px 0px;
  cursor: pointer;
  list-style-type: none;
  padding-left: ${({ selected }) => selected && "8px"};
  &:before {
    position: absolute;
    display: ${({ selected }) => (selected ? "block" : "none")};
    content: "";
    left: 0px;
    width: 1px;
    height: 21px;
    background-color: ${() => Color(GREEN_01)};
  }
`;

import firebase from 'firebase/app'

export const getUser = () => firebase.auth().currentUser ? firebase.auth().currentUser.uid : null;
export const getTime = () => firebase.firestore.FieldValue.serverTimestamp();

const filter = (doc,allowed) => {
	return Object.keys(doc)
		.filter(key => allowed.includes(key))
		.reduce((obj, key) => {
			return {
				...obj,
				[key]: doc[key]
			};
		}, {});
}

export const prepareDataForCreate = (doc,allowed) => {
	const user = getUser();
	const time = getTime();

	const result = filter(doc,allowed);
	result.metadata = {
		createdBy: user,
		createdTime: time,
		updatedBy: user,
		updatedTime: time
	}

	return result;
}

export const prepareDataForUpdate = (doc,allowed) => {
	const user = getUser();
	const time = getTime();

	const result = filter(doc,allowed);
	result.metadata = {
		updatedBy: user,
		updatedTime: time
	}

	return result;
}

export const prepareDataForDuplicate = (data,allowed) => {
	const user = getUser();
	const time = getTime();

	const result = filter({...data},allowed);
	result.metadata = {
		createdBy: user,
		createdTime: time,
		updatedBy: user,
		updatedTime: time
	}

	return result;
}
import React, {useState, useEffect} from 'react'
import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth';
import get from 'lodash/get';
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  useLocation,
} from "react-router-dom";

import user from '../../actions/user'

import Page from '../../components/layout/page'
import Form from '../../components/layout/form'
import Box from '../../components/layout/box'
import Button from '../../components/form/button'
import TextField from '../../components/form/text-field'
import PageLoading from '../../components/page-loading';
import PageError from '../../components/page-error';

import { useCallouts } from '../../components/callout'
import { useProcessing } from '../../components/processing'

const Login = () => {
	const { t } = useTranslation();
	const [userAuth, userLoading, userError] = useAuthState(firebase.auth());
	const location = useLocation();
	const { addCallout } = useCallouts();
	const { addToQueue, removeFromQueue } = useProcessing();
	const [ pageError, setPageError ] = useState(null);
	const [ linkSent, setLinkSent ] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		initialErrors: {
			email: t("user.validation.email.required"),
		},
		validationSchema: yup.object({
			email: yup.string()
				.required(t("user.validation.email.required"))
				.email(t("user.validation.email.valid")),
		}),
		onSubmit: (values) => {
			const pid = addToQueue();
			user.sendLink(values.email,get(location,"state.from.pathname"))
			.then((result) => {
				removeFromQueue(pid);
				addCallout({
					message:t("user.alert.sendLink.success"),
				})

				setLinkSent(true);
			})
			.catch((error) => {
				removeFromQueue(pid);
				addCallout({
					type: "error",
					message:t("user.alert.sendLink.fail"),
					error: error
				})
			})
		},
	});

	useEffect(() => {
		if(userError){
			setPageError("general");
		}
	},[userError])

	const handleSignOutClick = (event) => {
		user.signOut();
	}

	// LOADING
	if (userLoading) return <PageLoading />;

	// ERROR
	if (pageError) return <PageError heading={t(`error.${pageError}.heading`)} description={t(`error.${pageError}.description`)} />

	if (userAuth) {
		return (
			<Page heading={t("user.heading.alreadySignedIn")} description={t("user.description.alreadySignedIn",{replace:{email:userAuth.email}})} size="small">
				<Box direction="horizontal" mainAxisAlignment="center">
					<Button onClick={handleSignOutClick} label={t("user.action.signOut")} size="large" variant="primary" />
				</Box>
			</Page>
		);
	}

	if(linkSent){
		return (
			<Page heading={t("user.heading.linkSent")} description={t("user.description.linkSent",{replace:{email:formik.values.email}})} size="small">
				
			</Page>
		);
	}

	return (
		<Page heading={t("user.heading.signIn")} description={t("user.description.signIn")} size="small">
			<Form direction="horizontal" onSubmit={formik.handleSubmit}>
				<TextField name="email" value={formik.values.email} placeholder={t("user.label.email")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.email && Boolean(formik.errors.email)} errorMessage={formik.errors.email} />
				<Button type="submit" size="large" variant="primary" disabled={formik.values.email.length <= 0} onClick={formik.handleSubmit} label={t("user.action.sendLink")} />
			</Form>
		</Page>
	);
}  

export default Login
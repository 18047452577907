import styled from "styled-components";

import { unit } from "../../../theme/metrics";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  max-height: 100%;
`;

export const Header = styled.div`
  display: flex;
  padding: 8px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ scroll, padding }) =>
    scroll &&
    `
    ${padding && "padding-bottom: 77px"};
    height: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  transition: all 0.3s;
  ${(props) =>
    props.over ? `background-color: ${props.theme.background.secondary};` : ``}
  cursor: pointer;
  border-radius: ${unit(1)};
  padding: 8px;
`;

export const justifyContent = (align) => {
  switch (align) {
    case "start":
      return "justify-content: flex-start;";
    case "end":
      return "justify-content: flex-end;";
    case "center":
      return "justify-content: center;";
    case "evenly":
      return "justify-content: space-between;";
    case "around":
      return "justify-content: space-around;";
    default:
      return "";
  }
};

export const Cell = styled.div`
  flex: ${({ width }) => !width && "1 1 0"};
  width: ${({ width }) => width && `${width}px`};
  flex-wrap: wrap;
  padding: ${({ padding }) => padding && `${unit(2)} ${unit(3)}`};
  gap: ${({ sort }) => (sort ? unit(0.5) : unit(1))};
  align-items: center;
  ${({ justify }) => justify && justifyContent(justify)};
  cursor: ${({ onClick }) => onClick && "pointer"};
  display: flex;
`;
export const MenuContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

import styled from "styled-components";
import Color from "color";

import { unit } from "../../../theme/metrics";
import { BLACK_01 } from "../../../theme/colors";

export const Container = styled.div`
  position: relative;
  user-select: none;
`;

export const Button = styled.div`
  position: relative;
  min-width: ${unit(2)};
  min-height: ${unit(2)};
  width: ${({ size }) => (size ? unit(size) : unit(2))};
  height: ${({ size }) => (size ? unit(size) : unit(2))};
  border-radius: ${({ size }) => (size && size > 1 ? unit(size / 2) : unit(1))};
  background-color: ${({ background, open }) =>
    background
      ? open
        ? Color(BLACK_01).alpha(0.8)
        : Color(BLACK_01).alpha(0.3)
      : "unset"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.ul`
  position: absolute;
  top: ${unit(-1)};
  left: calc(100% + ${unit(2)});
  z-index: 10;
  max-width: 380px;
  width: ${({ width }) => (width ? unit(width) : "max-content")};
  padding: ${unit(2)};
  border-radius: ${unit(1)};
  background-color: ${Color(BLACK_01).alpha(0.8)};
`;

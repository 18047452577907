import styled, { css } from "styled-components";
import Color from "color";

import { typeText2Regular } from "../../../theme/typography";

import { unit } from "../../../theme/metrics";

import {
  GREEN_WASH,
  GREEN_01,
  GREEN_02,
  GREEN_03,
  GREY_01,
  GREY_02,
  GREY_03,
  WHITE_01,
  DARK_GREY_01,
  BLUE_GREY,
} from "../../../theme/colors";

const primary = (selected, over, down, wash) => css`
  background-color: ${() =>
    wash
      ? GREEN_WASH
      : (selected && !down && GREEN_03) || (over && GREEN_02) || GREEN_01};
  border: 1px solid
    ${({ theme }) =>
      (down && theme.foreground.primary) ||
      (over && GREEN_02) ||
      (selected && GREEN_03) ||
      GREEN_01};
  color: ${({ theme }) =>
    wash ? GREEN_01 : (down && theme.background.primary) || WHITE_01};
  svg {
    path {
      stroke: ${() => wash && GREEN_01};
    }
  }
`;

const tertiary = (selected, over, down) => css`
  background-color: ${WHITE_01};
  color: ${({ theme }) => (selected ? GREEN_03 : theme.foreground.primary)};
  border: 1px solid
    ${() =>
      (selected && GREEN_02) ||
      (down && BLUE_GREY) ||
      (over && GREY_02) ||
      GREY_01};

  svg {
    path {
      stroke: ${() =>
        (selected && GREEN_03) || (over && DARK_GREY_01) || DARK_GREY_01};
    }
  }
`;

const secondary = (selected, over, down) => css`
  background-color: ${({ theme }) =>
    (down && theme.foreground.primary) ||
    (selected && GREY_03) ||
    (over && GREY_02) ||
    Color(GREY_01).alpha(0.3)};
  border: 1px solid
    ${({ theme }) =>
      (down && theme.foreground.primary) ||
      (selected && GREY_03) ||
      (over && GREY_02) ||
      Color(GREY_01).alpha(0.3)};
  color: ${DARK_GREY_01};

  svg {
    path {
      stroke: ${() => DARK_GREY_01};
    }
  }
`;

const transparent = css`
  background-color: transparent;
  border: 1px solid ${GREY_01};
  color: ${WHITE_01};
`;

export const Btn = styled.button`
  ${typeText2Regular}
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  cursor: pointer;
  transition: all 0.3s;
  width: ${({ fitContainer }) => (fitContainer ? "100%" : "")};
  ${({ spaceBefore }) =>
    spaceBefore ? `margin-top: ${unit(spaceBefore)};` : ``}
	${({ spaceAfter }) => (spaceAfter ? `margin-bottom: ${unit(spaceAfter)};` : ``)}
  ${({ variant, selected, over, down, wash }) =>
    (variant === "tertiary" && tertiary(selected, over, down)) ||
    (variant === "primary" && primary(selected, over, down, wash)) ||
    (variant === "secondary" && secondary(selected, over, down)) ||
    (variant === "transparent" && transparent)};
  padding: 0
    ${({ size }) =>
      size === "large" ? unit(3) : size === "small" ? unit(1.5) : unit(2)};
  opacity: ${({ down, variant, disabled }) =>
    (down && variant === "primary") || disabled ? 0.5 : 1};
  height: ${({ size }) =>
    size === "large" ? unit(6) : size === "small" ? unit(4) : unit(5)};

  ${({ roundedTop }) =>
    roundedTop
      ? `
		border-top-left-radius: ${unit(1)};
		border-top-right-radius: ${unit(1)};
	`
      : ""};

  ${({ roundedBottom }) =>
    roundedBottom
      ? `
		border-bottom-left-radius: ${unit(1)};
		border-bottom-right-radius: ${unit(1)};
	`
      : ""};
  &:focus {
    outline: none;
  }
`;

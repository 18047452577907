import styled from "styled-components";

import { typeText2Regular, typeText0Regular } from "../../../theme/typography";
import { unit } from "../../../theme/metrics";

import { RED_01, GREY_02 } from "../../../theme/colors";

export const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
`;

export const Input = styled.textarea`
  ${typeText2Regular}
  flex: 1;
  border-radius: ${() => unit(0.5)};

  border: 1px solid
    ${(props) =>
      props.hasError
        ? RED_01
        : props.variant === "inline"
        ? props.focused
          ? props.theme.foreground.tertiary
          : props.over || props.empty
          ? props.theme.keyline
          : "transparent"
        : props.focused
        ? props.theme.foreground.tertiary
        : props.theme.keyline};

  color: ${(props) =>
    props.hasError
      ? RED_01
      : props.variant === "inline"
      ? props.theme.foreground.secondary
      : props.theme.foreground.secondary};

  color: ${(props) =>
    props.focused
      ? props.theme.foreground.secondary
      : props.hasError
      ? RED_01
      : ""};
  padding: 15px ${unit(2)};
  background-color: ${(props) =>
    props.variant === "inline"
      ? "transparent"
      : props.over && !props.disabled
      ? props.theme.background.secondary
      : `transparent`};
  outline: none;
  resize: none;

  &::placeholder {
    color: ${(props) =>
      props.focused
        ? props.theme.foreground.tertiary
        : props.hasError
        ? RED_01
        : GREY_02};
    opacity: 1;
  }

  &::disabled {
    border: none;
  }
`;

export const ErrorMessage = styled.div`
	${typeText0Regular}
	color: ${RED_01};
	position: absolute;
	top: calc(100% + ${unit(0.25)});
	left: ${unit(1)};
`;

export const pixelate = (value) => `${value}px`;

export const MAX_WIDTH = 1700;
export const UNIT = 8;
export const SPACER = 5 * UNIT;
export const COLUMNS = 16;
export const HEADER_HEIGHT = 7.5;

export const SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
  xlarge: "xlarge",
  max: "max",
};

export const SIZES = [
  SIZE.small,
  SIZE.medium,
  SIZE.large,
  SIZE.xlarge,
  SIZE.max,
];

export const MARGIN = {
  small: 0,
  medium: 0,
  large: 0,
  xlarge: 0,
  max: 0,
};

export const GUTTER = {
  small: 24,
  medium: 40,
  large: 40,
  xlarge: 40,
  max: 40,
};

export const BREAKPOINT = {
  medium: 768,
  large: 1024,
  xlarge: 1400,
  max: 2000,
};

export const mediaQuery = {
  medium: `@media screen and (min-width: ${pixelate(BREAKPOINT.medium)})`,
  large: `@media screen and (min-width: ${pixelate(BREAKPOINT.large)})`,
  xlarge: `@media screen and (min-width: ${pixelate(BREAKPOINT.xlarge)})`,
  max: `@media screen and (min-width: ${pixelate(BREAKPOINT.max)})`,
};

export const breakpoint = (
  size = SIZE.large,
  { pixelate = true } = { pixelate: true }
) => (pixelate ? `${BREAKPOINT[size]}px` : BREAKPOINT[size]);
export const margin = (
  size = SIZE.large,
  { pixelate = true, multiplier = 1 } = { pixelate: true, multiplier: 1 }
) => (pixelate ? `${MARGIN[size] * multiplier}px` : MARGIN[size] * multiplier);
export const gutter = (
  size = SIZE.large,
  { pixelate = true, multiplier = 1 } = { pixelate: true, multiplier: 1 }
) => (pixelate ? `${GUTTER[size] * multiplier}px` : GUTTER[size] * multiplier);
export const unit = (value = 1, { pixelate = true } = { pixelate: true }) =>
  pixelate ? `${value * UNIT}px` : value * UNIT;
export const spacer = (value = 1, { pixelate = true } = { pixelate: true }) =>
  pixelate ? `${value * SPACER}px` : value * SPACER;
export const maxWidth = ({ pixelate = true } = { pixelate: true }) =>
  pixelate ? `${BREAKPOINT[SIZE.max]}px` : BREAKPOINT[SIZE.max];

import React, { useState } from "react";

import Menu from "../../data-display/menu";
import IconButton from "../../form/icon-button";

import { MenuContainer } from "./menu.styles";

const ModalMenu = ({ items, variant, className }) => {
  const [menuActive, setMenuActive] = useState(false);
  const [menuOver, setMenuOver] = useState(false);

  return (
    <MenuContainer className={className}>
      <IconButton
        type="dots"
        color="grey"
        variant={variant ? variant : menuOver ? "primary" : "secondary"}
        selected={menuActive}
        onMouseOver={() => setMenuOver(true)}
        onMouseOut={() => setMenuOver(false)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setMenuActive(!menuActive);
        }}
      />
      {menuActive && (
        <Menu
          onClose={() => setMenuActive(false)}
          verticalAlign="bottom"
          horizontalAlign="left"
          items={items}
        />
      )}
    </MenuContainer>
  );
};

export default ModalMenu;

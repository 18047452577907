import firebase from 'firebase/app'
import { useCollection as useCollectionHook, useCollectionOnce as useCollectionOnceHook, useDocument as useDocumentHook } from 'react-firebase-hooks/firestore';

import { prepareDataForCreate, prepareDataForUpdate } from '../helpers/firestore'
import { writePermissions, readPermissions } from '../helpers/permissions'

import structure from 'shared/data/project'
import project from '../project'

export const FIELDS = ['user','admin','state','role','read','write'];
export const COLLECTION_NAME = 'members';
export const STATE = {
	PENDING: "pending",
	ACTIVE: "active",
	REMOVED: "removed",
	REJECTED: "rejected"
}

const createData = (data = {}) => {
	const userId = firebase.auth().currentUser.uid;

	return prepareDataForCreate({
		user: userId,
		state: STATE.ACTIVE,
		admin: true,
		
		read: readPermissions(structure,true),
		write: writePermissions(structure,true),
		...data,
	},FIELDS)
}

const updateData = (data) => {
	return prepareDataForUpdate(data,FIELDS)
}

export const newDocumentRef = (projectId) => {
	const userId = firebase.auth().currentUser.uid;
	return project.getDocumentRef(projectId).collection(COLLECTION_NAME).doc(userId);
}

export const getDocumentRef = (projectId,memberId) => {
	return project.getDocumentRef(projectId).collection(COLLECTION_NAME).doc(memberId);
}

export const getCollectionRef = (projectId,state) => {
	if(state){
		return project.getDocumentRef(projectId).collection(COLLECTION_NAME).where("state", "==", state)
	}

	return project.getDocumentRef(projectId).collection(COLLECTION_NAME)
}

export const getCollectionGroupRef = () => {
	const userId = firebase.auth().currentUser.uid;
	return firebase.firestore().collectionGroup(COLLECTION_NAME).where("user", "==", userId)
}

export const useCollectionGroup = () => {
	return useCollectionHook(getCollectionGroupRef(),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useDocument = (projectId,memberId) => {
	return useDocumentHook(getDocumentRef(projectId,memberId),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useCollection = (projectId,state) => {
	return useCollectionHook(getCollectionRef(projectId,state),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useCollectionOnce = (projectId,state) => {
	return useCollectionOnceHook(getCollectionRef(projectId,state));
}

export const update = (projectId, memberId, memberData) => {
	return updateDocument(projectId, memberId, memberData);
}

export const remove = (projectId,userId) => {
	const batch = firebase.firestore().batch();
	const projectRef = project.getDocumentRef(projectId);

	batch.update(projectRef,{
		users: firebase.firestore.FieldValue.arrayRemove(userId)
	})

	updateDocument(projectId,userId,{
		state: STATE.REMOVED
	},batch)

	return batch.commit()
}

export const createDocument = (projectId,data,batch) => {
	if(batch){
		return batch.set(newDocumentRef(projectId),createData(data));
	}
	return newDocumentRef(projectId).set(createData(data));
}

export const updateDocument = (projectId,memberId,data,batch) => {
	if(batch){
		return batch.set(getDocumentRef(projectId,memberId),updateData(data),{merge:true});
	}
	return getDocumentRef(projectId,memberId).set(updateData(data),{merge:true});
}
import styled from "styled-components";

import {
  typeText2Regular,
  typeText1Bold,
  typeText0Regular,
} from "../../../theme/typography";
import { unit } from "../../../theme/metrics";

import { GREEN_01, GREY_02, RED_01 } from "../../../theme/colors";

export const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
`;

export const Input = styled.input`
  ${typeText2Regular}
  flex: 1;
  border-radius: 6px;
  border: 1px solid
    ${(props) =>
      props.focused
        ? props.theme.foreground.tertiary
        : props.hasError
        ? RED_01
        : props.theme.keyline};
  color: ${(props) =>
    props.focused
      ? props.theme.foreground.secondary
      : props.hasError
      ? RED_01
      : ""};
  padding: 15px ${unit(2)};
  background-color: ${(props) =>
    props.over ? props.theme.background.secondary : `transparent`};
  outline: none;

  &::placeholder {
    color: ${(props) =>
      props.focused
        ? props.theme.foreground.tertiary
        : props.hasError
        ? RED_01
        : GREY_02};
    opacity: 1;
  }
  ${(props) => (props.disabled ? "cursor: not-allowed" : "")};
  ${(props) =>
    props.start
      ? `padding-left: ${unit(6)}`
      : props.end
      ? `padding-right: ${unit(6)}`
      : ""};
`;

export const Action = styled.span`
	${typeText1Bold}
	color: ${GREEN_01};
	position: absolute;
	right: ${unit(2)};
	top: 50%;
	transform: translateY(-50%);
	user-select: none;
	cursor: pointer;
`;

export const Icon = styled.svg`
  ${(props) =>
    props.start
      ? `
		position: absolute;
		top: 50%;
		left: ${unit(2.25)};
		transform: translate(0, -50%);
	`
      : `
		position: absolute;
		top: 50%;
		right: ${unit(2.25)};
		transform: translate(0, -50%);
	`};

  path {
    stroke: ${(props) => (props.hasError ? RED_01 : ``)};
  }
`;

export const ErrorMessage = styled.div`
	${typeText0Regular}
	color: ${RED_01};
	position: absolute;
	top: calc(100% + ${unit(0.25)});
	left: ${unit(1)};
`;

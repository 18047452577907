import React from "react";
import PropTypes from "prop-types";

import { Container, Content, Label } from "./action-row.styles";

import Checkbox from "../../form/checkbox";
import Icon from "../../feedback/icon";
import Text from "../../typography/text";

const ActionRow = ({
  name,
  text,
  label,
  size,
  actionType,
  icon,
  theme,
  checked,
  roundedTop,
  roundedBottom,
  borderBottom,
  inputOnly,

  onChange,
  onClick,
}) => {
  return (
    <Container
      size={size}
      theme={theme}
      roundedTop={roundedTop}
      roundedBottom={roundedBottom}
      borderBottom={borderBottom}
      onClick={
        (actionType !== "checkbox" || actionType !== "switch") && onClick
      }
    >
      <Content>
        <Text size={size} color={checked && actionType !== "icon" && "green"}>
          {text}
        </Text>
        {size === "large" && <Label theme={theme}>{label}</Label>}
      </Content>
      {(actionType === "checkbox" || actionType === "switch") && (
        <Checkbox
          name={name}
          onChange={onChange}
          variant={actionType}
          checked={checked}
          inputOnly={inputOnly}
        />
      )}
      {actionType === "icon" && (
        <Icon
          color={theme === "primary" ? "white" : "default"}
          variant={icon}
        />
      )}
    </Container>
  );
};

ActionRow.displayName = "ActionRow";

ActionRow.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  theme: PropTypes.oneOf(["primary", "secondary", "tertiary", "transparent"]),
  icon: PropTypes.oneOf([
    "delete",
    "chevron",
    "align-left",
    "drag",
    "question-mark",
    "logo",
    "graph",
    "thumbs-up",
    "star",
    "add",
    "impact",
    "order",
  ]),
  actionType: PropTypes.oneOf(["none", "icon", "checkbox", "switch"]),
  checked: PropTypes.bool,
  roundedTop: PropTypes.bool,
  roundedBottom: PropTypes.bool,
  borderBottom: PropTypes.bool,
  inputOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

ActionRow.defaultProps = {
  size: "large",
  theme: "secondary",
  icon: "",
  actionType: "none",
  checked: false,
  roundedTop: false,
  roundedBottom: false,
  borderBottom: false,
  inputOnly: false,
  onChange: null,
  onClick: PropTypes.func,
};

export default ActionRow;

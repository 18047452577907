import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { Content } from "./text.styles.js";

const Text = forwardRef(
  (
    {
      children,
      display,
      gap,
      size,
      variant,
      align,
      bold,
      color,
      underline,
      opacity,
      spaceBefore,
      spaceAfter,
      ellipsis,
    },
    ref
  ) => {
    return (
      <Content
        gap={gap}
        color={color}
        underline={underline}
        variant={variant}
        display={display}
        spaceBefore={spaceBefore}
        spaceAfter={spaceAfter}
        size={size}
        align={align}
        bold={bold}
        opacity={opacity}
        ellipsis={ellipsis}
      >
        {children}
      </Content>
    );
  }
);

Text.displayName = "Text";

Text.propTypes = {
  gap: PropTypes.number,
  children: PropTypes.node,
  display: PropTypes.oneOf(["block", "inline", "inline-block", "inline-flex"]),
  color: PropTypes.oneOf([
    "black",
    "white",
    "grey",
    "grey-01",
    "grey-02",
    "grey-00",
    "green",
    "red",
  ]),
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  size: PropTypes.oneOf(["large", "medium", "small"]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  bold: PropTypes.bool,
  underline: PropTypes.oneOf(["black", "white", "grey", "green", "red"]),
  opacity: PropTypes.number,
  spaceBefore: PropTypes.number,
  spaceAfter: PropTypes.number,
  ellipsis: PropTypes.bool,
};

Text.defaultProps = {
  display: "block",
  color: undefined,
  variant: "secondary",
  size: "medium",
  align: "left",
  bold: false,
  ellipsis: false,
};

export default Text;

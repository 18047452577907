import React from 'react'
import { ThemeProvider } from 'styled-components'

import { WHITE } from '../../theme/theme'

import { Container, Content, Loader, LoaderPath } from './page-loading.styles.js'

const PageLoading = () => {
	return (
		<ThemeProvider theme={WHITE}>
			<Container>
				<Content>
					<Loader viewBox="25 25 50 50" >
						<LoaderPath cx="50" cy="50" r="20" fill="none" strokeWidth="2" />
					</Loader>
				</Content>
			</Container>
		</ThemeProvider>
	);
}

export default PageLoading
import React from "react";

import Text from "../../../components/typography/text";
import Status from "../../../components/data-display/status";

import { getProjectPath } from "../../../routing/paths";

import { Option } from "./plan-to-learn.styles";
import { BUBBLE_COLOR_SCHEME } from "../../../theme/colors";

export const cellBuilder = (
  projectId,
  assumption,
  columns,
  projectData,
  selectedFilter,
  isOver,
  history
) => {
  const cells = Array(columns.length).fill("");

  let closed = false;
  Object.entries(assumption.values).forEach(([key, value]) => {
    if (key === "status") {
      const toggle = value ? value.toLowerCase() === "closed" : false;
      closed = toggle;
    }
  });
  const opacity = closed ? 0.4 : 1;

  const index = columns.findIndex(({ id }) => id === "id");
  if (index >= 0) cells[index] = <Text opacity={opacity}>{assumption.id}</Text>;

  Object.entries(assumption.values).forEach(([key, value]) => {
    const index = columns.findIndex(({ id }) => id === key);

    if (index >= 0) {
      if (key === "options") {
        cells[index] = value.map(({ value, id }, index) => {
          const filter = projectData.shortlist[selectedFilter]?.ideas.includes(
            id
          );
          return (
            <Option
              color={
                (filter && BUBBLE_COLOR_SCHEME[selectedFilter]) ||
                (isOver && "white") ||
                ""
              }
              key={`${index}${id}`}
              onClick={(event) => {
                event.stopPropagation();
                history.push({
                  pathname: `${getProjectPath(projectId)}/option/${id}`
                });
              }}
            >
              <Text
                color={filter && "white"}
                opacity={filter ? 1 : opacity}
                size="small"
                ellipsis
              >
                {value}
              </Text>
            </Option>
          );
        });
      } else if (key === "status") {
        const toggle = value !== "Closed";
        cells[index] = value ? <Status key="status" toggle={toggle} /> : "";
      } else
        cells[index] = (
          <Text
            opacity={opacity}
            size={key === "assumption" ? "medium" : "small"}
          >
            {value}
          </Text>
        );
    }
  });
  return cells;
};

import React, { useState } from "react";

import Box from "../../../../components/layout/box";

import AddNote from "../../../add-note";
import EditNote from "../../../edit-note";
import AddEntry from "../../../add-entry";

import Scores from "../../scores";
import Comments from "../../comments";
import Notes from "../../notes";

const Fivex = ({
  projectId,
  ideaId,
  projectData,
  memberData,
  ideaData,
  tab,
}) => {
  const [addNote, setAddNote] = useState(null);
  const [editNote, setEditNote] = useState(null);
  const [addEntry, setAddEntry] = useState(null);

  const content = [];

  const projectStructure = projectData?.structure;
  projectStructure.forEach((group) => {
    const { id, type } = group;

    switch (type) {
      case "comments":
        content.push(
          <Comments
            key={id}
            projectId={projectId}
            ideaId={ideaId}
            groupId={id}
            structure={group}
            write={memberData.write[group.id]}
            projectData={projectData}
            ideaData={ideaData}
          />
        );
        break;
      case "notes":
        content.push(
          <Notes
            key={id}
            projectId={projectId}
            ideaId={ideaId}
            groupId={id}
            structure={group}
            write={memberData.write[group.id]}
            projectData={projectData}
            ideaData={ideaData}
            onAdd={(event) => setAddNote(event)}
            onEdit={(event) => setEditNote(event)}
          />
        );
        break;
      case "score":
        content.push(
          <Scores
            key={id}
            projectId={projectId}
            ideaId={ideaId}
            groupId={id}
            structure={group}
            write={memberData.write[group.id]}
            projectData={projectData}
            ideaData={ideaData}
            onAdd={(event) => setAddEntry(event)}
          />
        );
        break;
      default:
        content.push(
          <Scores
            key={id}
            projectId={projectId}
            ideaId={ideaId}
            groupId={id}
            structure={group}
            write={memberData.write[group.id]}
            projectData={projectData}
            ideaData={ideaData}
            onAdd={(event) => setAddEntry(event)}
          />
        );
        break;
    }
  });

  return (
    <>
      {content.map((content) => {
        if (content && tab === content.key)
          return (
            <Box
              key={content.key}
              flex="grow"
              crossAxisAlignment="stretch"
              direction="vertical"
            >
              {content}
            </Box>
          );
      })}
      {addNote !== null && (
        <AddNote
          {...addNote}
          onClose={() => setAddNote(null)}
          onComplete={() => setAddNote(null)}
        />
      )}
      {editNote !== null && (
        <EditNote
          {...editNote}
          onClose={() => setEditNote(null)}
          onComplete={() => setEditNote(null)}
        />
      )}
      {addEntry !== null && (
        <AddEntry
          {...addEntry}
          onClose={() => setAddEntry(null)}
          onComplete={() => setAddEntry(null)}
        />
      )}
    </>
  );
};

export default Fivex;

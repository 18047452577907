import React from "react";
import PropTypes from "prop-types";

import get from "lodash/get";

import { BUBBLE_COLOR_SCHEME } from "../../../theme/colors";

import project from "../../../actions/project";

import IconButton from "../../form/icon-button";
import IconButtonGroup from "../../form/icon-button-group";

const Shortlist = ({
  projectId,
  ideaId,
  projectData,
  table,
  border,
  filter,
  selected,
  onChange,
  className,
}) => {
  const Item = ({ id, color }) => {
    const shortlisted = get(projectData, `shortlist[${id}].ideas`, []).includes(
      ideaId
    );

    const toggle = (id) => {
      if (shortlisted) {
        project.removeIdeaFromShortlist(projectId, id, ideaId);
      } else {
        project.addIdeaToShortlist(projectId, id, ideaId);
      }
    };

    if (table)
      return (
        <IconButton
          type={id}
          size="small"
          variant="tertiary"
          color={color}
          selected={shortlisted}
        />
      );

    if (filter) {
      return (
        <IconButton
          type={id}
          size="small"
          disabled={get(projectData, `shortlist.${id}.ideas`, []).length <= 0}
          variant="tertiary"
          color={color}
          selected={id === selected}
          onClick={() => onChange({ selected: id })}
        />
      );
    }
    return (
      <IconButton
        type={id}
        size="small"
        variant="tertiary"
        color={color}
        selected={shortlisted}
        onClick={() => toggle(id)}
      />
    );
  };
  const shortlisted = (id) =>
    get(projectData, `shortlist[${id}].ideas`, []).includes(ideaId);

  if (table)
    return (
      <div className={className}>
        <IconButtonGroup direction="horizontal" border={border}>
          {shortlisted("f") && <Item id="f" color={BUBBLE_COLOR_SCHEME.f} />}
          {shortlisted("i") && <Item id="i" color={BUBBLE_COLOR_SCHEME.i} />}
          {shortlisted("v") && <Item id="v" color={BUBBLE_COLOR_SCHEME.v} />}
          {shortlisted("e") && <Item id="e" color={BUBBLE_COLOR_SCHEME.e} />}
          {shortlisted("x") && <Item id="x" color={BUBBLE_COLOR_SCHEME.x} />}
        </IconButtonGroup>
      </div>
    );

  return (
    <div className={className}>
      <IconButtonGroup direction="horizontal" border={border}>
        <Item id="f" color={BUBBLE_COLOR_SCHEME.f} />
        <Item id="i" color={BUBBLE_COLOR_SCHEME.i} />
        <Item id="v" color={BUBBLE_COLOR_SCHEME.v} />
        <Item id="e" color={BUBBLE_COLOR_SCHEME.e} />
        <Item id="x" color={BUBBLE_COLOR_SCHEME.x} />
      </IconButtonGroup>
    </div>
  );
};

Shortlist.propTypes = {
  projectId: PropTypes.string,
  ideaId: PropTypes.string,
  projectData: PropTypes.shape({
    owner: PropTypes.string,
    scientificName: PropTypes.string,
    displayName: PropTypes.string,
    organisation: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.string),
    structure: PropTypes.arrayOf(PropTypes.object),
    state: PropTypes.string,
    shortlist: PropTypes.object,
    metadata: PropTypes.object,
  }).isRequired,
  table: PropTypes.bool,
  border: PropTypes.bool,
  filter: PropTypes.bool,
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

export default Shortlist;

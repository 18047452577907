import styled, {keyframes} from 'styled-components'

import {ORANGE_01} from '../../theme/colors'

const rotate = keyframes`
	100% {
		transform: rotate(360deg);
	}
`;

const dash = keyframes`
	0% {
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -124;
	}
`;

export const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: scroll;
	background-color: ${props => props.theme.background.primary};
`

export const Content = styled.div`
	
`

export const Loader = styled.svg`
	animation: ${rotate} 2s linear infinite;
	height: 100px;
	width: 100px;
	transform-origin: center center;
	// position: absolute;
	// top: 0;
	// left: 0;
`

export const LoaderPath = styled.circle`
	stroke-dasharray: 150,200;
	stroke-dashoffset: -10;
	animation: ${dash} 1.5s ease-in-out infinite;
	strokeLinecap: round;
	stroke: ${ORANGE_01};
`




import React, {useEffect, useState} from 'react'
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as yup from 'yup';

import profile from '../../actions/profile'

import Modal from '../../components/feedback/modal'
import TextField from '../../components/form/text-field'
import Button from '../../components/form/button'
import Scaffold from '../../components/layout/scaffold'
import AvatarUpload from '../../components/form/avatar-upload'

import { useCallouts } from '../../components/callout'
import { useProcessing } from '../../components/processing'

const MAX_AVATAR_SIZE = 1;

const Onboarding = () => {
	const { t } = useTranslation();
	const { addCallout } = useCallouts()
	const { addToQueue, removeFromQueue } = useProcessing();

	const [avatarURL, setAvatarURL] = useState();

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			organisation: "",
			avatar: null,
		},
		initialErrors: {
			firstName: t("profile.validation.firstName.required"),
			lastName: t("profile.validation.lastName.required"),
			organisation: t("profile.validation.organisation.required"),
		},
		validationSchema: yup.object({
			firstName: yup.string()
				.required(t("profile.validation.firstName.required")),
			lastName: yup.string()
				.required(t("profile.validation.lastName.required")),
			organisation: yup.string()
				.required(t("profile.validation.organisation.required")),
			avatar: yup.string().nullable(),
		}),
		onSubmit: (values) => {
			const pid = addToQueue();
			profile.create(values)
			.then((result) => {
				removeFromQueue(pid);
				addCallout({
					message:t("profile.alert.create.success")
				})
			})
			.catch((error) => {
				removeFromQueue(pid);
				addCallout({
					type: "error",
					message:t("profile.alert.create.fail"),
					error:error
				})
			})
		},
	});

	useEffect(() => {
		if(formik.values.avatar){
			profile.getAvatarURL(formik.values.avatar)
			.then((url) => {
				setAvatarURL(url)
			})
		}
		else{
			setAvatarURL(null)
		}
	},[formik.values.avatar])

	const handleAvatarUpload = (event) => {
		if(event.file.size && event.file.size > MAX_AVATAR_SIZE * 1024 * 1024){
			addCallout({
				message:t("profile.validation.avatar.size",{replace:{max:MAX_AVATAR_SIZE}})
			})
		}
		else {
			const pid = addToQueue();
			profile.uploadAvatar(event.file)
			.then((result) => {
				removeFromQueue(pid);
				formik.setValues({...formik.values, avatar:result.metadata.name})
				addCallout({
					message:t("profile.alert.avatar.success")
				})
			})
			.catch((error) => {
				removeFromQueue(pid);
				addCallout({
					type: "error",
					message:t("profile.alert.avatar.fail"),
					error:error
				})
			})
		}
	}

	const handleAvatarRemove = (event) => {
		formik.setValues({...formik.values, avatar:null})
	}
	
	return (
		<Modal heading={t("profile.heading.create")} description={t("profile.description.create")}>
			<Scaffold spaceBetween={2}>
				<Scaffold spaceBetween={2}>
					<TextField name="firstName" value={formik.values.firstName} placeholder={t("profile.label.firstName")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.firstName && Boolean(formik.errors.firstName)} errorMessage={formik.errors.firstName} />
					<TextField name="lastName" value={formik.values.lastName} placeholder={t("profile.label.lastName")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.lastName && Boolean(formik.errors.lastName)} errorMessage={formik.errors.lastName} />
					<TextField name="organisation" value={formik.values.organisation} placeholder={t("profile.label.organisation")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.organisation && Boolean(formik.errors.organisation)} errorMessage={formik.errors.organisation} />
					<AvatarUpload onUpload={handleAvatarUpload} onRemove={handleAvatarRemove} firstName={formik.values.firstName} lastName={formik.values.lastName} src={avatarURL} label={t("profile.action.avatar")} />
				</Scaffold>
				<Scaffold direction="horizontal" align="end" spaceBetween={1}>
					<Button color="primary" onClick={formik.handleSubmit} label={t("profile.action.create")} disabled={!formik.isValid} />
				</Scaffold>
			</Scaffold>
		</Modal>
	);
}  

export default Onboarding
import styled from 'styled-components'
import Color from 'color';

import { typeText1Regular, typeText4Regular } from '../../../theme/typography'
import { unit } from '../../../theme/metrics'

import { GREEN_01, RED_01, ORANGE_01, DARK_GREY_01, WHITE_01, BLACK_01, BLUE_WASH } from '../../../theme/colors'

export const Container = styled.div`
	${props => props.size === "xlarge" ? typeText4Regular : typeText1Regular}
	display: flex;
	flex: 0 0 ${props => props.size === "xlarge" ? unit(10) : props.size === "large" ? unit(5) : props.size === "small" ? unit(3) : unit(4)};
	align-items: stretch;
	text-align: center;
	justify-content: center;
	color: ${props => props.theme.foreground.primary};
	position: relative;
	overflow: hidden;
	${props => props.onClick ? `cursor: pointer;` : ``}

	background-color: ${props => 
		props.color === "green" ?
			props.selected ? GREEN_01 : Color(GREEN_01).alpha(0.3)
		:
		props.color === "red" ?
			props.selected ? RED_01 : Color(RED_01).alpha(0.3)
		:
		props.color === "orange" ?
			props.selected ? ORANGE_01 : Color(ORANGE_01).alpha(0.3)
		:
			props.selected ? props.theme.foreground.secondary : BLUE_WASH
	};

	color: ${props => 
		props.color === "green" ?
			props.selected ? WHITE_01 : GREEN_01
		:
		props.color === "red" ?
			props.selected ? WHITE_01 : RED_01
		:
		props.color === "orange" ?
			props.selected ? WHITE_01 : ORANGE_01
		:
			props.selected ? props.theme.background.primary : DARK_GREY_01
	};
	
	border: 0;

	width: ${props => props.size === "xlarge" ? unit(10) : props.size === "large" ? unit(5) : props.size === "small" ? unit(3) : unit(4)};
	height: ${props => props.size === "xlarge" ? unit(10) : props.size === "large" ? unit(5) : props.size === "small" ? unit(3) : unit(4)};
	line-height: ${props => props.size === "xlarge" ? unit(10) : props.size === "large" ? unit(5) : props.size === "small" ? unit(3) : unit(4)};
	border-radius: 50%;

	&:focus {
		outline:none;
	}
`

export const ImageContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	${props => props.selected ? `
		:after{
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: ${Color(BLACK_01).alpha(0.3)};
		}
	` : ``}
`

export const Image = styled.img`
	display: block;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	

`
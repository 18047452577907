
export const formatScore = (value,structure) => {
	const isNumber = structure.findIndex((s) => Number.isNaN(parseInt(s.label))) < 0;
	const obj = structure.find((s) => s.value === Math.round(value))

	return value != null ? isNumber ? Math.round(value * 100) / 100 : obj ? obj.label : '' : '';
}

export const formatConfidence= (value,structure) => {
	const isNumber = structure.findIndex((s) => Number.isNaN(parseInt(s.label))) < 0;
	const obj = structure.find((s) => s.value === Math.round(value))

	return value != null ? isNumber ? Math.round(value * 100) / 100 : obj ? obj.label : '' : '';
}
import React, { useEffect, useState, useRef, Fragment } from "react";
import firebase from "firebase/app";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getProjectPath, getIdeaPath } from "../../routing/paths";

import EditIdea from "../edit-idea";

import idea from "../../actions/idea";
import project from "../../actions/project";
import member from "../../actions/member";

import PointModal from "./modal-content";

import { useCallouts } from "../../components/callout";
import { useProcessing } from "../../components/processing";

import Point from "./modal-content/point";
import Fivex from "./modal-content/fivex";
import PlanToLearn from "./modal-content/plan-to-learn";

const Content = ({ projectId, ideaId, projectDoc, memberDoc, ideaDoc }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const projectData = projectDoc.data();
  const memberData = memberDoc.data();
  const ideaData = ideaDoc.data();

  const isPrioritised = projectDoc
    .data()
    ?.prioritised?.find((_) => (_ = ideaId));

  const [editIdea, setEditIdea] = useState(null);
  const [selected, setSelected] = useState({
    label: "Fivex",
    id: "fivex",
    key: "",
  });

  const handleDuplicateIdea = () => {
    const pid = addToQueue();
    idea
      .duplicate(projectId, ideaId)
      .then((id) => {
        removeFromQueue(pid);
        addCallout({
          message: t("idea.alert.duplicate.success"),
        });
        history.push(getIdeaPath(projectId, id));
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("idea.alert.duplicate.fail"),
          error: error,
        });
      });
  };

  const handleArchiveIdea = () => {
    const pid = addToQueue();
    idea
      .archive(projectId, ideaId)
      .then(() => {
        removeFromQueue(pid);
        if (isPrioritised)
          project.addRemoveIdeaFromPrioritised(projectId, ideaId);

        addCallout({
          message: t("idea.alert.archive.success"),
        });
        history.push(
          `${getProjectPath(projectId)}/${t("project.navigation.option.id")}`
        );
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("idea.alert.archive.fail"),
          error: error,
        });
      });
  };

  const handleEditIdea = () => {
    setEditIdea(ideaId);
  };

  const menu = [
    {
      label: t("idea.action.edit"),
      icon: "edit",
      onClick: () => handleEditIdea(projectData.id),
    },
    {
      label: t("idea.action.duplicate"),
      icon: "copy",
      onClick: () => handleDuplicateIdea(projectData.id),
    },
    {
      label: t("idea.action.archive"),
      icon: "bin",
      confirm: "Sure?",
      onClick: () => handleArchiveIdea(projectData.id),
    },
  ];

  const projectStructure = projectData?.structure;

  /* Navigation from lang add projectStructure for list of fivex */
  const navigation = t("idea.modal.navigation", {
    returnObjects: true,
  });
  const index = navigation.findIndex(({ id }) => id === "fivex");
  navigation[index].list = projectStructure?.map(({ id, label }) => ({
    id,
    label,
  }));

  useEffect(() => {
    /* Set default selected to first in projectStructure array */
    if (projectStructure && projectStructure.length)
      setSelected({ ...selected, key: projectStructure[0].id });
  }, []);

  useEffect(() => {
    /* Updates to overview tab from prioritised screen */
    if (history.location.state) {
      const { label, key, id } = history.location.state;
      setSelected({ label, key, id });
      history.replace();
    }
  }, [history.location]);

  const page = () => {
    switch (selected.id) {
      case "fivex":
        return (
          <Fivex
            projectId={projectId}
            ideaId={ideaId}
            projectData={projectData}
            memberData={memberData}
            ideaData={ideaData}
            tab={selected.key}
          />
        );
      case "point":
        return (
          <Point
            projectId={projectId}
            ideaId={ideaId}
            projectData={projectData}
            ideaData={ideaData}
          />
        );
      case "p2l":
        return (
          <PlanToLearn
            projectId={projectId}
            ideaId={ideaId}
            projectData={projectData}
            ideaData={ideaData}
          />
        );

      default:
        return (
          <Fivex
            projectId={projectId}
            ideaId={ideaId}
            projectData={projectData}
            memberData={memberData}
            ideaData={ideaData}
            tab={selected.key}
          />
        );
    }
  };

  return (
    <>
      <PointModal
        heading={ideaData.name}
        menu={menu}
        selected={selected.key}
        navigation={navigation}
        onClick={(label, key, id) => setSelected({ label, key, id })}
        onClose={() => {
          history.push(
            `${getProjectPath(projectId)}/${t("project.navigation.option.id")}`
          );
        }}
        project={{ id: projectId, data: projectData }}
        idea={ideaId}
      >
        {page()}
      </PointModal>

      {editIdea && (
        <EditIdea
          projectId={projectId}
          ideaId={ideaId}
          onClose={() => setEditIdea(null)}
          onComplete={() => setEditIdea(null)}
        />
      )}
    </>
  );
};

const Idea = () => {
  const { t } = useTranslation();
  const userId = firebase.auth().currentUser.uid;
  const { projectId, ideaId } = useParams();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();
  const pid = useRef();

  const [projectDoc, projectLoading, projectError] = project.useDocument(
    projectId
  );
  const [memberDoc, memberLoading, memberError] = member.useDocument(
    projectId,
    userId
  );
  const [ideaDoc, ideaLoading, ideaError] = idea.useDocument(projectId, ideaId);

  useEffect(() => {
    if (projectError || ideaError || memberError) {
      addCallout({
        type: "error",
        message: t("error.general.description"),
        error: projectError || ideaError || memberError,
      });

      return null;
    }
  }, [projectError, ideaError, memberError, addCallout, t]);

  useEffect(() => {
    if (projectLoading && memberLoading && ideaLoading) {
      pid.current = addToQueue();
    } else if (!projectLoading && !memberLoading && !ideaLoading) {
      if (pid.current) {
        removeFromQueue(pid.current);
      }
    }
  }, [projectLoading, memberLoading, ideaLoading, addToQueue, removeFromQueue]);

  if (projectError || ideaError || memberError) {
    return null;
  }

  if (projectDoc && ideaDoc && memberDoc) {
    if (ideaDoc.exists) {
      return (
        <Content
          projectId={projectId}
          ideaId={ideaId}
          projectDoc={projectDoc}
          ideaDoc={ideaDoc}
          memberDoc={memberDoc}
        />
      );
    }
  }

  return null;
};

export default Idea;

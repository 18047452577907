import styled from "styled-components";

import { HEADER_HEIGHT, unit } from "../../../theme/metrics";

import { WHITE_01 } from "../../../theme/colors";

export const Container = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 ${unit(HEADER_HEIGHT)};
  width: 100%;
  background-color: ${WHITE_01};
  padding: 0 ${unit(2)};
  border-bottom: 1px solid ${({ theme }) => theme.keyline};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 24px;
`;

import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  writePermissions,
  readPermissions,
} from "../../actions/helpers/permissions";

import project from "../../actions/project";
import member from "../../actions/member";
import profile from "../../actions/profile";
import user from "../../actions/user";
import invitation from "../../actions/invitation";

import TextField from "../../components/form/text-field";
import Button from "../../components/form/button";
import Modal from "../../components/feedback/modal";
import Box from "../../components/layout/box";
import Popout, {
  PopoutButton,
  PopoutDivider,
  PopoutCheckbox,
} from "../../components/feedback/popout";
import Tabs from "../../components/layout/tabs";
import Text from "../../components/typography/text";
import Avatar from "../../components/form/avatar";
import IconButton from "../../components/form/icon-button";
import Label from "../../components/typography/label";
import Scaffold from "../../components/layout/scaffold";

import { useCallouts } from "../../components/callout";
import { useProcessing } from "../../components/processing";

const Summary = ({ projectId, projectData }) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const formik = useFormik({
    initialValues: {
      displayName: projectData.displayName,
      scientificName: projectData.scientificName,
      organisation: projectData.organisation,
    },
    validationSchema: yup.object({
      displayName: yup
        .string()
        .required(t("project.validation.displayName.required")),
      scientificName: yup
        .string()
        .required(t("project.validation.scientificName.required")),
      organisation: yup.string(),
    }),
    onSubmit: (values) => {
      const pid = addToQueue();
      project
        .update(projectId, values)
        .then((result) => {
          removeFromQueue(pid);
          addCallout({
            message: t("project.alert.update.success"),
          });
        })
        .catch((error) => {
          removeFromQueue(pid);
          addCallout({
            type: "error",
            message: t("project.alert.update.fail"),
            error: error,
          });
        });
    },
  });

  return (
    <Scaffold direction="vertical" spaceBetween={2}>
      <Scaffold direction="vertical" spaceBetween={2}>
        <TextField
          name="displayName"
          value={formik.values.displayName}
          placeholder={t("project.label.displayName")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          hasError={
            formik.touched.displayName && Boolean(formik.errors.displayName)
          }
          errorMessage={formik.errors.displayName}
        />
        <TextField
          name="scientificName"
          value={formik.values.scientificName}
          placeholder={t("project.label.scientificName")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          hasError={
            formik.touched.scientificName &&
            Boolean(formik.errors.scientificName)
          }
          errorMessage={formik.errors.scientificName}
        />
        <TextField
          name="organisation"
          value={formik.values.organisation}
          placeholder={t("project.label.organisation")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          hasError={
            formik.touched.organisation && Boolean(formik.errors.organisation)
          }
          errorMessage={formik.errors.organisation}
        />
      </Scaffold>

      <Scaffold direction="horizontal" align="end">
        <Button
          color="primary"
          variant="primary"
          label={t("project.action.update")}
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
        />
      </Scaffold>
    </Scaffold>
  );
};

const Member = ({
  projectId,
  userId,
  memberId,
  memberData,
  projectData,
  currentMemberData,
  projectOwnerId,
}) => {
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();
  const { t } = useTranslation();
  const [profileDoc] = profile.useDocument(userId);
  const [menuActive, setMenuActive] = useState(false);
  const [menuOver, setMenuOver] = useState(false);

  const [avatar, setAvatar] = useState();
  const [avatarURL, setAvatarURL] = useState();

  useEffect(() => {
    if (avatar) {
      profile.getAvatarURL(avatar).then((url) => {
        setAvatarURL(url);
      });
    } else {
      setAvatarURL(null);
    }
  }, [avatar]);

  useEffect(() => {
    if (profileDoc) {
      let d = profileDoc.data();
      if (d) {
        setAvatar(d.avatar);
      }
    }
  }, [profileDoc]);

  const handleRemoveClick = () => {
    const pid = addToQueue();
    member
      .remove(projectId, userId)
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("member.alert.remove.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("member.alert.remove.fail"),
          error: error,
        });
      });
  };

  const handleOwnerClick = () => {
    const pid = addToQueue();
    project
      .updateOwner(projectId, userId)
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("project.alert.owner.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("project.alert.owner.fail"),
          error: error,
        });
      });
  };

  const handleAdminChange = (event) => {
    const pid = addToQueue();
    member
      .update(projectId, memberId, { admin: event.checked })
      .then((result) => {
        removeFromQueue(pid);
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("member.alert.update.fail"),
          error: error,
        });
      });
  };

  const handlePermissionChange = (event, id) => {
    const value = { ...memberData.write };
    value[id] = event.checked;

    const pid = addToQueue();
    member
      .update(projectId, memberId, { write: value })
      .then((result) => {
        removeFromQueue(pid);
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("member.alert.update.fail"),
          error: error,
        });
      });
  };

  if (profileDoc) {
    const profileData = profileDoc.data();
    const currentUserId = user.getCurrentId();

    const showMenu = currentMemberData.admin === true;
    const showAdmin =
      currentUserId !== userId &&
      userId !== projectOwnerId &&
      currentMemberData.admin === true;
    const showRemove =
      currentUserId !== userId &&
      userId !== projectOwnerId &&
      currentMemberData.admin === true;
    const showOwner =
      currentUserId !== userId && currentUserId === projectOwnerId;

    return (
      <Box flex="grow">
        <Avatar
          firstName={profileData.firstName}
          lastName={profileData.lastName}
          src={avatarURL}
          size="large"
        />
        <Box
          direction="vertical"
          marginLeft={2}
          flex="grow"
          mainAxisAlignment="center"
        >
          <Text variant="secondary">{`${profileData.firstName} ${profileData.lastName}`}</Text>
          <Text variant="tertiary" size="small">
            {profileData.organisation}
          </Text>
        </Box>
        {showMenu && (
          <Box flex="shrink" position="relative">
            <IconButton
              type="dots"
              color="grey"
              variant={menuOver ? "primary" : "secondary"}
              selected={menuActive}
              onClick={() => setMenuActive(true)}
              onMouseOver={() => setMenuOver(true)}
              onMouseOut={() => setMenuOver(false)}
            />
            {menuActive && (
              <Popout onClose={() => setMenuActive(false)}>
                {showAdmin && (
                  <PopoutCheckbox
                    label={t("member.label.admin")}
                    help={t("member.help.admin")}
                    checked={memberData.admin}
                    onChange={(event) => handleAdminChange(event)}
                  />
                )}
                {projectData.structure.map((group, index) => (
                  <PopoutCheckbox
                    key={group.id}
                    name={`write.${group.id}`}
                    label={group.label}
                    help={t("member.help.permission", {
                      replace: { group: group.label },
                    })}
                    checked={memberData.write[group.id]}
                    onChange={(event) =>
                      handlePermissionChange(event, group.id)
                    }
                  />
                ))}
                {(showRemove || showOwner) && <PopoutDivider />}
                {showRemove && (
                  <PopoutButton
                    label={t("member.action.remove")}
                    onClick={handleRemoveClick}
                  />
                )}
                {showOwner && (
                  <PopoutButton
                    label={t("member.action.owner")}
                    onClick={handleOwnerClick}
                  />
                )}
              </Popout>
            )}
          </Box>
        )}
      </Box>
    );
  }

  return null;
};

const Members = ({ projectId, projectData, membersData }) => {
  // const [ snapshot ] = member.useCollection(projectId,"active");
  const currentUserId = user.getCurrentId();

  if (membersData && membersData.length > 0) {
    const currentMemberData = membersData.find(
      (member) => member.id === currentUserId
    );

    return (
      <Scaffold direction="vertical" spaceBetween={1} spaceAfter={1}>
        {membersData.map((member) => {
          return (
            <Member
              key={member.id}
              memberId={member.id}
              projectId={projectId}
              userId={member.user}
              memberData={member}
              projectData={projectData}
              currentMemberData={currentMemberData}
              projectOwnerId={projectData.owner}
            />
          );
        })}
      </Scaffold>
    );
  }

  return null;
};

const Invitation = ({
  projectId,
  invitationId,
  projectData,
  invitationData,
}) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();
  const [menuActive, setMenuActive] = useState(false);
  const [menuOver, setMenuOver] = useState(false);

  const handleUninviteClick = () => {
    setMenuActive(false);

    const pid = addToQueue();
    invitation
      .uninvite(invitationId)
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("invitation.alert.uninvite.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("invitation.alert.uninvite.fail"),
          error: error,
        });
      });
  };

  const handleResendClick = () => {
    setMenuActive(false);

    const pid = addToQueue();
    invitation
      .resend(invitationId)
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("invitation.alert.resend.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("invitation.alert.resend.fail"),
          error: error,
        });
      });
  };

  const handleAdminChange = (event) => {
    const pid = addToQueue();
    invitation
      .update(invitationId, { admin: event.checked })
      .then((result) => {
        removeFromQueue(pid);
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("invitation.alert.update.fail"),
          error: error,
        });
      });
  };

  const handlePermissionChange = (event, id) => {
    const value = { ...invitationData.write };
    value[id] = event.checked;
    const pid = addToQueue();
    invitation
      .update(invitationId, { write: value })
      .then((result) => {
        removeFromQueue(pid);
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("invitation.alert.update.fail"),
          error: error,
        });
      });
  };

  return (
    <Box flex="grow">
      <Avatar firstName={invitationData.email} size="large" />
      <Box
        direction="vertical"
        marginLeft={2}
        flex="grow"
        mainAxisAlignment="center"
      >
        <Text variant="secondary">{invitationData.email}</Text>
        <Text variant="tertiary" size="small">
          {invitationData.metadata.createdTime
            ? moment(invitationData.metadata.createdTime.toDate()).fromNow()
            : null}
        </Text>
      </Box>
      <Box flex="shrink" position="relative">
        <IconButton
          type="dots"
          color="grey"
          variant={menuOver ? "primary" : "secondary"}
          selected={menuActive}
          onClick={() => setMenuActive(true)}
          onMouseOver={() => setMenuOver(true)}
          onMouseOut={() => setMenuOver(false)}
        />
        {menuActive && (
          <Popout onClose={() => setMenuActive(false)}>
            <PopoutCheckbox
              label={t("member.label.admin")}
              help={t("member.help.admin")}
              checked={invitationData.admin}
              onChange={(event) => handleAdminChange(event)}
            />
            {projectData.structure.map((group, index) => (
              <PopoutCheckbox
                key={group.id}
                name={`write.${group.id}`}
                label={group.label}
                help={t("member.help.permission", {
                  replace: { group: group.label },
                })}
                checked={invitationData.write[group.id]}
                onChange={(event) => handlePermissionChange(event, group.id)}
              />
            ))}
            <PopoutDivider />
            <PopoutButton
              label="Resend invitation"
              onClick={handleResendClick}
            />
            <PopoutButton label="Uninvite" onClick={handleUninviteClick} />
          </Popout>
        )}
      </Box>
    </Box>
  );
};

const Invitations = ({ projectId, projectData, invitationsData }) => {
  if (invitationsData.length > 0) {
    return (
      <Scaffold direction="vertical" spaceBetween={1} spaceAfter={1}>
        <Label>Pending invites</Label>
        <Scaffold direction="vertical" spaceBetween={1}>
          {invitationsData.map((invitationData) => {
            return (
              <Invitation
                key={invitationData.id}
                projectId={projectId}
                invitationId={invitationData.id}
                projectData={projectData}
                invitationData={invitationData}
              />
            );
          })}
        </Scaffold>
      </Scaffold>
    );
  }

  return null;
};

const Users = ({ projectId, projectData, invitationsData, membersData }) => {
  return (
    <Scaffold direction="vertical" spaceBetween={2}>
      <Members
        projectId={projectId}
        projectData={projectData}
        membersData={membersData}
      />
      {invitationsData && invitationsData.length > 0 && (
        <Invitations
          projectId={projectId}
          projectData={projectData}
          invitationsData={invitationsData}
        />
      )}
      <Invite
        projectId={projectId}
        projectData={projectData}
        invitationsData={invitationsData}
      />
    </Scaffold>
  );
};

const Invite = ({ projectId, projectData, invitationsData }) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();
  const [settingsActive, setSettingsActive] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      admin: false,
      read: readPermissions(projectData.structure, true),
      write: writePermissions(projectData.structure, false),
    },
    initialErrors: {
      email: t("project.validation.email.required"),
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required(t("project.validation.email.required"))
        .email(t("project.validation.email.valid")),
    }),
    validate: (values, props) => {
      const errors = {};

      if (
        invitationsData.findIndex(
          (invitation) => invitation.email === values.email
        ) >= 0
      ) {
        errors.email = t("project.validation.email.used");
      }

      return errors;
    },
    onSubmit: (values) => {
      const pid = addToQueue();
      invitation
        .create(projectId, values)
        .then((result) => {
          removeFromQueue(pid);
          addCallout({
            message: t("invitation.alert.invite.success"),
          });
          reset();
        })
        .catch((error) => {
          removeFromQueue(pid);
          addCallout({
            type: "error",
            message: t("invitation.alert.invite.fail"),
            error: error,
          });
        });
    },
  });

  const reset = () => {
    formik.resetForm();
  };

  return (
    <Scaffold direction="vertical" spaceBetween={2}>
      <Label>{t("invitation.heading.invite")}</Label>

      <Box position="relative">
        <TextField
          name="email"
          value={formik.values.email}
          placeholder={t("invitation.label.email")}
          onAction={(event) => setSettingsActive(true)}
          actionLabel={t("member.action.settings")}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            const value = e.target.value || "";
            formik.setFieldValue('email', value.toLowerCase());
          }}
          hasError={formik.touched.email && Boolean(formik.errors.email)}
          errorMessage={formik.errors.email}
        />
        {settingsActive && (
          <Popout onClose={() => setSettingsActive(false)}>
            <PopoutCheckbox
              name="admin"
              label={t("member.label.admin")}
              help={t("member.help.admin")}
              checked={formik.values.admin}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {projectData.structure.map((group, index) => (
              <PopoutCheckbox
                key={index}
                name={`write.${group.id}`}
                label={group.label}
                help={t("member.help.permission", {
                  replace: { group: group.label },
                })}
                checked={formik.values.write[group.id]}
                onChange={formik.handleChange}
              />
            ))}
          </Popout>
        )}
      </Box>
      <Scaffold direction="horizontal" spaceBetween={1}>
        <Button
          variant="secondary"
          onClick={formik.handleReset}
          label={t("invitation.action.reset")}
        />
        <Button
          variant="primary"
          onClick={formik.handleSubmit}
          label={t("invitation.action.invite")}
          disabled={!formik.isValid}
        />
      </Scaffold>
    </Scaffold>
  );
};

const PageSuccess = ({
  projectId,
  projectDocument,
  invitationsSnapshot,
  membersSnapshot,
  onClose,
}) => {
  const { t } = useTranslation();

  const projectData = projectDocument.data();
  const invitationsData = invitationsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  const membersData = membersSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return (
    <Modal onClose={onClose} heading={t("project.heading.edit")}>
      <Tabs names={[t("project.heading.summary"), t("project.heading.users")]}>
        <Summary projectId={projectId} projectData={projectData} />
        <Users
          projectId={projectId}
          projectData={projectData}
          invitationsData={invitationsData}
          membersData={membersData}
        />
      </Tabs>
    </Modal>
  );
};

const EditProject = ({ id, onClose, onComplete }) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const [projectDocument, projectLoading, projectError] = project.useDocument(
    id
  );
  const [
    invitationsSnapshot,
    invitationsLoading,
    invitationsError,
  ] = invitation.useCollection(id, "pending");
  const [membersSnapshot, membersLoading, membersError] = member.useCollection(
    id,
    "active"
  );

  const pid = useRef();

  useEffect(() => {
    if (projectError || invitationsError || membersError) {
      addCallout({
        type: "error",
        message: t("error.general.description"),
        error: projectError || invitationsError || membersError,
      });
    }
  }, [projectError, invitationsError, membersError, addCallout, t]);

  useEffect(() => {
    if (projectLoading && invitationsLoading && membersLoading) {
      pid.current = addToQueue();
    } else if (!projectLoading && !invitationsLoading && !membersLoading) {
      if (pid.current) {
        removeFromQueue(pid.current);
      }
    }
  }, [
    projectLoading,
    invitationsLoading,
    membersLoading,
    addToQueue,
    removeFromQueue,
  ]);

  if (projectError || invitationsError || membersError) return null;

  if (projectDocument && invitationsSnapshot && membersSnapshot)
    return (
      <PageSuccess
        projectId={id}
        projectDocument={projectDocument}
        invitationsSnapshot={invitationsSnapshot}
        membersSnapshot={membersSnapshot}
        onClose={onClose}
      />
    );

  return null;
};

export default EditProject;

import React from "react";
import PropTypes from "prop-types";

import { Content } from "./divider.styles.js";

const Divider = ({ direction, size, spaceBefore, spaceAfter }) => {
  return (
    <Content
      direction={direction}
      size={size}
      spaceBefore={spaceBefore}
      spaceAfter={spaceAfter}
    />
  );
};

Divider.displayName = "Divider";

Divider.propTypes = {
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
  size: PropTypes.oneOf(["half", "full"]),
  spaceBefore: PropTypes.number,
  spaceAfter: PropTypes.number,
};

Divider.defaultProps = {
  direction: "horizontal",
  spaceBefore: 0,
  spaceAfter: 0,
};

export default Divider;

import React from "react";

const StarIcon = ({ color }) => (
  <svg
    width="100%"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.96822 3.46392L10.1704 7.14761L14.0453 7.1426L10.9134 9.42426L12.1156 13.1079L8.97775 10.8344L5.84585 13.1161L7.03849 9.42927L3.90069 7.15573L7.77558 7.15071L8.96822 3.46392Z"
      stroke={color}
    />
  </svg>
);

export default StarIcon;

import React, { Fragment, Children, useState } from "react";

import Box from "../box";
import Text from "../../typography/text";
import Badge from "../../data-display/badge";
import Divider from "../../layout/divider";

const Option = ({ name, onClick, selected }) => {
  const [over, setOver] = useState(false);

  return (
    <Box
      padding={1}
      onClick={onClick}
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
    >
      <Text
        variant={selected ? "primary" : over ? "primary" : "secondary"}
        underline={selected ? "green" : null}
      >
        {name}
      </Text>
    </Box>
  );
};

export const Navigation = ({ navigation, selected, onClick }) => {
  return (
    <>
      {navigation && (
        <Box fullHeight>
          {navigation.map(({ id, label, badge, divider }, index) => {
            const isSelected = selected === id;
            const padding = index !== 0;

            return (
              <Box spaceBefore={padding ? 2 : 0} fullHeight key={id}>
                <Box onClick={() => onClick(id)}>
                  <Box
                    fullHeight
                    gap={0.5}
                    crossAxisAlignment="center"
                    borderBottom={isSelected}
                    borderColor="green"
                  >
                    <Text color={isSelected ? "grey-00" : "grey-02"}>
                      {label}
                    </Text>
                    {badge && (
                      <Badge value={badge.value} selected={isSelected} />
                    )}
                  </Box>
                  {divider && (
                    <Divider size="half" spaceBefore={2} direction="vertical" />
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

const Tabs = ({ children, names, navigation, selected, onClick }) => {
  const [current, setCurrent] = useState(0);
  const count = Children.count(children);

  if (count === 0) {
    return (
      <Navigation
        navigation={navigation}
        selected={selected}
        onClick={onClick}
      />
    );
  }
  if (count === 1) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Fragment>
      {names && names.length > 1 && (
        <Box margin={-1} paddingBottom={4}>
          {names.map((name, index) => (
            <Option
              key={index}
              name={name}
              selected={index === current}
              onClick={() => setCurrent(index)}
            />
          ))}
        </Box>
      )}
      {count >= 2 &&
        Children.map(children, (child, index) =>
          child !== null && child !== undefined && current === index ? (
            <Box
              key={index}
              flex="grow"
              crossAxisAlignment="stretch"
              direction="vertical"
            >
              {child}
            </Box>
          ) : null
        )}
    </Fragment>
  );
};

export default Tabs;

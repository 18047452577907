import reset from 'styled-reset'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
	${reset}

	html {
		box-sizing: border-box;
	}

	html,body{
		margin: 0;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	a{
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}
`
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useFormik } from "formik";

import {
  setFormik,
  setNestedFormik,
  getNestedParameters,
  setNestedParameterInput,
  setNestedParameters,
  removeNestedParameter,
} from "./helper";

import Text from "../../typography/text";
import TextArea from "../text-area";
import Label from "../../typography/label";
import Heading from "../../typography/heading";
import Icon from "../../feedback/icon";
import MultipleChoice from "../multiple-choice";
import TextField from "../text-field";
import Button from "../button";
import Box from "../../layout/box";
import Divider from "../../layout/divider";
import Dropdown from "../dropdown";

import { DropdownHeader } from "./form-content.styles.js";

import { isEmpty } from "lodash";

const FormInput = ({
  state,
  parameter,
  onClick,
  nestedId,
  parentId,
  formik,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const { id, type, label } = parameter;
  const { handleBlur, handleChange, values, setValues, errors } = formik;
  const value = parameter.nested
    ? values[parentId]?.[nestedId]?.[id]
    : values[id];
  const placeholder = parameter.placeholder ? parameter.placeholder : "";
  const error = parameter.nested
    ? errors[parentId]?.[nestedId]?.[id]
    : errors[id];

  switch (type) {
    case "multiple-choice":
      return (
        <MultipleChoice
          name={id}
          items={parameter.options}
          active={
            parameter.nested
              ? values[parentId]?.[nestedId]?.[id] == null
              : values[id] == null
          }
          selected={value}
          onSelect={(item) => {
            if (parameter.nested) {
              setNestedParameterInput(
                item.value,
                formik,
                id,
                nestedId,
                parentId
              );
            } else setValues({ ...values, [id]: item.value });
          }}
          onBlur={handleBlur}
        />
      );
    case "text-area":
      return (
        <TextArea
          name={id}
          value={value}
          onChange={(_) => {
            if (parameter.nested) {
              setNestedParameterInput(
                _.target.value,
                formik,
                id,
                nestedId,
                parentId
              );
            } else handleChange(_);
          }}
          onBlur={handleBlur}
          placeholder={placeholder}
          hasError={error}
          errorMessage={error}
          rows={4}
        />
      );
    case "text-input":
      return (
        <TextField
          name={id}
          value={value}
          onChange={(_) => {
            if (parameter.nested) {
              setNestedParameterInput(
                _.target.value,
                formik,
                id,
                nestedId,
                parentId
              );
            } else handleChange(_);
          }}
          onBlur={handleBlur}
          placeholder={placeholder}
          hasError={error}
          errorMessage={error}
        />
      );
    case "dropdown":
      return (
        <Dropdown
          label={`Add ${label}`}
          type="input"
          open={dropdown}
          onClick={() => setDropdown(!dropdown)}
          content={parameter.options.map(({ label, id, value }) => ({
            text: label,
            name: id,
            size: "medium",
            theme: "secondary",
            onClick: () => {
              setDropdown(false);
              if (parameter.nestedParameters) {
                onClick(setNestedParameters(state, label, value));
              } else setValues({ ...values, [id]: value });
            },
          }))}
        />
      );
    default:
      break;
  }
};

const NestedFormContent = ({
  modal,
  state,
  onClick,
  index,
  formik,
  content,
  parentId,
}) => {
  const { id, label, collapse } = modal;
  return (
    <Box direction="vertical" marginBottom={3}>
      <DropdownHeader>
        <Heading size={2} spaceAfter={3} color="dark-grey">
          {label}
        </Heading>
        <Icon
          onClick={() => {
            const arr = [...state];
            arr.splice(index, 1);
            onClick(arr);
            removeNestedParameter(formik, id, parentId);
          }}
          variant="delete"
        />
        {state.length > 1 && (
          <Icon
            onClick={() => {
              const arr = [...state];
              arr[index].collapse = !collapse;
              onClick(arr);
            }}
            variant="chevron"
            position={collapse ? "down" : "up"}
          />
        )}
      </DropdownHeader>
      {collapse && <Divider />}
      {!collapse && (
        <Box direction="vertical" gap={3}>
          {content.map((_) => {
            if (_) {
              const { id, label, divider } = _;
              return (
                <Box direction="vertical" key={id}>
                  <Label variant="tertiary" spaceAfter={1}>
                    {label}
                  </Label>
                  <FormInput
                    parameter={_}
                    formik={formik}
                    nestedId={modal.id}
                    parentId={parentId}
                  />
                  {divider && <Divider spaceBefore={3} />}
                </Box>
              );
            }
          })}
        </Box>
      )}
    </Box>
  );
};

const FormContent = ({ data, parameters, onSubmit }) => {
  /* NOTE: Current state allows for one parameter with nestedParameters per modal */
  const [nestedModal, setNestedModal] = useState([]);

  const nested = parameters.find(({ nested }) => nested === "parent");

  /* Show error if JSON contains more than one parameter with nestedParameters */
  const error =
    parameters.filter(({ nested }) => nested === "parent").length > 1;

  /* Sets initialValues and validationSchema based on values passed from parameters */
  /* If parameters contains a set of nestedParameters it uses the setNestedFormik function */
  const { initial, validation } = nested
    ? setNestedFormik(parameters, nested)
    : setFormik(parameters);

  const initialValues = data ? data : initial;
  const validationSchema = validation;

  useEffect(() => {
    if (data && nested) {
      const modal = getNestedParameters(initialValues[nested.id], nested);
      setNestedModal(modal);
    }
  }, []);

  useEffect(() => {
    /* Expand modal */
    if (nestedModal.length === 1 && nestedModal[0].collapse === true) {
      const modal = { ...nestedModal[0], collapse: false };
      setNestedModal([modal]);
    }
  }, [nestedModal]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const { isValid, isSubmitting, errors, values, handleSubmit } = formik;

  const isFormValid =
    isValid &&
    !isSubmitting &&
    !Object.entries(values).every(([_, value]) => {
      if (typeof value === "object") {
        return !Object.keys(value).length;
      } else return !value;
    }) &&
    isEmpty(errors);

  return error ? (
    <Box direction="vertical">
      <Text>Unable to display modal. To many nested parameters.</Text>
    </Box>
  ) : (
    <Box direction="vertical" gap={3}>
      {parameters.map((parameter) => {
        if (parameter) {
          const { id, type, label, nested, divider } = parameter;
          return (
            <Box direction="vertical" key={id}>
              {type !== "dropdown" && (
                <Text variant="tertiary" spaceAfter={1}>
                  {label}
                </Text>
              )}
              {nested &&
                nested === "parent" &&
                nestedModal?.map((modal, index) => (
                  <NestedFormContent
                    key={id}
                    modal={modal}
                    state={nestedModal}
                    onClick={(_) => setNestedModal(_)}
                    index={index}
                    formik={formik}
                    content={parameter.nestedParameters}
                    parentId={id}
                  />
                ))}
              <FormInput
                state={nestedModal}
                parameter={parameter}
                onClick={(_) => setNestedModal(_)}
                formik={formik}
              />
              {divider && <Divider spaceBefore={3} />}
            </Box>
          );
        }
      })}
      <Button
        variant="primary"
        onClick={handleSubmit}
        label="Save"
        disabled={!isFormValid}
        fitContainer
      />
    </Box>
  );
};

FormContent.propTypes = {
  data: PropTypes.object,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf([
        "text-input",
        "text-area",
        "dropdown",
        "text-area",
      ]),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          id: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      placeholder: PropTypes.string,
      nested: "parent",
      nestedParameters: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
          type: PropTypes.oneOf([
            "text-input",
            "text-area",
            "dropdown",
            "text-area",
          ]),
          nested: "child",
          placeholder: PropTypes.string,
        })
      ),
    })
  ),
};

export default FormContent;

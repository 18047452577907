import React, { useEffect } from 'react'
import Bugsnag from '@bugsnag/js'

import Page from '../layout/page'
import Text from '../typography/text'

const PageError = ({
	heading,
	description,
	error
}) => {
	useEffect(() => {
		if(error){
			Bugsnag.notify(error)
			console.error(error);
		}
	},[error])

	return (
		<Page heading={heading}>
			<Text align="center">{description}</Text>
			{error && <Text size="small" align="center">{`${error.name}: An error with the code ${error.code} occured`}</Text>}
		</Page>
	);
}

export default PageError
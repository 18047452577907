import styled from 'styled-components'

import {ORANGE_01} from '../../theme/colors'

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 3px;
	z-index: 100;
`

export const Bar = styled.div`	
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: ${ORANGE_01};
`
import React from "react";

const GraphBackground = () => (
  <div
    style={{
      position: "absolute",
      height: "100%",
      width: "100%",
      zIndex: -1,
    }}
  >
    <svg
      style={{ position: "absolute", left: 0 }}
      width="925"
      height="100%"
      viewBox="0 0 925 100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.1" filter="url(#filter0_f_3516_17320)">
        <circle cx="149" cy="153" r="576" fill="#EA8082" />
      </g>
      <defs>
        <filter
          id="filter0_f_3516_17320"
          x="-627"
          y="-623"
          width="1552"
          height="1552"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_3516_17320"
          />
        </filter>
      </defs>
    </svg>
    <svg
      style={{ position: "absolute", right: 0 }}
      width="888"
      height="100%"
      viewBox="0 0 888 100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.1" filter="url(#filter0_f_3516_17321)">
        <circle cx="776" cy="153" r="576" fill="#32B6BC" />
      </g>
      <defs>
        <filter
          id="filter0_f_3516_17321"
          x="0"
          y="-623"
          width="1552"
          height="1552"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_3516_17321"
          />
        </filter>
      </defs>
    </svg>
  </div>
);

export default GraphBackground;

import styled from "styled-components";

import { WHITE_01, GREY_01 } from "../../../theme/colors";
import { unit } from "../../../theme/metrics";

export const Container = styled.div`
  border-radius: ${unit(1)};
  padding: 7px ${unit(1)};
  background-color: ${WHITE_01};
  border: ${({ border }) => border && `1px solid ${GREY_01}`};
  display: inline-block;
  ${(props) =>
    props.spaceBefore ? `margin-top: ${unit(props.spaceBefore)};` : ``}
  ${(props) =>
    props.spaceAfter ? `margin-bottom: ${unit(props.spaceAfter)};` : ``}
`;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import prioritisedLayout from "../../../project/prioritised/prioritised.json";
import ideaLayout from "./layout.json";

import comment from "../../../../actions/comment";
import idea from "../../../../actions/idea";

import Table from "../../../../components/data-display/table";
import Divider from "../../../../components/layout/divider";
import Modal from "../../../../components/feedback/modal";
import Box from "../../../../components/layout/box";
import Heading from "../../../../components/typography/heading";
import Text from "../../../../components/typography/text";
import TextArea from "../../../../components/form/text-area";
import Button from "../../../../components/form/button/button.component";
import FormContent from "../../../../components/form/form-content";

import { useCallouts } from "../../../../components/callout";
import { useProcessing } from "../../../../components/processing";

import { Scroll } from "./point.styles";

import { buildTable, lookup, updateTable } from "./helper";

const Point = ({ projectId, ideaId, projectData, ideaData }) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const [table, setTable] = useState(null);
  const [prioritisedTable, setPrioritisedTable] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    table: "",
    data: { label: "", id: "", column: "" },
  });
  const [text, setText] = useState("");
  const [edit, setEdit] = useState(false);

  const projectStructure = projectData?.structure;

  const rows = ideaLayout.flatMap(({ parameters }) => parameters);
  const summary = prioritisedLayout.find(({ id }) => id === "overview");
  const prioritisedRows = summary.parameters.filter(({ overview }) => overview);

  const content =
    modal.table === "idea"
      ? ideaLayout.find(({ id }) => id === modal.data.id)
      : prioritisedLayout.find(({ id }) => id === modal.data.id);

  const [snapshot] = comment.useCollection(projectId, ideaId, "option");

  useEffect(() => {
    const getTabelData = lookup(snapshot);
    const columns = [
      { id: "indication|patients", align: "start", clickableCell: true },
    ];
    const data = [
      { id: "indication", label: "Indication", cells: ["-"] },
      { id: "patients", label: "Patients", cells: ["-"] },
    ];
    if (getTabelData) {
      Object.entries(getTabelData)
        .map(([, value]) => {
          if (
            value.parameterId === "indication" ||
            value.parameterId === "patients"
          ) {
            const index = data.findIndex(({ id }) => id === value.parameterId);
            data[index].cells[0] = value.text ? value.text : "-";
          }
        })
        .filter((_) => _ != null);
    }
    setTable({ columns, data });
  }, [snapshot]);

  useEffect(() => {
    const columns = [
      { id: "column1", align: "start", clickableCell: true },
      { id: "note", width: 462, align: "start", clickableCell: true },
    ];
    const cells = buildTable(prioritisedRows, ideaData, columns, true);
    const data = [];
    prioritisedRows.map(({ label, id }, index) => {
      data.push({
        id,
        label,
        height: 75,
        cells: cells.length ? cells[index] : cells,
      });
    });
    setPrioritisedTable({ columns, data });
  }, []);

  const handleTableUpdate = (values, id, isOverview) => {
    if (isOverview) {
      setPrioritisedTable({
        ...prioritisedTable,
        data: updateTable(
          prioritisedTable,
          values,
          id,
          prioritisedRows,
          isOverview
        ),
      });
    } else {
      setTable({
        ...table,
        data: updateTable(table, values, id, rows, isOverview),
      });
    }
  };

  const handleSubmit = (values) => {
    const isOverview = modal.table === "prioritised";
    const update = ideaData.prioritised?.find(
      (_) => _.parameterId === content.id
    )?.values;
    if (content) {
      const pid = addToQueue();
      update
        ? idea
            .updatePrioritisedContent(projectId, ideaId, content.id, values)
            .then(() => {
              handleTableUpdate(values, modal.data.id, isOverview);
              removeFromQueue(pid);
              addCallout({
                message: t("idea.modal.point.callout.update", {
                  point: modal.data.label,
                  content: ideaData.name,
                }),
              });
            })
            .catch((error) => {
              removeFromQueue(pid);
              addCallout({
                type: "error",
                message: t("idea.modal.point.callout.error-update", {
                  point: modal.data.label,
                  content: ideaData.name,
                }),
                error: error,
              });
            })
        : idea
            .addPrioritisedContent(projectId, ideaId, {
              parameterId: content.id,
              values,
            })
            .then(() => {
              handleTableUpdate(values, modal.data.id, isOverview);
              removeFromQueue(pid);
              addCallout({
                message: t("idea.modal.point.callout.add", {
                  point: modal.data.label,
                  content: ideaData.name,
                }),
              });
            })
            .catch((error) => {
              removeFromQueue(pid);
              addCallout({
                type: "error",
                message: t("idea.modal.point.callout.error-add", {
                  point: modal.data.label,
                  content: ideaData.name,
                }),
                error: error,
              });
            });
      setModal({
        ...modal,
        table: "",
        open: false,
        data: { label: "", id: "", column: "" },
      });
    }
  };

  const handleTextChange = (event) => {
    if (!edit) setEdit(true);
    setText(event.value);
  };

  const handleSave = (id) => {
    const pid = addToQueue();
    comment
      .update(projectId, ideaId, "option", id, {
        parameterId: id,
        text: text,
      })
      .then(() => {
        removeFromQueue(pid);
        addCallout({
          message: t("idea.modal.point.callout.update", {
            point: modal.data.label,
            content: ideaData.name,
          }),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("idea.modal.point.callout.error-update", {
            point: modal.data.label,
            content: ideaData.name,
          }),
          error: error,
        });
      });
    setText("");
    setEdit(false);
    setModal({
      ...modal,
      table: "",
      open: false,
      data: { label: "", id: "", column: "" },
    });
  };

  return (
    <>
      <Heading size="small" spaceAfter={6}>
        {t("idea.modal.point.heading")}
      </Heading>
      <Scroll>
        {table && (
          <Table
            columns={table.columns}
            data={table.data}
            onClickCell={(_, id, column) => {
              const group = projectStructure.find((_) => _.id === "option");
              const param = group?.parameters?.find((_) => _.id === id);
              let text = "";
              if (param) {
                if (snapshot && snapshot.docs) {
                  const doc = snapshot.docs.find(
                    (doc) => doc.data().parameterId === id
                  );
                  if (doc) {
                    text = doc.data().text;
                  }
                }

                setModal({
                  ...modal,
                  table: "idea",
                  open: true,
                  data: {
                    label: param.label,
                    id: param.id,
                    help: param.help,
                    text,
                    column,
                  },
                });
              }
            }}
          />
        )}
        <Divider />
        {prioritisedTable && (
          <Table
            columns={prioritisedTable.columns}
            data={prioritisedTable.data}
            onClickCell={(label, id, column) => {
              setModal({
                ...modal,
                table: "prioritised",
                open: true,
                data: { label, id, column },
              });
            }}
          />
        )}
      </Scroll>
      {modal.open && (
        <Modal
          heading={modal.data.label}
          headingSize="medium"
          color="green"
          align="left"
          onClose={() => {
            setText("");
            setEdit(false);
            setModal({
              ...modal,
              table: "",
              open: false,
              data: { label: "", id: "", column: "" },
            });
          }}
        >
          {modal.table === "idea" ? (
            <>
              <Box marginBottom={1}>
                <Text variant="tertiary">{modal.data.label}</Text>
              </Box>
              <Box paddingBottom={3}>
                <TextArea
                  name="text"
                  value={edit ? text : modal.data.text}
                  onChange={handleTextChange}
                  autoResize={true}
                  variant="outline"
                />
              </Box>
              <Button
                variant="primary"
                onClick={() => handleSave(modal.data.id)}
                label="Save"
              />
            </>
          ) : (
            <FormContent
              data={
                content &&
                ideaData.prioritised?.find((_) => _.parameterId === content.id)
                  ?.values
              }
              parameters={content.parameters}
              onSubmit={handleSubmit}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default Point;

import styled from 'styled-components'

import { unit } from '../../theme/metrics'
import { OFF_WHITE } from '../../theme/colors'

export const Container = styled.div`
	background-color: ${OFF_WHITE};
	flex: 1 1 auto;
	position: relative;
`

export const ToggleContainer = styled.div`
	position: absolute;
	top: ${unit(2)};
	right: ${unit(2)};
`

export const ControlsContainer = styled.div`
	position: absolute;
	top: ${unit(2)};
	left: ${unit(2)};
`
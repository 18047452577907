import React from "react";
import PropTypes from "prop-types";

import DeleteIcon from "./icons/delete.component";
import ChevronIcon from "./icons/chevron.component";
import AlignLeftIcon from "./icons/align-left.component";
import DragIcon from "./icons/drag.component";
import QuestionMark from "./icons/question.component";
import Logo from "./icons/logo.component";
import GraphIcon from "./icons/graph.component";
import ThumbsUpIcon from "./icons/thumbs-up.component";
import StarIcon from "./icons/star.component";
import AddIcon from "./icons/add.component";
import ImpactIcon from "./icons/impact.component";
import OrderIcon from "./icons/order.component";

import { StyledIcon } from "./icon.styles";

import { WHITE_01, GREY_00, GREEN_01 } from "../../../theme/colors";

const icon = (variant, position, color) => {
  const getColor = () => {
    switch (color) {
      case "white":
        return WHITE_01;
      case "green":
        return GREEN_01;
      case "default":
        return GREY_00;
    }
  };
  switch (variant) {
    case "delete":
      return <DeleteIcon color={getColor()} />;
    case "chevron":
      return <ChevronIcon position={position} color={getColor()} />;
    case "align-left":
      return <AlignLeftIcon color={getColor()} />;
    case "drag":
      return <DragIcon color={getColor()} />;
    case "question-mark":
      return <QuestionMark color={getColor()} />;
    case "logo":
      return <Logo />;
    case "graph":
      return <GraphIcon color={getColor()} />;
    case "thumbs-up":
      return <ThumbsUpIcon color={getColor()} />;
    case "star":
      return <StarIcon color={getColor()} />;
    case "add":
      return <AddIcon color={getColor()} />;
    case "impact":
      return <ImpactIcon color={getColor()} />;
    case "order":
      return <OrderIcon color={getColor()} position={position} />;
    default:
      return <></>;
  }
};

const Icon = ({ variant, position, color = "default", size = 3, onClick }) => {
  return (
    <StyledIcon size={size} onClick={onClick}>
      {icon(variant, position, color)}
    </StyledIcon>
  );
};

Icon.propTypes = {
  variant: PropTypes.oneOf([
    "delete",
    "chevron",
    "align-left",
    "drag",
    "question-mark",
    "logo",
    "graph",
    "thumbs-up",
    "star",
    "add",
    "impact",
    "order",
  ]).isRequired,
  position: PropTypes.oneOf(["up", "left", "right", "down"]),
  color: PropTypes.oneOf(["white", "green", "default"]),
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default Icon;

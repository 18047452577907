import React from "react";

export const OrderIcon = ({ position, color }) => {
  const rotation = () => {
    switch (position) {
      case "up":
        return "180deg";
      case "left":
        return "90deg";
      case "right":
        return "270deg";
      case "down":
        return "0deg";
      default:
        return "0deg";
    }
  };
  return (
    <svg
      width="100%"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${position ? rotation(position) : 0})`,
      }}
    >
      <path
        d="M4.0001 7.375L0.102989 0.624999L7.89722 0.625L4.0001 7.375Z"
        fill={color}
      />
    </svg>
  );
};
export default OrderIcon;

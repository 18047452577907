import React, { useRef, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import { WHITE } from "../../../theme/theme";

import {
  Container,
  Scrollable,
  Content,
  Inner,
  Header,
  HeadingContainer,
  CloseButton,
  StyledModalMenu,
} from "./modal.styles.js";

import Heading from "../../typography/heading";
import Paragraph from "../../typography/text";

const Modal = ({
  heading,
  color,
  align,
  headingSize = "large",
  descriptionSize = "large",
  modalSize = "medium",
  description,
  menu,
  header,
  children,
  onClose,
}) => {
  const contentRef = useRef();

  const handleClick = (event) => {
    if (contentRef && !contentRef.current.contains(event.target)) {
      if (onClose) {
        onClose();
      }
    }
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <ThemeProvider theme={WHITE}>
      <Container onClick={handleClick} size={modalSize}>
        <Scrollable size={modalSize}>
          <Inner>
            <Content ref={contentRef} size={modalSize}>
              {(heading || description || header) && (
                <Header>
                  {(heading || description) && (
                    <Fragment>
                      {heading && (
                        <HeadingContainer>
                          <Heading
                            color={color}
                            size={headingSize}
                            level={2}
                            align={
                              align
                                ? align
                                : modalSize === "large" ||
                                  modalSize === "xlarge"
                                ? "left"
                                : "center"
                            }
                          >
                            {heading}
                          </Heading>
                        </HeadingContainer>
                      )}
                      {header}
                      {description && (
                        <Paragraph
                          size={descriptionSize}
                          spaceBefore={2}
                          variant="tertiary"
                          align={
                            modalSize === "large" || modalSize === "xlarge"
                              ? "left"
                              : "center"
                          }
                        >
                          {description.split("\n").map((line, index) => (
                            <Fragment key={index}>
                              {line}
                              <br />
                            </Fragment>
                          ))}
                        </Paragraph>
                      )}
                    </Fragment>
                  )}
                </Header>
              )}
              {children}
              {menu && <StyledModalMenu size={modalSize} items={menu} />}
              {onClose && <CloseButton onClick={onClose} />}
            </Content>
          </Inner>
        </Scrollable>
      </Container>
    </ThemeProvider>
  );
};

Modal.displayName = "Modal";

Modal.propTypes = {
  heading: PropTypes.string,
  color: PropTypes.oneOf([
    "black",
    "grey",
    "grey-0",
    "dark-grey",
    "green",
    "red",
  ]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  headingSize: PropTypes.oneOf(["small", "medium", "large"]),
  descriptionSize: PropTypes.oneOf(["small", "medium", "large"]),
  modalSize: PropTypes.oneOf(["small", "medium", "large"]),
  description: PropTypes.string,
  menu: PropTypes.array,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,

  onClose: PropTypes.func,
};

Modal.defaultProps = {
  size: "medium",
  menu: undefined,
  onClose: undefined,
};

export default Modal;

export const WHITE_01 = `#FFFFFF`;
export const BLACK_01 = `#050505`;

export const RED_01 = `#FC3718`;
export const RED_02 = `#9C1702`;
export const RED_03 = `#ECDFDD`;

export const ORANGE_01 = `#FF8500`;

/* NEW COLORS */
export const KEYLINE_LIGHT = `#E4E8F1`;
export const NEW_GREY_01 = `#848792`;
export const NEW_GREY_02 = `#AEB0BB`;

/* NEW COLORS: FIVEX */
export const PURPLE_00 = `#543DB2`;

export const GREY_00 = `#4D4D4D`;
export const GREY_01 = `#E4E8F1`;
export const GREY_02 = `#D5D6DE`;
export const GREY_03 = `#C2C3CC`;
export const GREY_04 = `#808080`;
export const GREY_05 = `#B8E8EC`;
export const GREY_06 = `#575151`;

export const GREEN_01 = `#32B6BC`;
export const GREEN_02 = `#41CCD2`;
export const GREEN_03 = `#259DA3`;
export const GREEN_04 = `#0B4F52`;
export const GREEN_05 = `#07474A`;
export const GREEN_06 = `#0F282A`;

export const BLUE_01 = `#587CD9`;

export const PINK_01 = `#F37A88`;

export const BEIGE_01 = `#BAB08E`;

export const BLUE_WASH = `#F7F8FB`;
export const PINK_WASH = `#FEF7F6`;
export const GREEN_WASH = `#EFFCFD`;

export const DARK_GREY_01 = `#4D4D4D`;
export const DARK_GREY_02 = `#48494C`;

export const OFF_WHITE = `#F9FAFC`;

export const BLUE_GREY = `#C7CEDD`;

export const BUBBLE_COLOR_SCHEME = {
  default: "green",
  f: "blue",
  i: "pink",
  v: "beige",
  e: "orange",
  x: "red",
};

export const BUBBLE_COLORS = {
  red: {
    primary: {
      color: RED_01,
      glow: "#F7FF9F",
    },
    secondary: {
      color: RED_01,
      glow: "#F7FF9F",
    },
  },
  green: {
    primary: {
      color: GREEN_01,
      glow: "#F7FF9F",
    },
    secondary: {
      color: GREY_06,
      glow: "#000000",
    },
  },
  pink: {
    primary: {
      color: PINK_01,
      glow: "#F7FF9F",
    },
    secondary: {
      color: PINK_01,
      glow: "#F7FF9F",
    },
  },
  beige: {
    primary: {
      color: BEIGE_01,
      glow: "#F7FF9F",
    },
    secondary: {
      color: BEIGE_01,
      glow: "#F7FF9F",
    },
  },
  orange: {
    primary: {
      color: ORANGE_01,
      glow: "#F7FF9F",
    },
    secondary: {
      color: ORANGE_01,
      glow: "#F7FF9F",
    },
  },
  blue: {
    primary: {
      color: BLUE_01,
      glow: "#F7FF9F",
    },
    secondary: {
      color: BLUE_01,
      glow: "#F7FF9F",
    },
  },
};

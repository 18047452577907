import styled from 'styled-components'

import {spacer, unit} from '../../theme/metrics'

import { RED_01 } from '../../theme/colors'

export const Container = styled.div`
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform:translateX(-50%);
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Content = styled.div`
	background-color: ${props => props.type === "error" ? RED_01 : props.theme.background.primary};
	position: relative;
	height: ${unit(8)};
	border-radius: ${unit(4)};
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Inner = styled.div`
	padding: 0 0 0 ${spacer()};
`

export const Dismiss = styled.div`
	padding: 0 ${spacer()};
	cursor: pointer;
`

export const Item = styled.div`
	transition: 0.3s;
	opacity: ${props => props.state === "enter" || props.state === "entered" ? 1 : 0};
`
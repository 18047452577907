// the main file in our front-end app
// create-react-app expects a file in src/index.js and loads everything from here

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'
import React from 'react'
import ReactDOM from 'react-dom'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './dictionary/en'

import { initializeAnalytics } from './services/analytics'

import './theme/fonts/stylesheet.css';

import App from './views/App'

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
	resources: {
		en: {
			translation:en
		}
	},
	lng: "en",
	fallbackLng: "en",
	interpolation: {
		escapeValue: false
	}
});

// connect our app to firebase 
const dbConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

firebase.initializeApp(dbConfig);

if (window.location.hostname === 'localhost' && process.env.REACT_APP_ENV === 'local') {
	console.log("testing locally -- hitting local functions and firestore emulators");
	firebase.functions().useFunctionsEmulator('http://localhost:5001');
	firebase.firestore().settings({
		host: 'localhost:8080',
		ssl: false
	});
}

initializeAnalytics()

// render the App component to our document root with React
ReactDOM.render(<App />, document.getElementById('root'))
import styled from "styled-components";
import { unit } from "../../../theme/metrics";

export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => (size ? unit(size) : unit(2))};
  height: ${({ size }) => (size ? unit(size) : unit(2))};
  cursor: ${({ onClick }) => onClick && "pointer"};
  transition: all 0.3s;
`;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Bubbles from "../../../../components/data-display/bubbles";
import Scaffold from "../../../../components/layout/scaffold";
import Label from "../../../../components/typography/label";
import Text from "../../../../components/typography/text";
import Icon from "../../../../components/feedback/icon";
import Box from "../../../../components/layout/box";
import Button from "../../../../components/form/button";

import GraphBackground from "./graph-background.component";
import {
  Container,
  Tooltip,
  TooltipInner,
  XaxisLabel,
  YaxisLabel,
} from "./assumption-graph.styles";

const BUBBLE_RADIUS = 0.2;
const TOOLTIP_WIDTH = 360;

const AssumptionGraph = ({ data, editBubble }) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState();
  const [bubbles, setBubbles] = useState([]);
  const [y, setY] = useState();

  const container = useRef(null);

  const handleHover = (bubble) => {
    if (bubble && bubble.id) {
      setHover({
        id: bubble.id,
        x: bubble.x,
        y: bubble.y,
        radius: bubble.radius,
      });
    }
  };

  const getPosition = (bubble) => {
    return {
      x: ((bubble.certainty / 5) * 10) / 10 - 0.1,
      y: (5 - bubble.impact) / 5 + 0.1,
    };
  };

  useEffect(() => {
    if (data) {
      setBubbles(
        data.map((_) => {
          return {
            ..._,
            size: BUBBLE_RADIUS,
            fixedRadius: null,
            interactive: true,
            position: getPosition(_),
            startPosition: getPosition(_),
            filter: null,
            dim: false,
            new: false,
            variant: "primary",
          };
        })
      );
    }
  }, [data]);

  const showTooltip = () => {
    const bubble = data.find(({ id }) => id === hover.id);
    const flip = hover.x + TOOLTIP_WIDTH > window.innerWidth;

    const offScreen = (el) => {
      if (hover.y + el?.offsetHeight > window.innerHeight) {
        setY(hover.y - hover.radius);
      } else setY();
    };

    const position = flip ? TOOLTIP_WIDTH + hover.radius / 2 : hover.radius / 2;
    const width = TOOLTIP_WIDTH + hover.radius;
    const padding = flip ? -16 : hover.radius + 16;
    return (
      <Tooltip
        x={hover.x}
        y={y ? y : hover.y}
        position={position}
        width={width}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Box shadow>
          <TooltipInner padding={padding} ref={(el) => offScreen(el)}>
            <Scaffold direction="vertical" spaceBetween={1}>
              {bubble && (
                <Box direction="vertical" gap={1}>
                  <Label variant="primary" color="green">
                    {bubble.area}
                  </Label>
                  <Text size="small" variant="tertiary" spaceAfter={1}>
                    {bubble.assumption}
                  </Text>
                  <Box mainAxisAlignment="evenly">
                    <Box gap={1}>
                      <Box crossAxisAlignment="center" gap={0.5}>
                        <Icon variant="impact" />
                        <Text size="small" variant="tertiary">
                          {bubble.impact}
                        </Text>
                      </Box>
                      <Box crossAxisAlignment="center" gap={0.5}>
                        <Icon variant="thumbs-up" />
                        <Text size="small" variant="tertiary">
                          {bubble.certainty}
                        </Text>
                      </Box>
                    </Box>
                    <div>
                      <Button
                        label="Edit"
                        variant="secondary"
                        onClick={() => editBubble(bubble.id)}
                      />
                    </div>
                  </Box>
                </Box>
              )}
            </Scaffold>
          </TooltipInner>
        </Box>
      </Tooltip>
    );
  };

  return (
    <Container ref={container} grid={5}>
      {bubbles && bubbles.length > 0 && (
        <Bubbles
          data={bubbles}
          onOver={handleHover}
          fillFactor={0.15}
          textSize={10}
          colors={{
            primary: {
              color: "#575151",
              glow: "#575151",
            },
          }}
        />
      )}
      <GraphBackground />

      <XaxisLabel>
        <Scaffold direction="horizontal" spaceBetween={2}>
          <Label variant="tertiary">-</Label>
          <Label variant="tertiary">{t("planToLearn.graph.xAxis")}</Label>
          <Label variant="tertiary">+</Label>
        </Scaffold>
      </XaxisLabel>
      <YaxisLabel>
        <Scaffold direction="horizontal" spaceBetween={2}>
          <Label variant="tertiary">-</Label>
          <Label variant="tertiary">{t("planToLearn.graph.yAxis")}</Label>
          <Label variant="tertiary">+</Label>
        </Scaffold>
      </YaxisLabel>
      {hover && showTooltip()}
    </Container>
  );
};

export default AssumptionGraph;

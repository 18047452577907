import styled from "styled-components";

import { unit, pixelate } from "../../../../theme/metrics";
import { WHITE_01, GREY_01 } from "../../../../theme/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-size: ${({ grid }) =>
    `calc(100% / ${grid}) 100%, 
    100% calc(100% / ${grid});`};

  background-image: ${() => `
     linear-gradient(to right, ${GREY_01} 1px, transparent 1px),
     linear-gradient(to bottom, ${GREY_01} 1px, transparent 1px);
     `};
`;

export const Tooltip = styled.div`
  position: absolute;
  left: ${({ position }) => `-${position}px`};
  transform: ${({ x, y }) =>
    `translate3d(${x ? pixelate(x) : "50%"}, ${
      y ? `calc(${pixelate(y)} - 50%)` : "50%"
    }, 0)`};
  width: ${({ width }) => `${width}px`};
`;

export const TooltipInner = styled.div`
  position: relative;
  padding: ${unit(3)};
  background-color: ${WHITE_01};
  transform: translate3d(0px, 0, 0);
  width: ${unit(45)};
  left: ${({ padding }) => `${padding}px`};
  border-radius: ${unit(1)};
`;

export const ControlsContainer = styled.div`
  position: absolute;
  top: ${unit(2)};
  right: ${unit(2)};
  border-radius: ${unit(1)};
  overflow: hidden;
  background-color: ${WHITE_01};
  display: flex;
  border: 1px solid ${GREY_01};
`;

export const XaxisLabel = styled.div`
  position: absolute;
  bottom: ${unit(3)};
  left: 50%;
  transform: translateX(-50%);
`;

export const YaxisLabel = styled.div`
  position: absolute;
  left: ${unit(3)};
  top: 50%;
  transform-origin: 0% 0%;
  transform: rotate(-90deg) translateX(-50%);
`;

export const TooltipBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

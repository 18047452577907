import React from 'react'
import PropTypes from 'prop-types';

import { Container, ImageContainer, Image } from './avatar.styles'

const Avatar = ({
	color,
	size,
	firstName,
	lastName,
	src,
	className,
	accessibilityLabel,
	onClick,
	selected
}) => {
	const initials = (firstName ? [...firstName][0].toUpperCase() : '') + (lastName ? [...lastName][0].toUpperCase() : '');

	return (
		<Container
			color={color}
			size={size}

			as={onClick ? "button" : null}
			aria-label={accessibilityLabel}
			className={className}
			onClick={onClick}
			selected={selected}
		>
			{src ?
				<ImageContainer selected={selected}>
					<Image src={src} />
				</ImageContainer>
				:
				initials
			}
		</Container>
	);
}

Avatar.displayName = "Avatar";

Avatar.propTypes = {
	color: PropTypes.oneOf(['green', 'red', 'orange', 'grey']),
	size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	src: PropTypes.string,
	className: PropTypes.string,
	accessibilityLabel: PropTypes.string,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
};

Avatar.defaultProps = {
	color: 'grey',
	size: 'medium',
	firstName: undefined,
	lastName: undefined,
	src: undefined,
	className: undefined,
	accessibilityLabel: undefined,
	onClick: undefined,
	selected: false
};

export default Avatar
import styled from 'styled-components'
import Color from 'color';

import { typeText2Regular } from '../../../theme/typography'

import { unit } from '../../../theme/metrics'

import { GREY_01 } from '../../../theme/colors'

export const Container = styled.label`
	${typeText2Regular}
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s;
	color: ${props => props.theme.foreground.secondary};
	background-color: ${props => props.over ? GREY_01 : Color(GREY_01).alpha(0.3)};
	${props => props.disabled ? `opacity: 0.5;` : ``}
	border-radius: ${unit(1)};
	padding: 0 ${props => props.size === "large" ? unit(3) : (props.size === "small" ? unit(1.5) : unit(2))};
	height: ${props => props.size === "large" ? unit(6) : (props.size === "small" ? unit(4) : unit(5))};

	&:focus {
		outline:none;
	}
`

export const Input = styled.input`
	opacity: 0;
	width: 0px;
	height: 0px;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`


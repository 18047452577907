import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";

import {
  Container,
  Input,
  Action,
  Icon,
  ErrorMessage,
} from "./text-field.styles.js";

const Content = forwardRef(
  (
    {
      id,
      name,
      value,
      type = "text",
      className,
      label,
      placeholder,
      errorMessage,
      hasError = false,
      helperText,
      disabled,
      actionLabel,
      iconStart,
      iconEnd,

      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onAction,

      top,
      bottom,
      left,
      right,
    },
    ref
  ) => {
    const innerRef = useRef(null);
    useImperativeHandle(ref, () => innerRef.current);

    const [focused, setFocused] = useState(false);
    const [over, setOver] = useState(false);

    const handleChange = (event) => {
      if (onChange) {
        onChange({ ...event, id, value: event.currentTarget.value });
      }
    };

    const handleBlur = (event) => {
      setFocused(false);
      if (onBlur) {
        onBlur({ ...event, id });
      }
    };

    const handleFocus = (event) => {
      setFocused(true);
      if (onFocus) {
        onFocus({ ...event, id });
      }
    };

    const handleKeyDown = (event) => {
      if (onKeyDown) {
        onKeyDown({ ...event, id });
      }
    };

    const handleActionClick = (event) => {
      if (onAction) {
        onAction({
          ...event,
          id,
          position: { ...innerRef.current.getBoundingClientRect() },
        });
      }
    };

    const pattern = type === "number" ? "\\d*" : undefined;

    const getIcon = (iconName, start) => {
      if (iconName === "search") {
        return (
          <Icon
            start={start ? 1 : 0}
            hasError={hasError}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 19C15.9706 19 20 15.1944 20 10.5C20 5.80558 15.9706 2 11 2C6.02944 2 2 5.80558 2 10.5C2 15.1944 6.02944 19 11 19Z"
              stroke="#050505"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 17L22 22"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Icon>
        );
      } else if (iconName === "dropdown") {
        return (
          <Icon
            start={start ? 1 : 0}
            hasError={hasError}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="#050505"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Icon>
        );
      }
    };

    return (
      <Container>
        <Input
          ref={innerRef}
          aria-describedby={errorMessage && focused ? `${id}-error` : null}
          aria-invalid={errorMessage || hasError ? "true" : "false"}
          placeholder={placeholder}
          type={type}
          value={value}
          disabled={disabled}
          hasError={hasError}
          id={id}
          name={name}
          className={className}
          pattern={pattern}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
          over={over}
          focused={focused}
          start={iconStart !== "none" ? 1 : 0}
          end={iconEnd !== "none" ? 1 : 0}
        />
        {iconStart && getIcon(iconStart, true)}
        {actionLabel && (
          <Action onClick={handleActionClick}>{actionLabel}</Action>
        )}
        {iconEnd && !actionLabel && getIcon(iconEnd, false)}
        {errorMessage && hasError && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </Container>
    );
  }
);

const TextField = (props) => {
  return <Content {...props} />;
};

TextField.propTypes = {
  iconStart: PropTypes.oneOf(["none", "search"]),
  iconEnd: PropTypes.oneOf(["none", "dropdown"]),
  type: PropTypes.oneOf(["text", "number"]),
  actionLabel: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextField.defaultProps = {
  iconStart: "none",
  iconEnd: "none",
  type: "text",
  hasError: false,
  disabled: false,
};

Content.displayName = "TextField";

export default TextField;

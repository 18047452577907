import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";

import { getDashboardPath, getProjectPath } from "../../routing/paths";

import project from "../../actions/project";
import idea from "../../actions/idea";
import profile from "../../actions/profile";
import user from "../../actions/user";

import {
  Container,
  Content,
  ScreenSize,
  ProfileContainer,
  Footer,
  FooterItem,
  FooterLink,
} from "./layout.styles.js";

import { WHITE } from "../../theme/theme";

import Avatar from "../../components/form/avatar";
import Menu from "../../components/data-display/menu";
import Heading from "../../components/typography/heading";
import Box from "../../components/layout/box";

import PageError from "../../components/page-error";

import EditProfile from "../edit-profile";
import ChangeEmail from "../change-email";

import { CalloutProvider } from "../../components/callout";
import Navbar from "../../components/data-display/navbar/navbar.component";

const Profile = ({ userId, onEdit, onChangeEmail }) => {
  const { t } = useTranslation();
  const [doc] = profile.useDocument(userId);
  const [data, setData] = useState(null);
  const [avatar, setAvatar] = useState();
  const [avatarURL, setAvatarURL] = useState();
  const [menuVisible, setMenuVisible] = useState(false);
  useEffect(() => {
    if (avatar) {
      profile.getAvatarURL(avatar).then((url) => {
        setAvatarURL(url);
      });
    } else {
      setAvatarURL(null);
    }
  }, [avatar]);
  useEffect(() => {
    if (doc) {
      let d = doc.data();
      if (d) {
        setData(d);
        setAvatar(d.avatar);
      }
    }
  }, [doc]);
  if (data) {
    return (
      <ProfileContainer>
        <Avatar
          firstName={data.firstName}
          lastName={data.lastName}
          src={avatarURL}
          selected={menuVisible}
          onClick={() => setMenuVisible(true)}
        />
        {menuVisible && (
          <Menu
            horizontalAlign="left"
            onClose={() => setMenuVisible(false)}
            items={[
              {
                label: t("profile.action.edit"),
                icon: "user",
                onClick: onEdit,
              },
              {
                label: t("user.action.changeEmail"),
                icon: "email",
                onClick: onChangeEmail,
              },
              {
                label: t("user.action.licenceAgreement"),
                icon: "legal",
                onClick: () => window.open("/legal/licence-agreement.pdf"),
              },
              {
                label: t("user.action.signOut"),
                icon: "sign-out",
                onClick: () => user.signOut(),
              },
            ]}
          />
        )}
      </ProfileContainer>
    );
  }
  return <Avatar />;
};

const ProjectInfo = ({ projectId }) => {
  const [doc] = project.useDocument(projectId);

  if (doc && doc.exists) {
    return (
      <Box direction="horizontal" crossAxisAlignment="center">
        <Heading size="small">{`${doc.data().displayName} (${
          doc.data().scientificName
        })`}</Heading>
      </Box>
    );
  }

  return null;
};

const Options = ({ projectId }) => {
  const [ideasSnapshot, ,] = idea.useCollection(projectId);
  if (ideasSnapshot && ideasSnapshot.docs && ideasSnapshot.docs.length) {
    return ideasSnapshot.docs.length;
  }
  return 0;
};

const Prioritised = ({ projectId }) => {
  const [doc] = project.useDocument(projectId);
  if (doc && doc.exists) {
    if (doc.data().prioritised) {
      return doc.data().prioritised?.length;
    }
  }
  return 0;
};

const Assumptions = ({ projectId }) => {
  const [doc] = project.useDocument(projectId);
  if (doc && doc.exists) {
    if (doc.data().assumptions) {
      return doc.data().assumptions?.length;
    }
  }
  return 0;
};

const Layout = ({ children }) => {
  const { t } = useTranslation();
  let projectMatch = useRouteMatch(getProjectPath(":projectId"));
  let dashboardMatch = useRouteMatch(getDashboardPath());
  const history = useHistory();
  let projectId = null;

  const [editProfileVisible, setEditProfileVisible] = useState(false);
  const [changeEmailVisible, setChangeEmailVisible] = useState(false);
  const [projectPage, setProjectPage] = useState("option");

  useEffect(() => {
    /* TODO: Tidy up */
    const tabs = ["option", "prioritised", "p2l"];
    tabs.map((_) => {
      if (history.location.pathname.includes(_)) {
        setProjectPage(_);
      }
    });
  }, [history.location]);

  if (projectMatch) {
    projectId = projectMatch.params.projectId;
  }
  const [currentUser, , error] = user.useCurrent();

  if (error)
    return (
      <PageError
        heading={t("error.general.heading")}
        description={t("error.general.description")}
      />
    );

  return (
    <ThemeProvider theme={WHITE}>
      <Container>
        <CalloutProvider>
          <Navbar
            title={projectId && <ProjectInfo projectId={projectId} />}
            navigation={
              projectId && [
                {
                  id: t("project.navigation.option.id"),
                  label: t("project.navigation.option.label"),
                  badge: {
                    value: <Options projectId={projectId} />,
                  },
                },
                {
                  id: t("project.navigation.prioritised.id"),
                  label: t("project.navigation.prioritised.label"),
                  badge: {
                    value: <Prioritised projectId={projectId} />,
                  },
                  divider: true,
                },
                {
                  id: t("project.navigation.planToLearn.id"),
                  label: t("project.navigation.planToLearn.label"),
                  badge: {
                    value: <Assumptions projectId={projectId} />,
                  },
                },
              ]
            }
            selected={projectPage}
            onClick={(key) => {
              setProjectPage(key);
              history.push(`${projectMatch.url}/${key}`);
            }}
            to={getDashboardPath()}
            profile={
              currentUser && (
                <Profile
                  userId={currentUser.uid}
                  onEdit={() => setEditProfileVisible(true)}
                  onChangeEmail={() => setChangeEmailVisible(true)}
                />
              )
            }
          />

          <Content>{children}</Content>

          {!projectMatch && !dashboardMatch &&
            <Footer>
              <FooterItem>
                <FooterLink href="/legal/licence-agreement.pdf" target="_blank">{t("footer.label.licenceAgreement")}</FooterLink>
              </FooterItem>
            </Footer>
          }

          {editProfileVisible && (
            <EditProfile
              onComplete={() => setEditProfileVisible(false)}
              onClose={() => setEditProfileVisible(false)}
            />
          )}
          {changeEmailVisible && (
            <ChangeEmail
              onComplete={() => setChangeEmailVisible(false)}
              onClose={() => setChangeEmailVisible(false)}
            />
          )}
        </CalloutProvider>
      </Container>
      <ScreenSize>
        <PageError
          heading={t("error.screenSize.heading")}
          description={t("error.screenSize.description")}
        />
      </ScreenSize>
    </ThemeProvider>
  );
};

export default Layout;

import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  WHITE_01,
  GREEN_01,
  RED_01,
  ORANGE_01,
  DARK_GREY_01,
  BLUE_01,
  PINK_01,
  BLACK_01,
  BEIGE_01,
} from "../../../theme/colors";

import { Btn, Char } from "./icon-button.styles";

const Icon = ({ selected, color, variant, type }) => {
  if (selected) {
    color = WHITE_01;
  } else {
    switch (variant) {
      case "primary":
        switch (color) {
          case "white":
            color = DARK_GREY_01;
            break;
          default:
            color = WHITE_01;
            break;
        }
        break;
      case "secondary":
        switch (color) {
          case "white":
            color = WHITE_01;
            break;
          case "green":
            color = GREEN_01;
            break;
          case "red":
            color = RED_01;
            break;
          case "orange":
            color = ORANGE_01;
            break;
          case "blue":
            color = BLUE_01;
            break;
          case "pink":
            color = PINK_01;
            break;
          case "beige":
            color = BEIGE_01;
            break;
          default:
            color = DARK_GREY_01;
            break;
        }
        break;
      case "tertiary":
        color = BLACK_01;
        break;
      default:
        color = WHITE_01;
        break;
    }
  }

  switch (type) {
    case "add":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 5V19"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 12H19"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      case "delete":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 12H19"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
    case "dots":
      return (
        <svg
          width="14"
          height="2"
          viewBox="0 0 14 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="1" cy="1" r="1" fill={color} />
          <circle cx="7" cy="1" r="1" fill={color} />
          <circle cx="13" cy="1" r="1" fill={color} />
        </svg>
      );
    case "close":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2427 7.75745L7.75739 16.2427"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.75732 7.75745L16.2426 16.2427"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "chevron-left":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    default:
  }

  if (type && type.length === 1) {
    return <Char color={color}>{type.toUpperCase()}</Char>;
  }

  return null;
};

const IconButton = ({
  color,
  variant,
  type,
  size,
  shadow,

  top,
  bottom,
  left,
  right,

  className,
  disabled,

  accessibilityLabel,

  selected,

  onClick,
  onMouseOver,
  onMouseOut,
}) => {
  const [over, setOver] = useState(false);

  return (
    <Btn
      color={color}
      size={size}
      variant={variant}
      shadow={shadow}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      as="button"
      aria-label={accessibilityLabel}
      className={className}
      onClick={disabled ? null : onClick}
      onMouseOver={(e) => {
        if (!disabled) {
          setOver(true);
        }
        if (onMouseOver) {
          onMouseOver(e);
        }
      }}
      onMouseOut={(e) => {
        setOver(false);
        if (onMouseOut) {
          onMouseOut(e);
        }
      }}
      over={over}
      selected={selected}
      disabled={disabled}
    >
      <Icon selected={selected} type={type} color={color} variant={variant} />
    </Btn>
  );
};

IconButton.displayName = "IconButton";

IconButton.propTypes = {
  color: PropTypes.oneOf([
    "green",
    "red",
    "orange",
    "grey",
    "pink",
    "blue",
    "beige",
  ]),
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  shadow: PropTypes.bool,
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  accessibilityLabel: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  color: "grey",
  variant: "primary",
  type: undefined,
  size: "medium",
  shadow: false,
  top: undefined,
  bottom: undefined,
  left: undefined,
  right: undefined,
  className: undefined,
  disabled: false,
  accessibilityLabel: undefined,
  selected: false,
  onClick: undefined,
};

export default IconButton;

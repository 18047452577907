import React, { useState, Fragment } from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import Row from "./row";
import Box from "../../../components/layout/box";
import Heading from "../../../components/typography/heading";
import Tooltop from "../../../components/feedback/tooltip";

const Parameter = ({
  projectId,
  ideaId,
  parameter,
  ideaData,
  write,
  projectData,
  group,
  onSelect,
  onAdd,
  selected,
}) => {
  const [addVisible, setAddVisible] = useState(false);

  const [hover, setHover] = useState(false);

  const entries = (ideaData.entries || []).filter(
    (entry) => entry.parameterId === parameter.id
  );

  const scoreValue = get(
    ideaData,
    `values[${group.id}][${parameter.id}].score.mean.value`
  );
  const confidenceValue = get(
    ideaData,
    `values[${group.id}][${parameter.id}].confidence.mean.value`
  );
  const historyAvailable =
    get(ideaData, `values[${group.id}][${parameter.id}]`) !== undefined;

  const handleSelect = (event) => {
    // setSelected(!selected)
    if (onSelect) {
      onSelect({ groupId: group.id, parameterId: parameter.id });
    }
  };

  const handleAdd = (event) => {
    onAdd(event);
  };

  const handleOver = (event) => {
    setHover(true);
  };

  const handleOut = (event) => {
    setHover(false);
  };

  return (
    <Row
      projectId={projectId}
      ideaId={ideaId}
      entries={entries}
      parameter={parameter}
      addVisible={addVisible}
      selected={
        selected &&
        selected.parameterId === parameter.id &&
        selected.groupId === group.id
      }
      hover={hover}
      write={write}
      group={group}
      scoreValue={scoreValue}
      confidenceValue={confidenceValue}
      historyAvailable={historyAvailable}
      onAdd={handleAdd}
      onSelect={handleSelect}
      onMouseOver={handleOver}
      onMouseOut={handleOut}
      setVisibility={setAddVisible}
    />
  );
};

const Scores = ({
  structure,
  projectId,
  ideaId,
  ideaData,
  projectData,
  write,
  onAdd,
}) => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (event) => {
    if (selected && event.parameterId === selected.parameterId) {
      setSelected(null);
    } else {
      setSelected(event);
    }
  };

  const { t } = useTranslation();
  const [scoreOver, setScoreOver] = useState(false);
  const [confidenceOver, setConfidenceOver] = useState(false);

  return (
    <Box
      marginHorizontal={-3}
      marginBottom={3}
      flex="grow"
      direction="vertical"
    >
      <Box paddingLeft={3} paddingRight={10} paddingBottom={1}>
        <Box flex="grow" crossAxisAlignment="center">
          <Heading size="medium">{structure.label}</Heading>
        </Box>
        <Box
          width={12}
          crossAxisAlignment="center"
          mainAxisAlignment="center"
          position="relative"
          onMouseOver={() => setScoreOver(true)}
          onMouseOut={() => setScoreOver(false)}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 20.1863V10.1863"
              stroke="#050505"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 20.1863V4.18631"
              stroke="#050505"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 20.1863V14.1863"
              stroke="#050505"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {scoreOver && <Tooltop label={t("idea.label.score")} />}
        </Box>
        <Box
          width={12}
          crossAxisAlignment="center"
          mainAxisAlignment="center"
          position="relative"
          onMouseOver={() => setConfidenceOver(true)}
          onMouseOut={() => setConfidenceOver(false)}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.57256 21.1862H4.82902C4.34394 21.1862 3.87872 20.9966 3.53571 20.659C3.1927 20.3214 3 19.8636 3 19.3862V13.0862C3 12.6089 3.1927 12.151 3.53571 11.8135C3.87872 11.4759 4.34394 11.2863 4.82902 11.2863H7.57256M13.9741 9.48627V5.88629C13.9741 5.17021 13.6851 4.48346 13.1706 3.97712C12.6561 3.47077 11.9582 3.18631 11.2306 3.18631L7.57256 11.2863V21.1862H17.8882C18.3293 21.1911 18.7574 21.039 19.0935 20.7578C19.4296 20.4766 19.6511 20.0854 19.7173 19.6562L20.9793 11.5563C21.0191 11.2983 21.0014 11.0349 20.9275 10.7843C20.8535 10.5337 20.7251 10.3019 20.5512 10.105C20.3772 9.90813 20.1618 9.75081 19.9199 9.64396C19.678 9.53712 19.4154 9.48331 19.1503 9.48627H13.9741Z"
              stroke="#050505"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {confidenceOver && <Tooltop label={t("idea.label.confidence")} />}
        </Box>
      </Box>
      <Fragment>
        {structure.parameters.map((parameter, index) => (
          <Parameter
            key={parameter.id}
            selected={selected}
            projectId={projectId}
            ideaId={ideaId}
            group={structure}
            ideaData={ideaData}
            projectData={projectData}
            parameter={parameter}
            write={write}
            onSelect={handleSelect}
            onAdd={onAdd}
          />
        ))}
      </Fragment>
    </Box>
  );
};

Scores.displayName = "Scores";

export default Scores;

import firebase from 'firebase/app'
import { useCollection as useCollectionHook, useCollectionOnce as useCollectionOnceHook, useDocument as useDocumentHook, useDocumentOnce as useDocumentOnceHook } from 'react-firebase-hooks/firestore';

import { prepareDataForCreate, prepareDataForUpdate, prepareDataForDuplicate } from '../helpers/firestore'

import idea from '../idea'

export const FIELDS = ['heading','text','groupId','state'];
export const COLLECTION_NAME = 'notes';
export const STATE = {
	ACTIVE: "active",
	ARCHIVED: "archived"
}

const createData = (data = {}) => {
	return prepareDataForCreate({
		state: STATE.ACTIVE,
		...data,
	},FIELDS)
}

const updateData = (data) => {
	return prepareDataForUpdate(data,FIELDS)
}

const duplicateData = (data) => {
	return prepareDataForDuplicate(data,FIELDS)
}

export const newDocumentRef = (projectId,ideaId) => {
	return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME).doc();
}

export const getDocumentRef = (projectId,ideaId,noteId) => {
	return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME).doc(noteId);
}

export const getCollectionRef = (projectId,ideaId,{groupId,state}={groupId:null,ideaId:null}) => {
	if(state && groupId){
		return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME).where("state", "==", state).where("groupId", "==", groupId)
	}
	else if(groupId){
		return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME).where("groupId", "==", groupId)
	}
	else if(state){
		return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME).where("state", "==", state)
	}

	return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME)
}

export const getCollection = (projectId,ideaId,{groupId,state}={groupId:null,ideaId:null}) => {
	return getCollectionRef(projectId,ideaId,{groupId,state}).get()
}

export const useDocument = (projectId,ideaId,noteId) => {
	return useDocumentHook(getDocumentRef(projectId,ideaId,noteId),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useDocumentOnce = (projectId,ideaId,noteId) => {
	return useDocumentOnceHook(getDocumentRef(projectId,ideaId,noteId));
}

export const useCollection = (projectId,ideaId,{groupId,state}={groupId:null,ideaId:null}) => {
	return useCollectionHook(getCollectionRef(projectId,ideaId,{groupId,state}),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useCollectionOnce = (projectId,ideaId,{groupId,state}={groupId:null,ideaId:null}) => {
	return useCollectionOnceHook(getCollectionRef(projectId,ideaId,{groupId,state}));
}

export const create = (projectId,ideaId,data) => {
	return createDocument(projectId,ideaId,data)
}

export const update = (projectId, ideaId, noteId, data) => {
	return updateDocument(projectId, ideaId, noteId, data);
}

export const archive = (projectId, ideaId, noteId) => {
	return updateDocument(projectId, ideaId, noteId, {state: STATE.ARCHIVED});
}

export const createDocument = (projectId,ideaId,data,batch) => {
	if(batch){
		return batch.set(newDocumentRef(projectId,ideaId),createData(data));
	}
	return newDocumentRef(projectId,ideaId).set(createData(data));
}

export const updateDocument = (projectId,ideaId,noteId,data,batch) => {
	if(batch){
		return batch.set(getDocumentRef(projectId,ideaId,noteId),updateData(data),{merge:true});
	}
	return getDocumentRef(projectId,ideaId,noteId).set(updateData(data),{merge:true});
}

export const duplicateAll = (projectId, ideaId, targetProjectId, targetIdeaId, batch) => {
	return new Promise((resolve, reject) => {
		getCollection(projectId,ideaId)
		.then((snapshot) => {
			const _batch = batch || firebase.firestore().batch();

			console.log(snapshot);

			snapshot.docs.forEach(doc => {
				console.log(doc.id);
				const data = doc.data();
				const copyRef = newDocumentRef(targetProjectId,targetIdeaId)
				_batch.set(copyRef,duplicateData({...data}))
			})

			if(batch !== _batch){
				_batch.commit()
				.then(() => resolve())
				.catch((error) => reject(error));
			}
			else{
				resolve()
			}
		})
		.catch((error) => {
			reject(error);
		});
	})
}
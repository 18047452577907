import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import { useCallouts } from "../../components/callout";
import { useProcessing } from "../../components/processing";

import idea from "../../actions/idea";

import Modal from "../../components/feedback/modal";
import TextField from "../../components/form/text-field";
import TextArea from "../../components/form/text-area";
import Button from "../../components/form/button";
import Scaffold from "../../components/layout/scaffold";

const Content = ({ projectId, ideaId, doc, onClose, onComplete }) => {
  const { t } = useTranslation();
  const data = doc.data();

  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const formik = useFormik({
    initialValues: {
      name: data.name,
      description: data.description,
    },
    validationSchema: yup.object({
      name: yup.string().required(t("idea.validation.name.required")),
      description: yup.string(),
    }),
    onSubmit: (values) => {
      const pid = addToQueue();
      idea
        .update(projectId, ideaId, values)
        .then((result) => {
          removeFromQueue(pid);
          addCallout({
            message: t("idea.alert.update.success"),
          });
          onComplete();
        })
        .catch((error) => {
          removeFromQueue(pid);
          addCallout({
            type: "error",
            message: t("idea.alert.update.fail"),
            error: error,
          });
        });
    },
  });

  return (
    <Modal
      onClose={onClose}
      heading={t("idea.heading.edit")}
      description={t("idea.description.edit")}
    >
      <Scaffold>
        <Scaffold direction="vertical" spaceBetween={2} spaceAfter={1}>
          <TextField
            name="name"
            value={formik.values.name}
            placeholder={t("idea.label.name")}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            hasError={formik.touched.name && Boolean(formik.errors.name)}
            errorMessage={formik.errors.name}
          />
          <TextArea
            name="description"
            rows={2}
            autoResize={true}
            value={formik.values.description}
            placeholder={t("idea.label.description")}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            hasError={
              formik.touched.description && Boolean(formik.errors.description)
            }
            errorMessage={formik.errors.description}
          />
        </Scaffold>
        <Scaffold
          direction="horizontal"
          align="end"
          spaceBetween={1}
          spaceBefore={3}
        >
          <Button
            variant="primary"
            onClick={formik.handleSubmit}
            label={t("idea.action.update")}
            disabled={!formik.isValid}
          />
        </Scaffold>
      </Scaffold>
    </Modal>
  );
};

const EditIdea = ({ projectId, ideaId, onClose, onComplete }) => {
  const { t } = useTranslation();
  const [doc, loading, error] = idea.useDocumentOnce(projectId, ideaId);

  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const pid = useRef();

  useEffect(() => {
    if (error) {
      addCallout({
        type: "error",
        message: t("error.general.description"),
        error: error,
      });
    }
  }, [error, addCallout, t]);

  useEffect(() => {
    if (loading) {
      pid.current = addToQueue();
    } else if (pid.current) {
      removeFromQueue(pid.current);
    }
  }, [loading, addToQueue, removeFromQueue]);

  if (error) return null;

  if (doc)
    return (
      <Content
        doc={doc}
        projectId={projectId}
        ideaId={ideaId}
        onClose={onClose}
        onComplete={onComplete}
      />
    );

  return null;
};

export default EditIdea;

import firebase from 'firebase/app'
import { useCollection as useCollectionHook, useCollectionOnce as useCollectionOnceHook, useDocument as useDocumentHook } from 'react-firebase-hooks/firestore';

import { prepareDataForCreate, prepareDataForUpdate, prepareDataForDuplicate } from '../helpers/firestore'

import idea from '../idea'

export const FIELDS = ['groupId','parameterId','text'];
export const COLLECTION_NAME = 'comments';

const createData = (data = {}) => {
	return prepareDataForCreate({
		...data,
	},FIELDS)
}

const updateData = (data) => {
	return prepareDataForUpdate(data,FIELDS)
}

const duplicateData = (data) => {
	return prepareDataForDuplicate(data,FIELDS)
}

export const newDocumentRef = (projectId,ideaId) => {
	return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME).doc();
}

export const getDocumentRef = (projectId,ideaId,commentId) => {
	return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME).doc(commentId);
}

export const getCollectionRef = (projectId,ideaId,groupId) => {
	if(groupId){
		return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME).where("groupId", "==", groupId)
	}

	return idea.getDocumentRef(projectId,ideaId).collection(COLLECTION_NAME)
}

export const getCollection = (projectId,ideaId,groupId) => {
	return getCollectionRef(projectId,ideaId,groupId).get()
}

export const useDocument = (projectId,ideaId,groupId,parameterId) => {
	const commentId = `${groupId}_${parameterId}`;
	return useDocumentHook(getDocumentRef(projectId,ideaId,commentId),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useCollection = (projectId,ideaId,groupId) => {
	return useCollectionHook(getCollectionRef(projectId,ideaId,groupId),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useCollectionOnce = (projectId,ideaId,groupId) => {
	return useCollectionOnceHook(getCollectionRef(projectId,ideaId,groupId));
}

export const update = (projectId, ideaId, groupId, parameterId, data) => {
	return updateDocument(projectId, ideaId, groupId, parameterId, data);
}

export const updateDocument = (projectId, ideaId, groupId, parameterId, data, batch) => {
	const commentId = `${groupId}_${parameterId}`;

	if(batch){
		return batch.set(getDocumentRef(projectId,ideaId,commentId),updateData({groupId,parameterId,...data}),{merge:true});
	}
	return getDocumentRef(projectId,ideaId,commentId).set(updateData({groupId,parameterId,...data}),{merge:true});
}


export const duplicateAll = (projectId, ideaId, targetProjectId, targetIdeaId, batch) => {
	return new Promise((resolve, reject) => {
		getCollection(projectId,ideaId)
		.then((snapshot) => {
			const _batch = batch || firebase.firestore().batch();

			snapshot.docs.forEach(doc => {
				const data = doc.data();
				const copyRef = newDocumentRef(targetProjectId,targetIdeaId)
				_batch.set(copyRef,duplicateData({...data}))
			})

			console.log(batch,_batch,batch===_batch);

			if(batch !== _batch){
				_batch.commit()
				.then(() => resolve())
				.catch((error) => {
					reject(error);
				});
			}
			else{
				resolve()
			}
		})
		.catch((error) => {
			reject(error);
		});
	})
}
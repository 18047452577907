import React, { useState, useEffect } from "react";
import { orderBy } from "lodash";

import idea from "../../../../actions/idea";
import project from "../../../../actions/project";

import DataTable from "../../../../components/data-display/data-table";
import Button from "../../../../components/form/button";
import Help from "../../../../components/feedback/help";

import AddEditAssumption from "../../../project/plan-to-learn/add-edit-assumption";

import { cellBuilder } from "./helper";
import { Scroll } from "./plan-to-learn-styles";

export const PlanToLearn = ({ projectId, ideaId, projectData, ideaData }) => {
  const [ideasSnapshot] = idea.useCollection(projectId);
  const [assumption, setAssumption] = useState(false);
  const [assumptionId, setAssumptionId] = useState("");
  const [rows, setRows] = useState([]);
  const [sort, setSort] = useState({ id: "", order: "" });

  const setOrder = (id) => {
    const { order } = sort;
    if (sort.id === "" || sort.id === "impact" || sort.id === "certainty")
      return { id, order: "asc" };
    if (order === "asc") return { ...sort, order: "desc" };
    if (order === "desc") return { id: "", order: "" };
  };

  const assumptions = projectData?.assumptions;
  const columns = [
    { header: "Assumption", width: 527, id: "assumption" },
    {
      header: "Area",
      width: 109,
      id: "area",
      sort: sort.id === "area",
      order: sort.order,
      onClick: () => setSort(setOrder("area")),
    },
    {
      header: (
        <Help
          content="Impact"
          variant="impact"
          background={false}
          size={3}
          color={sort.id === "impact" ? "green" : "default"}
        />
      ),
      justify: "center",
      padding: false,
      width: 56,
      id: "impact",
      onClick: () =>
        setSort(
          sort.id === "impact"
            ? { id: "", order: "" }
            : { id: "impact", order: "desc" }
        ),
    },
    {
      header: (
        <Help
          content="Certainty"
          variant="thumbs-up"
          background={false}
          size={3}
          color={sort.id === "certainty" ? "green" : "default"}
        />
      ),
      justify: "center",
      padding: false,
      width: 56,
      id: "certainty",
      onClick: () =>
        setSort(
          sort.id === "certainty"
            ? { id: "", order: "" }
            : { id: "certainty", order: "desc" }
        ),
    },
    { header: "Status", width: 88, id: "status" },
  ];

  useEffect(() => {
    if (assumptions) {
      const sorted = sort.id
        ? orderBy(assumptions, [`values.${sort.id}`], [sort.order])
        : assumptions;

      const filterClosed = [];
      const filterOpen = [];
      sorted.forEach((_) => {
        if (_.values.status.toLowerCase() === "closed") filterClosed.push(_);
        else return filterOpen.push(_);
      });
      const filtered = [...filterOpen, ...filterClosed];

      const data = filtered
        .map((_) => {
          if (_.values.options.find(({ id }) => id === ideaId)) {
            const cells = cellBuilder(_, columns, ideaId);
            return {
              cells,
              onClick: () => setAssumptionId(_.id),
              menu: [
                {
                  actionType: "icon",
                  icon: "edit",
                  label: "Edit assumption",
                  onClick: () => setAssumptionId(_.id),
                },
                {
                  actionType: "icon",
                  icon: "bin",
                  confirm: "Sure?",
                  label: "Delete",
                  onClick: () => project.removeAssumption(projectId, _.id),
                },
              ],
            };
          }
        })
        .filter((_) => _ != null);

      if (JSON.stringify(rows) !== JSON.stringify(data)) setRows(data);
    }
  }, [assumptions, sort]);

  useEffect(() => {
    if (assumptionId) setAssumption(true);
  }, [assumptionId]);

  return (
    <>
      <Button
        label="Add assumption"
        iconStart="add"
        onClick={() => setAssumption(!assumption)}
        spaceAfter={5}
      />

      <Scroll>
        <DataTable columns={columns} rows={rows} scroll />
      </Scroll>

      {assumption && (
        <AddEditAssumption
          onClose={() => {
            if (assumptionId) setAssumptionId("");
            setAssumption(!assumption);
          }}
          assumptionId={assumptionId}
          projectId={projectId}
          projectData={projectData}
          ideasSnapshot={ideasSnapshot}
          defaultOption={{
            label: ideaData.name,
            id: ideaId,
            value: ideaData.name,
          }}
        />
      )}
    </>
  );
};

export default PlanToLearn;

import React from 'react'
import PropTypes from 'prop-types';

import Box from '../../layout/box'
import Avatar from '../avatar'
import FileButton from '../file-button'

const AvatarUpload = ({
	color,
	firstName,
	lastName,
	src,
	label,
	onUpload,
	onRemove
}) => {
	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if(file){
			if(onUpload){
				onUpload({file})
			}
		}
	}

	const handleAvatarClick = (event) => {
		if(onRemove){
			onRemove()
		}
	}

	return (
		<Box flex="grow" marginTop={1} marginBottom={1}>
			<Avatar size="xlarge" color={color} firstName={firstName} lastName={lastName} src={src} onClick={handleAvatarClick} />
			<Box marginLeft={2} flex="grow" crossAxisAlignment="center">
				<FileButton onChange={handleFileChange} label={label} accept="image/*" />
			</Box>
		</Box>
	)
	
}

AvatarUpload.displayName = "AvatarUpload";

AvatarUpload.propTypes = {
	color: PropTypes.oneOf(['green', 'red', 'orange', 'grey']),
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	src: PropTypes.string,
	label: PropTypes.string,
	onUpload: PropTypes.func,
	onRemove: PropTypes.func,
};

AvatarUpload.defaultProps = {
	color: 'grey',
	firstName: undefined,
	lastName: undefined,
	src: undefined,
	label: undefined,
	onUpload: undefined,
	onRemove: undefined,
};

export default AvatarUpload

	
import styled from "styled-components";
import Color from "color";
import ModalMenu from "../../../components/feedback/modal-menu";
import { HEADER_HEIGHT, unit } from "../../../theme/metrics";
import { dropShadow } from "../../../theme/general";
import { GREY_01, BLUE_WASH } from "../../../theme/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: ${() => unit(HEADER_HEIGHT)};
  left: 0;
  width: 100%;
  height: ${() => `calc(100% - ${unit(HEADER_HEIGHT)})`};
  backdrop-filter: blur(5px) saturate(60%);
  background: rgba(11, 11, 14, 0.1);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1308px;
  width: 100%;
  height: 100%;
  margin-top: ${() => unit(4)};
  border-radius: ${() => `${unit(1)} ${unit(1)} 0px 0px`};
  background-color: white;
  overflow-y: scroll;
  ${dropShadow}
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const HeaderContainer = styled.header`
  padding: ${() => `${unit(3)} ${unit(7)} 0px ${unit(7)}`};
  background-color: white;
  z-index: 1;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  gap: ${unit(2)};
  padding-bottom: ${unit(2)};
  border-bottom: 1px solid ${() => Color(GREY_01)};
  h2 {
    padding-top: ${() => unit(1)};
  }
`;

export const Scroll = styled.div`
  padding: ${() => `${unit(6)} ${unit(7)} ${unit(9)} ${unit(7)}`};
  background-color: white;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Navigation = styled.div`
  width: 264px;
  background-color: ${() => Color(BLUE_WASH)};
  height: 100%;
`;

export const Menu = styled.div`
  position: relative;
`;
export const StyledModalMenu = styled(ModalMenu)`
  position: absolute;
  right: 0px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: ${() => `${unit(2.5)} ${unit(3)} 0px 0px`};
  gap: ${() => unit(1)};
`;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Icon from "../../feedback/icon";
import Box from "../../layout/box";

import { Btn } from "./button.styles";

const Content = ({
  variant = "primary",
  wash,
  size = "medium",

  label,
  to,
  className,

  iconStart,
  iconEnd,
  iconSize,
  roundedTop,
  roundedBottom,
  fitContainer,
  spaceBefore,
  spaceAfter,
  justify,

  tabIndex,

  disabled,
  selected,
  down,
  submit,

  accessibilityLabel,
  accessibilityControls,
  accessibilityExpanded,
  accessibilityHaspopup,

  onClick,
  onMouseOver,
  onMouseOut,
}) => {
  const [over, setOver] = useState(false);

  if (to) {
    return (
      <Btn
        justify={justify}
        size={size}
        variant={variant}
        wash={wash}
        over={over}
        down={down}
        selected={selected}
        disabled={disabled}
        roundedTop={roundedTop}
        roundedBottom={roundedBottom}
        spaceBefore={spaceBefore}
        spaceAfter={spaceAfter}
        withIcon={iconStart !== "none" || iconEnd !== "none"}
        as={Link}
        to={to}
        accessibilityLabel={accessibilityLabel}
        className={className}
        onClick={onClick}
        fitContainer={fitContainer}
        onMouseOver={(e) => {
          setOver(true);
          if (onMouseOver) {
            onMouseOver(e);
          }
        }}
        onMouseOut={(e) => {
          setOver(false);
          if (onMouseOut) {
            onMouseOut(e);
          }
        }}
        tabIndex={disabled ? null : tabIndex}
      >
        {iconStart && (
          <Box marginRight={1}>
            <Icon variant={iconStart} size={iconSize} color="white" />
          </Box>
        )}
        {label}
        {iconEnd && (
          <Box marginLeft={1}>
            <Icon variant={iconEnd} size={iconSize} color="white" />
          </Box>
        )}
      </Btn>
    );
  }

  return (
    <Btn
      justify={justify}
      size={size}
      variant={variant}
      wash={wash}
      over={over}
      down={down}
      selected={selected}
      disabled={disabled}
      roundedTop={roundedTop}
      roundedBottom={roundedBottom}
      spaceBefore={spaceBefore}
      spaceAfter={spaceAfter}
      withIcon={iconStart !== "none" || iconEnd !== "none"}
      as="button"
      aria-controls={submit ? accessibilityControls : null}
      aria-expanded={submit ? accessibilityExpanded : null}
      aria-haspopup={submit ? accessibilityHaspopup : null}
      aria-label={accessibilityLabel}
      className={className}
      onClick={onClick}
      fitContainer={fitContainer}
      onMouseOver={(e) => {
        setOver(true);
        if (onMouseOver) {
          onMouseOver(e);
        }
      }}
      onMouseOut={(e) => {
        setOver(false);
        if (onMouseOut) {
          onMouseOut(e);
        }
      }}
      tabIndex={disabled ? null : tabIndex}
      type={submit ? "submit" : "button"}
    >
      {iconStart && (
        <Box marginRight={1}>
          <Icon variant={iconStart} size={iconSize} color="white" />
        </Box>
      )}
      {label}
      {iconEnd && (
        <Box marginLeft={1}>
          <Icon variant={iconEnd} size={iconSize} color="white" />
        </Box>
      )}
    </Btn>
  );
};

const Button = (props) => {
  return <Content {...props} />;
};

Button.displayName = "Button";

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "transparent"]),
  wash: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  label: PropTypes.string.isRequired,
  down: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  iconStart: PropTypes.oneOf([
    "delete",
    "chevron",
    "align-left",
    "drag",
    "question-mark",
    "logo",
    "graph",
    "thumbs-up",
    "star",
    "add",
    "impact",
    "order",
  ]),
  iconEnd: PropTypes.oneOf([
    "delete",
    "chevron",
    "align-left",
    "drag",
    "question-mark",
    "logo",
    "graph",
    "thumbs-up",
    "star",
    "add",
    "impact",
    "order",
  ]),
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  roundedTop: PropTypes.bool,
  roundedBottom: PropTypes.bool,
  fitContainer: PropTypes.bool,
  justify: PropTypes.oneOf([
    "start",
    "center",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
  spaceBefore: PropTypes.number,
  spaceAfter: PropTypes.number,
};

Button.defaultProps = {
  variant: "primary",
  size: "medium",
  down: false,
  selected: false,
  disabled: false,
  onClick: undefined,
  onMouseOver: undefined,
  onMouseOut: undefined,
  roundedTop: true,
  roundedBottom: true,
  fitContainer: false,
};

export default Button;

import React, {useEffect, useState} from 'react'
import { useTranslation } from "react-i18next";
import {
  useParams,
  Redirect
} from "react-router-dom";

import { getDashboardPath } from '../../routing/paths'

import invitation from '../../actions/invitation'
import user from '../../actions/user'

import { useCallouts } from '../../components/callout'
import { useProcessing } from '../../components/processing'


const Invitation = (props) => {
	const { t } = useTranslation();
	const { addCallout } = useCallouts();
	const { addToQueue, removeFromQueue } = useProcessing();

	const { invitationId } = useParams();
	const [ accepted, setAccepted ] = useState(false);
	const [ pageError, setPageError ] = useState(null);
	
	useEffect(() => {
		const pid = addToQueue();
		
		invitation.getDocument(invitationId)
		.then(invitationDoc => {
			if(invitationDoc.exists){
				const invitationData = invitationDoc.data();

				if(invitationData.state === "pending"){
					
					if(user.getCurrentEmail() === invitationData.email){
						invitation.accept(invitationId)
						.then(() => {
							removeFromQueue(pid);
							setAccepted(true);
						})
						.catch((error) => {
							removeFromQueue(pid);
							setPageError({type:"general",error:error});
						})
					}
					else{
						removeFromQueue(pid);
						setPageError({type:"mismatch"});
					}
					
				}
				else{
					removeFromQueue(pid);
					setPageError({type:"invalid"});
				}
			}
			else{
				removeFromQueue(pid);
				setPageError({type:"invalid"});
			}
		})
		.catch(error => {
			removeFromQueue(pid);
			setPageError({type:"general",error:error});
		})
	}, [invitationId,addToQueue,removeFromQueue]);

	useEffect(() => {
		if(pageError){
			addCallout({
				type: "error",
				message: pageError.type === "invalid" ? t("invitation.error.invalid") : pageError.type === "mismatch" ? t("invitation.error.mismatch") : t("error.general.description"),
				error: pageError.error
			})
		}
	},[pageError,addCallout,t])

	if(accepted || pageError){
		return(
			<Redirect
				to={{
					pathname: getDashboardPath()
				}}
			/>
		)
	}

	return (
		null
	);
}  

export default Invitation
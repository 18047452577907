export const lookup = (snapshot) => {
  if (snapshot && snapshot.docs) {
    let l = {};
    snapshot.docs.forEach((doc) => {
      const data = doc.data();
      l[`${data.groupId}_${data.parameterId}`] = data;
    });

    return l;
  }
};

export const buildTable = (rows, data, columns, isOverview) => {
  /* Functions for the tables */
  return rows.map(({ id, overview }) => {
    const cell = data.prioritised?.find(
      ({ parameterId }) => parameterId === id
    );
    const arr = Array(columns.length).fill("-");
    if (isOverview) {
      overview.forEach((_, index) => {
        if (cell && Object.keys(cell.values).includes(_))
          arr[index] = cell.values[_].length ? cell.values[_] : "-";
      });
    } else if (cell) {
      columns.forEach((column, index) => {
        if (column.id.includes(id)) {
          arr[index] = cell.values[id].length ? cell.values[id] : "-";
        }
      });
    }
    return arr;
  });
};

export const updateTable = (table, values, id, rows, isOverview) => {
  const idea = table.data.findIndex((_) => _.id === id);
  const row = rows.find((_) => _.id === id);
  const _ = [...table.data];

  if (isOverview) {
    row.overview.map(
      (r, index) => (_[idea].cells[index] = values[r] ? values[r] : "-")
    );
  } else {
    table.columns.forEach((column, index) => {
      if (column.id.includes(id)) {
        _[idea].cells[index] = values[id] ? values[id] : "-";
      }
    });
  }
  return _;
};

import React from 'react'

import Text from '../../typography/text'

import { Container } from './tooltip.styles.js'

const Tooltip = ({
	label
}) => {

	return (
		<Container>
			<Text color="white">{label}</Text>
		</Container>
	);
}

export default Tooltip
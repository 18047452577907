import React from "react";
import PropTypes from "prop-types";

import Text from "../../typography/text";

import { Container } from "./badge.styles";

const Badge = ({ value, selected }) => {
  return (
    <Container selected={selected}>
      <Text color={selected ? "green" : "grey-02"}>{value}</Text>
    </Container>
  );
};

Badge.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
};

export default Badge;

import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from "react-i18next";

import { getLoginPath } from './paths'

import PageLoading from '../components/page-loading';
import PageError from '../components/page-error';

const User = ({children,location,to}) => {
	const [user, loading, error] = useAuthState(firebase.auth());
	const { t } = useTranslation();

	if (loading) return <PageLoading />
	if (error) return <PageError heading={t(`error.general.heading`)} description={t(`error.general.description`)} />
	
	if (user) return (
		<Redirect
			to={{
				pathname: to,
			}}
		/>
	)

	return (
		<Redirect
			to={{
				pathname: getLoginPath(),
				state: { from: location }
			}}
		/>
	)
}

const PrivateRedirect = ({ children, to, ...props }) => {
	return (
		<Route
			{...props}
			render={({ location }) => {
				return (
					<User to={to} location={location}>
						{children}
					</User>
				)
			}}
		/>
	);
}

export default PrivateRedirect;
import styled from "styled-components";
import Color from "color";
import { unit } from "../../../theme/metrics";
import {
  BLUE_WASH,
  GREEN_01,
  GREEN_WASH,
  PURPLE_00,
} from "../../../theme/colors";
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: ${() => unit(0.5)};
  width: fit-content;
  background-color: ${({ toggle }) =>
    toggle ? Color(GREEN_WASH) : Color(BLUE_WASH)};
  padding: ${() => unit(1)};
`;

export const Dot = styled.div`
  width: ${() => unit(1)};
  height: ${() => unit(1)};
  background-color: ${({ toggle }) =>
    toggle ? Color(GREEN_01) : Color(PURPLE_00)};
  border-radius: ${() => unit(0.5)};
  margin-right: ${() => unit(1)};
`;

import React, {useEffect, useState, useRef} from 'react'
import { useTranslation } from "react-i18next";

import { useCallouts } from '../../../components/callout'
import { useProcessing } from '../../../components/processing'

import idea from '../../../actions/idea'
import Text from '../../../components/typography/text'

import { Container, Background, Content, Name, NameContainer, NameHidden, Description } from './add-idea.styles.js'


const AddIdea = ({projectId,onClose,onComplete}) => {
	const { t } = useTranslation();
	const { addCallout } = useCallouts();
	const { addToQueue, removeFromQueue } = useProcessing();

	const nameRef = useRef();
	const descriptionRef = useRef();

	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [descriptionVisible, setDescriptionVisible] = useState(false);

	const submit = () => {
		const pid = addToQueue();
		idea.create(projectId,{
			name,
			description
		})
		.then(() => {
			removeFromQueue(pid);
			addCallout({
				message:t("idea.alert.create.success")
			})
			onComplete();
		})
		.catch((error) => {
			removeFromQueue(pid);
			addCallout({
				type: "error",
				message:t("idea.alert.create.fail"),
				error:error
			})
		})
	}

	const handleKeyDown = (event) => {
		if(event.keyCode === 13) { 
			if(event.shiftKey){
				setDescriptionVisible(true);
			}
			else{
				submit();
			}
		}
	}

	const handleDescriptionKeyDown = (event) => {
		if(event.keyCode === 13) { 
			if(!event.shiftKey){
				event.preventDefault();
				submit();
			}
		}
		else if(event.keyCode === 8) {
			if(description.length === 0){
				setDescriptionVisible(false);
			}
		}
	}

	useEffect(() => {
		nameRef.current.focus();
	},[]);

	useEffect(() => {
		if(descriptionVisible){
			descriptionRef.current.focus();
		}
		else{
			nameRef.current.focus();
		}
	}, [descriptionVisible]);

	return (
		<Container>
			<Background onClick={onClose} />
			<Content>
				<NameContainer>
					<NameHidden>{name}</NameHidden>
					<Name ref={nameRef} value={name} onChange={(event) => setName(event.currentTarget.value)} onKeyDown={handleKeyDown} />
				</NameContainer>
				{descriptionVisible && <Description ref={descriptionRef} value={description} onChange={event => setDescription(event.target.value)} onKeyDown={handleDescriptionKeyDown} />}
				<Text size="small" align="center">{descriptionVisible ? t("idea.help.addOrNewLine") : t("idea.help.addOrMoreDetail")}</Text>
			</Content>
		</Container>
	)
}

export default AddIdea;
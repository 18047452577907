import styled from 'styled-components'

import { unit } from '../../../theme/metrics'

export const Content = styled.div`
	width: 224px;
	${props => props.absolute ? 'position: absolute' : ''};
	border-radius: ${unit(2)};
	${props => props.verticalAlign === "top" ? `bottom: calc(100% + ${unit(2)});` : `top: calc(100% + ${unit(2)});`}
	${props => props.horizontalAlign === "left" ? `right: 0;` : `left: 0;`}
	display: flex;
	flex-direction: column;
	z-index: 10;
	box-shadow: 0px 26px 68px rgba(0, 0, 0, 0.07);
	border-radius: ${unit(1)};
`;

export const ItemContainer = styled.div`
	flex: 0 0 ${unit(6)};
	padding: 0 ${unit(2)};
	display: flex;
	align-items: center;
	background-color: ${props => props.over ? props.theme.background.secondary : props.theme.background.primary};
	cursor: pointer;

	${props => props.borderTop ? `
		border-top-left-radius: ${unit(1)};
		border-top-right-radius: ${unit(1)};
	` : ''};
	${props => props.borderBottom ? `
		border-bottom-left-radius: ${unit(1)};
		border-bottom-right-radius: ${unit(1)};
	` : ''};
`;

export const ItemContent = styled.div`
	flex: 1 1 auto;
`

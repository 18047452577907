import { useEffect, useState, useRef } from 'react'
import moment from 'moment';

const TICK = 1000;

const RelativeTime = ({
	time
}) => {
	const [ relativeTime, setRelativeTime ] = useState(null)
	
	let intervalRef = useRef()
	useEffect(() => {
		const update = () => {
			if(time){
				setRelativeTime(moment(time).fromNow())
			}
		}

		update()

		clearInterval(intervalRef.current);
		intervalRef.current = setInterval(() => update(), TICK)
		return () => {
			clearInterval(intervalRef.current);
		};
	},[time]);

	return relativeTime;
}

export default RelativeTime
import React, {useEffect, useRef} from 'react'
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useCallouts } from '../../components/callout'
import { useProcessing } from '../../components/processing'

import note from '../../actions/note'

import Modal from '../../components/feedback/modal'
import TextField from '../../components/form/text-field'
import TextArea from '../../components/form/text-area'
import Button from '../../components/form/button'
import Scaffold from '../../components/layout/scaffold'

const Content = ({projectId,ideaId,noteId,doc,onClose,onComplete}) => {
	const { t } = useTranslation();
	const data = doc.data();
	
	const { addCallout } = useCallouts();
	const { addToQueue, removeFromQueue } = useProcessing();

	const formik = useFormik({
		initialValues: {
			heading: data.heading,
			text: data.text,
		},
		validationSchema: yup.object({
			heading: yup.string()
				.required(t("note.validation.heading.required")),
			text: yup.string()
				.required(t("note.validation.text.required")),
		}),
		onSubmit: (values) => {
			const pid = addToQueue();
			note.update(projectId,ideaId,noteId,values)
			.then((result) => {
				removeFromQueue(pid);
				addCallout({
					message:t("note.alert.update.success")
				})
				onComplete();
			})
			.catch((error) => {
				removeFromQueue(pid);
				addCallout({
					type: "error",
					message:t("note.alert.update.fail"),
					error:error
				})
			})
		},
	});

	return (
		<Modal onClose={onClose} heading={t("note.heading.update")}>
			<Scaffold>
				<Scaffold direction="vertical" spaceBetween={2} spaceAfter={1}>
					<TextField name="heading" value={formik.values.heading} placeholder={t("note.label.heading")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.heading && Boolean(formik.errors.heading)} errorMessage={formik.errors.heading} />
					<TextArea name="text" rows={2} autoResize={true} value={formik.values.text} placeholder={t("note.label.text")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.text && Boolean(formik.errors.text)} errorMessage={formik.errors.text} />
				</Scaffold>
				<Scaffold direction="horizontal" align="end" spaceBetween={1} spaceBefore={3}>
					<Button variant="primary" onClick={formik.handleSubmit} label={t("note.action.update")} disabled={!formik.isValid} />
				</Scaffold>
			</Scaffold>
		</Modal>
	);
}

const EditNote = ({projectId, ideaId, noteId, onClose, onComplete}) => {
	const { t } = useTranslation();
	const [ doc, loading, error ] = note.useDocumentOnce(projectId,ideaId,noteId);

	const { addCallout } = useCallouts();
	const { addToQueue, removeFromQueue } = useProcessing();

	const pid = useRef();

	useEffect(() => {
		if(error){
			addCallout({
				type: "error",
				message:t("error.general.description"),
				error:error
			})
		}
	},[error,addCallout,t])

	useEffect(() => {
		if(loading){
			pid.current = addToQueue();
		}
		else if(pid.current){
			removeFromQueue(pid.current);
		}
	},[loading,addToQueue,removeFromQueue])

	if(error) return null

	if(doc) return <Content doc={doc} projectId={projectId} ideaId={ideaId} noteId={noteId} onClose={onClose} onComplete={onComplete} />

	return null;
}

export default EditNote

import React, { useEffect, useState } from "react";

import Text from "../../typography/text";
import Label from "../../typography/label";
import IconButton from "../../form/icon-button";
import Menu from "../menu";
import Divider from "../../layout/divider";
import Box from "../../layout/box";
import Icon from "../../feedback/icon";

import {
  Container,
  Header,
  Body,
  Row,
  Cell,
  MenuContainer,
} from "./data-table.styles.js";

const TableMenu = ({ items, over, scroll, isLast }) => {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <MenuContainer>
      <IconButton
        color="grey"
        variant={over ? "primary" : "secondary"}
        type="dots"
        selected={menuActive}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setMenuActive(!menuActive);
        }}
      />
      {menuActive && (
        <Menu
          onClose={() => setMenuActive(false)}
          verticalAlign={scroll && isLast ? "top" : "bottom"}
          horizontalAlign={scroll ? "left" : "right"}
          items={items}
        />
      )}
    </MenuContainer>
  );
};

const RowContent = ({
  id,
  cells,
  columns,
  menu,
  hasMenu,
  onClick,
  isOver,
  scroll,
  isLast,
}) => {
  const [over, setOver] = useState(false);

  useEffect(() => {
    if (over && isOver && id) isOver(id);
    if (!over && isOver) isOver();
  }, [over]);

  return (
    <Row
      onClick={onClick}
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
      over={over}
    >
      {cells.map((cell, index) => (
        <Cell key={index} width={columns[index].width} padding={true}>
          {typeof cell === "string" ? (
            <Text variant="tertiary" size="small">
              {cell}
            </Text>
          ) : (
            cell
          )}
        </Cell>
      ))}
      {hasMenu && (
        <Cell width={80} padding>
          {menu && (
            <TableMenu
              items={menu}
              over={over}
              scroll={scroll}
              isLast={isLast}
            />
          )}
        </Cell>
      )}
    </Row>
  );
};

const DataTable = ({ rows, columns, scroll, isOver }) => {
  const numberOfColumns = Math.max(
    rows.reduce(
      (result, currentValue) =>
        Math.max(result, currentValue.cells.length, columns.length),
      0
    )
  );

  const grow = (array, length) => {
    const result = [...array];
    while (result.length < length) {
      result.push(null);
    }
    return result;
  };

  const _columns = grow(columns, numberOfColumns);
  const _rows = rows.map((row) => ({
    ...row,
    cells: grow(row.cells, numberOfColumns),
  }));
  const hasMenu = rows.findIndex((row) => row.menu && row.menu.length > 0) >= 0;

  return (
    <Container>
      <Header>
        {_columns.map((column, index) => (
          <Cell
            key={index}
            width={column.width}
            padding={columns[index].padding ?? true}
            justify={column.justify}
            onClick={column.onClick}
            sort={column.sort}
          >
            {typeof column.header === "string" ? (
              <>
                <Label color={column.sort && "green"} variant="secondary">
                  {column.header}
                </Label>
                {column.sort && (
                  <Icon
                    variant="order"
                    position={column.order == "desc" ? "up" : "down"}
                    size={1}
                    color="green"
                  />
                )}
              </>
            ) : (
              column.header
            )}
          </Cell>
        ))}
        {hasMenu && <Cell width={80} padding={true} />}
      </Header>
      <Body scroll={scroll} padding={rows.length === 1}>
        {_rows.map((row, index) => (
          <Box direction="vertical" gap={1} key={index}>
            <RowContent
              hasDivider={index < _rows.length - 1}
              hasMenu={hasMenu}
              columns={_columns}
              isOver={isOver}
              scroll={scroll}
              isLast={rows.length > 1 && index === _rows.length - 1}
              {...row}
            />
            {index < _rows.length - 1 && <Divider />}
          </Box>
        ))}
      </Body>
    </Container>
  );
};

export default DataTable;

import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

import Text from "../../typography/text";
import Icon from "../icon/icon.component";

import { Container, Content, Button } from "./help.styles.js";

const Help = (props) => {
  const { content, background = true } = props;
  const [open, setOpen] = useState(false);

  const handleMouseEnter = (event) => {
    setOpen(true);
  };

  const handleMouseLeave = (event) => {
    setOpen(false);
  };

  return (
    <Container>
      <Button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        open={open}
        background={background}
        size={props.size}
      >
        <Icon {...props} />
      </Button>
      {open && (
        <Content width={props.width}>
          <Text color="white">
            <ReactMarkdown>{content}</ReactMarkdown>
          </Text>
        </Content>
      )}
    </Container>
  );
};

Help.propTypes = {
  background: PropTypes.bool,
  content: PropTypes.any,
  width: PropTypes.number,
};

export default Help;

import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";

import { Input, Container, Content, Help, Icon } from "./checkbox.styles.js";

const Checkbox = forwardRef(
  (
    {
      id,
      name,
      variant,
      checked,
      label,
      errorMessage,
      hasError,
      help,
      disabled,
      themeColor,
      size,
      bold,

      borderBottom,
      borderTop,

      onBlur,
      onChange,
      onFocus,

      inputOnly,
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const [over, setOver] = useState(false);

    const handleChange = (event) => {
      if (onChange) {
        onChange({ ...event, id, checked: event.target.checked });
      }
    };

    const handleBlur = (event) => {
      setFocused(false);
      if (onBlur) {
        onBlur({ ...event, id });
      }
    };

    const handleFocus = (event) => {
      setFocused(true);
      if (onFocus) {
        onFocus({ ...event, id });
      }
    };

    const handleOver = (event) => {
      event.stopPropagation();
      setOver(true);
    };

    const handleOut = (event) => {
      event.stopPropagation();
      setOver(false);
    };

    if (inputOnly) {
      return (
        <Content inputOnly>
          <Input
            ref={ref}
            aria-describedby={errorMessage && focused ? `${id}-error` : null}
            aria-invalid={errorMessage || hasError ? "true" : "false"}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          <Icon checked={checked} variant={variant} themeColor={themeColor} />
        </Content>
      );
    }

    return (
      <Container
        borderTop={borderTop}
        borderBottom={borderBottom}
        over={over}
        onMouseOver={handleOver}
        onMouseOut={handleOut}
        size={size}
      >
        <Content size={size} bold={bold}>
          <Input
            ref={ref}
            aria-describedby={errorMessage && focused ? `${id}-error` : null}
            aria-invalid={errorMessage || hasError ? "true" : "false"}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          {label}
          {help && <Help>{help}</Help>}
        </Content>
        <Icon checked={checked} variant={variant} themeColor={themeColor} />
      </Container>
    );
  }
);

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.oneOf(["checkbox", "switch"]),
  size: PropTypes.oneOf(["medium", "large"]),
  checked: PropTypes.bool,
  bold: PropTypes.bool,
  label: PropTypes.string,
  themeColor: PropTypes.oneOf(["dark", "light"]),
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  help: PropTypes.string,
  disabled: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  inputOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
  variant: "checkbox",
  size: "medium",
  themeColor: "light",
  bold: false,
  checked: false,
  hasError: false,
  disabled: false,
  borderBottom: false,
  borderTop: false,
};

Checkbox.displayName = Checkbox;

export default Checkbox;

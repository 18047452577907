import React from "react";

const QuestionMark = ({ color }) => (
  <svg
    width="100%"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.96897 6.82317H3.51901V6.71163C3.52832 5.80219 3.8495 5.38608 4.60822 4.9571C5.4647 4.48522 6 3.84175 6 2.85939C6 1.40944 4.77579 0.5 2.99767 0.5C1.3685 0.5 0.0465477 1.33222 0 2.96235H1.6571C1.69899 2.15586 2.33204 1.76978 2.98836 1.76978C3.70054 1.76978 4.27308 2.20734 4.27308 2.88084C4.27308 3.48141 3.84019 3.89323 3.27696 4.21926C2.45306 4.69113 1.97828 5.1673 1.96897 6.71163V6.82317ZM2.78355 9.5C3.33282 9.5 3.80295 9.0796 3.8076 8.55624C3.80295 8.04147 3.33282 7.62107 2.78355 7.62107C2.21567 7.62107 1.75485 8.04147 1.7595 8.55624C1.75485 9.0796 2.21567 9.5 2.78355 9.5Z"
      fill={color}
    />
  </svg>
);

export default QuestionMark;

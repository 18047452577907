import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
	releaseStage: process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'dev' ? 'development' : process.env.REACT_APP_ENV,
	plugins: [new BugsnagPluginReact()],
	enabledReleaseStages: [ 'prod', 'stage' ]
})

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React) 
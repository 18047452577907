export const SLUG = {
  DASHBOARD: "dashboard",
  PROJECT: "project",
  INVITATION: "invitation",
  LOGIN: "login",
  VERIFY: "verify",
  ONBOARDING: "onboarding",
};

export const getRootPath = () => {
  return `/`;
};

export const getDashboardPath = () => {
  return `/${SLUG.DASHBOARD}`;
};

export const getProjectPath = (projectId) => {
  if (projectId) {
    return `/${SLUG.PROJECT}/${projectId}`;
  }

  return `/`;
};

export const getIdeaPath = (projectId, ideaId) => {
  if (projectId) {
    if (ideaId) {
      return `/${SLUG.PROJECT}/${projectId}/${ideaId}`;
    }
    return `/${SLUG.PROJECT}/${projectId}`;
  }

  return `/`;
};

export const getInvitationPath = (invitationId) => {
  if (invitationId) {
    return `/${SLUG.INVITATION}/${invitationId}`;
  }

  return `/`;
};

export const getLoginPath = () => {
  return `/${SLUG.LOGIN}`;
};

export const getVerifyPath = () => {
  return `/${SLUG.LOGIN}/${SLUG.VERIFY}`;
};

export const getOnboardingPath = () => {
  return `/${SLUG.ONBOARDING}`;
};

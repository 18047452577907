import styled from 'styled-components'

import { spacer, unit } from '../../../theme/metrics'

export const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
	background-color: ${props => props.theme.background.primary};

	overflow: scroll;
`

export const Inner = styled.div`
	padding: ${spacer(2)};
	display: flex;
	min-height: 100%;
	align-items: center;
	justify-content: center;
`;

export const Header = styled.header`
	margin: 0 auto;
	padding: 0 0 ${unit(4)} 0;
`;

export const Content = styled.div`
	width: ${props => props.size === "small" ? spacer(10) : (props.size === "large" ? spacer(25) : spacer(16))};
	background-color: #FFFFFF;
	position: relative;
	margin: 0 auto;
	${props => props.center ? `margin-bottom: ${unit(7.5)}` : ''};
`
import React from "react";
import { Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PrivateRoute from "./private-route";
import PrivateRedirect from "./private-redirect";

import PageError from "../components/page-error";

import Login from "../views/login";
import Verify from "../views/verify";
import Dashboard from "../views/dashboard";
import Project from "../views/project";
import Invitation from "../views/invitation";
import Onboarding from "../views/onboarding";

import {
  getRootPath,
  getDashboardPath,
  getProjectPath,
  getInvitationPath,
  getLoginPath,
  getVerifyPath,
  getOnboardingPath,
} from "./paths";

const Router = () => {
  const { t } = useTranslation();

  return (
    <Switch>
      <PrivateRedirect exact path={getRootPath()} to={getDashboardPath()} />
      <PrivateRoute profileRequired={true} path={getDashboardPath()}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute profileRequired={true} path={getProjectPath(":projectId")}>
        <Project />
      </PrivateRoute>
      <PrivateRoute
        profileRequired={true}
        path={getInvitationPath(":invitationId")}
      >
        <Invitation />
      </PrivateRoute>
      <Route path={getVerifyPath()}>
        <Verify />
      </Route>
      <Route path={getLoginPath()}>
        <Login />
      </Route>
      <PrivateRoute profileRequired={false} path={getOnboardingPath()}>
        <Onboarding />
      </PrivateRoute>
      <Route>
        <PageError
          heading={t("error.notFound.heading")}
          description={t("error.notFound.description")}
        />
      </Route>
    </Switch>
  );
};

export default Router;

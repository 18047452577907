import React, { useRef, useEffect, Children, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { BLACK } from '../../../theme/theme'
import { unit } from '../../../theme/metrics'

import Box from '../../layout/box'
import Text from '../../typography/text'
import Checkbox from '../../form/checkbox'


import { Container, Background, ButtonContainer, Divider, CloseButton } from './popout.styles.js'

const Popout = ({
	children,
	onClose,
	horizontalAlign="right",
	footnote,
	pad,
}) => {
	const containerRef = useRef();

	const [swap, setSwap] = useState(false);

	const count = Children.count(children);

	useEffect(() => {
		if(containerRef.current.getBoundingClientRect().top < unit(8,{pixelate:false})){
			setSwap(true);
		}
	},[])

	const handleBackgroundClick = (event) => {
		if(onClose){
			onClose();
		}
	}

	return (
		<ThemeProvider theme={BLACK}>
			<Background onClick={handleBackgroundClick} />
			<Container ref={containerRef} pad={pad} swap={swap} horizontalAlign={horizontalAlign}>
				<Box 
				paddingTop={pad ? 4 : 0}
				paddingBottom={pad ? 3 : 0}
				paddingHorizontal={pad ? 3 : 0}
				direction="vertical"
				mainAxisAlignment="start"
				crossAxisAlignment="stretch"
				>
					{Children.map(children, (child,index) =>
						child !== null && child !== undefined ? (
						<Box
						paddingBottom={pad && index < count - 1 ? 2 : 0}
						direction="vertical"
						mainAxisAlignment="start"
						crossAxisAlignment="stretch"
						flex="grow"
						position="relative"
						>
							{child}
						</Box>
						) : null
					)}
					{footnote && 
						<Box
						paddingTop={3}
						direction="vertical"
						mainAxisAlignment="start"
						crossAxisAlignment="stretch"
						flex="grow"
						>
							<Text align="center" size="small">{footnote}</Text>
						</Box>
					}
				</Box>
				{pad && <CloseButton onClick={onClose} />}
			</Container>
		</ThemeProvider>
	);
}

export default Popout

export const PopoutButton = ({
	label,
	onClick,
}) => {
	const [ over, setOver ] = useState(false);

	const handleOver = (event) => {
		event.stopPropagation();
		setOver(true);
	}

	const handleOut = (event) => {
		event.stopPropagation();
		setOver(false);
	}

	return (
		<ButtonContainer over={over} onMouseOver={handleOver} onMouseOut={handleOut} onClick={onClick}>
			{label}
		</ButtonContainer>
	)
}

export const PopoutCheckbox = ({
	name,
	label,
	help,
	checked,
	onChange
}) => {
	return (
		<Checkbox variant="switch" name={name} size="large" label={label} help={help} checked={checked} onChange={onChange} />
	)
}

export const PopoutDivider = ({
}) => {
	return (
		<Divider />
	)
}
import React, { useContext, createContext, useEffect, useState, useCallback  } from 'react'
import uniqid from 'uniqid';
import { TransitionGroup, Transition } from 'react-transition-group';

import Callout from './callout.component'

import { Item } from './callout.styles.js'

const Context = createContext()

const AUTO_DISMISS = 2000;

const removeId = (callouts, id) => {
	var i = 0;
	var result = [];
	while (i < callouts.length) {
		if (callouts[i] !== id) {
			result.push(id)
		}
		i++;
	}
	return result;
}

export const CalloutProvider = ({ children }) => {
	const [callouts, setCallouts] = useState([])
	
	const ids = callouts.map(c => c.id).join(',')
	useEffect(() => {
		if (ids.length > 0) {
			const timer = setTimeout(() => setCallouts((callouts) => callouts.slice(0, callouts.length - 1)), AUTO_DISMISS)
			return () => clearTimeout(timer)
		}
	}, [ids])

	const addCallout = (callout) => setCallouts((callouts) => [{
		id: uniqid(),
		...callout
	}, ...callouts])

	const removeCallout = useCallback((id) => {
		setCallouts((callouts) => removeId(callouts,id))
	},[])

	const value = { addCallout, removeCallout }
		
	return (
		<Context.Provider value={value}>
			{children}
			<TransitionGroup>
				{callouts.map((callout) => 
					<Transition key={callout.id} in={true} timeout={300}>
					{(state) => (
						<Item state={state}>
							<Callout {...callout} />
						</Item>
					)}
					</Transition>

				)}
			</TransitionGroup>

		</Context.Provider>
	)
}

export const useCallouts = () => useContext(Context);
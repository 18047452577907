import React, { Fragment, Children } from "react";

import Box from "../box";

import { Container } from "./form.styles.js";

const Form = ({ children, direction = "vertical", onSubmit }) => {
  const count = Children.count(children);

  if (count === 0) {
    return null;
  }
  if (count === 1) {
    return <Fragment>{children}</Fragment>;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Box
        direction={direction}
        mainAxisAlignment="start"
        crossAxisAlignment="stretch"
        flex="grow"
        margin={-1}
        wrapItems
      >
        {Children.map(children, (child, index) =>
          child !== null && child !== undefined ? (
            <Box
              padding={1}
              direction="vertical"
              mainAxisAlignment="start"
              crossAxisAlignment="stretch"
              flex={index === 0 ? "grow" : null}
              position="relative"
            >
              {child}
            </Box>
          ) : null
        )}
      </Box>
    </Container>
  );
};

export default Form;

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

import {BUBBLE_COLORS, BUBBLE_COLOR_SCHEME, WHITE_01, BLACK_01} from "../../../theme/colors"

import Bubbles from "../../data-display/bubbles";
import Label from "../../typography/label"
import Text from "../../typography/text"
import Scaffold from "../scaffold"

import { Container, Tooltip, TooltipInner, ControlsContainer, ControlsOption, ScoreLabel, ConfidenceLabel } from './visualisation.styles.js'

export const CHART_TYPE = {
	bubble: 'bubble',
	scatter: 'scatter'
}

const MIN_BUBBLE_RADIUS = 0.2;
const SCATTER_BUBBLE_RADIUS = 0.2;


const VARIANT = {
	primary: "primary",
	secondary: "secondary",
};

const Controls = ({selected, onSelect, colorScheme}) => {
	return (
		<ControlsContainer>
			<ControlsOption onClick={() => onSelect({chartType:CHART_TYPE.bubble})} color={colorScheme} selected={selected === CHART_TYPE.bubble}>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.83335 3.33333C6.83335 3.79357 6.46026 4.16667 6.00002 4.16667C5.53978 4.16667 5.16669 3.79357 5.16669 3.33333C5.16669 2.8731 5.53978 2.5 6.00002 2.5C6.46026 2.5 6.83335 2.8731 6.83335 3.33333Z" stroke={selected === CHART_TYPE.bubble ? WHITE_01 : BLACK_01}/>
					<path d="M10.1667 10.6667C10.1667 12.9679 8.3012 14.8333 6.00001 14.8333C3.69882 14.8333 1.83334 12.9679 1.83334 10.6667C1.83334 8.36548 3.69882 6.5 6.00001 6.5C8.3012 6.5 10.1667 8.36548 10.1667 10.6667Z" stroke={selected === CHART_TYPE.bubble ? WHITE_01 : BLACK_01}/>
					<circle cx="12.3333" cy="4.33325" r="2.5" stroke={selected === CHART_TYPE.bubble ? WHITE_01 : BLACK_01} />
				</svg>
			</ControlsOption>
			<ControlsOption onClick={() => onSelect({chartType:CHART_TYPE.scatter})} color={colorScheme} selected={selected === CHART_TYPE.scatter}>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12 13.3334V6.66675" stroke={selected === CHART_TYPE.scatter ? WHITE_01 : BLACK_01} strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M8 13.3334V2.66675" stroke={selected === CHART_TYPE.scatter ? WHITE_01 : BLACK_01} strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M4 13.3333V9.33325" stroke={selected === CHART_TYPE.scatter ? WHITE_01 : BLACK_01} strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			</ControlsOption>
		</ControlsContainer>
	)
}

function Visualisation({data, categories, onSelect, selected, colorScheme = BUBBLE_COLOR_SCHEME.default}){
	const { t } = useTranslation();

	const [size, setSize] = useState({width: 0, height: 0});
	const [bubbles, setBubbles] = useState([]);
	
	const [mappedData, setMappedData] = useState({});
	const [hoverBubble, setHoverBubble] = useState();
	const [chartType, setChartType] = useState(CHART_TYPE.bubble);

	const container = useRef(null);

	const onHover = (bubble) => {
		if(bubble && bubble.id) setHoverBubble({id: bubble.id, x: bubble.x, y: bubble.y});
		else setHoverBubble()
	}

	const mapData = (data) => {
		let newMap = {};
		if(data)
		{
			data.forEach(d => {
				newMap[d.id] = d;
			});
		}
		setMappedData(newMap);
	}

	const handleSelect = (bubble) => {
		if(onSelect){
			onSelect({id:bubble.id});
		}
	}

	const updateBubbles = () => {
		if(!data) {
			setBubbles([])
			mapData();
		}
		else {
			let selectable = [];
			let dataMerged = [...data];
			const newBubbles = [];
			dataMerged.forEach(d => {
				
				selectable.push(d.label);

				const getSize = () => {
					
					if(selected && selected.length > 0)
					{
							if(selected.includes(d.id)) return 1;
							return MIN_BUBBLE_RADIUS;
					}

					if(chartType === CHART_TYPE.scatter)
					{
						return MIN_BUBBLE_RADIUS
					}

					return MIN_BUBBLE_RADIUS + (d.score * (1 - MIN_BUBBLE_RADIUS));
				}

				const getRadius = ()=> {
					if(!selected) return null;
					if(selected === d.label) return size.height / 4;
					return null;
				}

				const getPosition = (forceScorePosition = false) => {
					if(chartType === CHART_TYPE.scatter || forceScorePosition) return {x: (1/ 12) + ((d.score / 12) * 10), y: 0.9 - (d.confidence * 0.8)}

					return {x: .5, y: .5}
				}

				const getSelected = () => {
					return !(selected && !selected.includes(d.id));
				}

				if(chartType === CHART_TYPE.bubble || d.active){
					
					const dim = (selected && !selected.includes(d.id)) || ((selected == null || selected.length === 0) && colorScheme !== BUBBLE_COLOR_SCHEME.default && d.score === 0);

					// dim: (chartType === CHART_TYPE.scatter && !getSelected()) || (chartType === CHART_TYPE.bubble && !getSelected() && colorScheme !== BUBBLE_COLOR_SCHEME.default && d.score === 0),

					newBubbles.push({
						id: d.id,
						label: d.label,
						size: getSize(),
						fixedRadius: getRadius(),
						variant: d.shortlisted.f || d.shortlisted.i || d.shortlisted.v || d.shortlisted.e || d.shortlisted.x ? VARIANT.primary : VARIANT.secondary,
						interactive: true,
						dim: dim,
						position: getPosition(),
						startPosition: getPosition(false),
						filter: null,
						new: Object.keys(mappedData).length && !mappedData[d.id]
					})
				}
			});
			
			setBubbles(newBubbles);
			mapData(dataMerged);
		}
	}

	const handleResize = () => 
	{
		setSize({
			width: container.current.clientWidth,
			height: container.current.clientHeight
		});
	}

	const init = () => {
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}

	useEffect(updateBubbles, [data, selected, chartType, size])
	
	useEffect(init, []);

	return(
		<Container ref={container} gridLines={chartType === CHART_TYPE.scatter}>
			
			{bubbles && (bubbles.length > 0) && <Bubbles data={bubbles} onOver={onHover} onSelect={handleSelect} selected={selected} fillFactor={0.15} space={3} colors={BUBBLE_COLORS[colorScheme]} />}
			
			{chartType === CHART_TYPE.scatter && 
				<ScoreLabel>
					<Scaffold direction="horizontal" spaceBetween={2}>
						<Label variant="tertiary">- Low</Label>
						<Label variant="secondary">{t("idea.label.score")}</Label>
						<Label variant="tertiary">High +</Label>
					</Scaffold>
				</ScoreLabel>
			}
			{chartType === CHART_TYPE.scatter && 
				<ConfidenceLabel>
					<Scaffold direction="horizontal" spaceBetween={2}>
						<Label variant="tertiary">- Low</Label>
						<Label variant="secondary">{t("idea.label.confidence")}</Label>
						<Label variant="tertiary">High +</Label>
					</Scaffold>
				</ConfidenceLabel>
			}

			{hoverBubble && 
				<Tooltip x={hoverBubble.x} y={hoverBubble.y}>
					<TooltipInner>
						<Scaffold direction="vertical" spaceBetween={1}>
							<Label variant="primary">{mappedData[hoverBubble.id].label}</Label>
							<Text size="small" variant="tertiary">{`${Math.round((mappedData[hoverBubble.id].completed || 0) * 100)}% complete`}</Text>
							{mappedData[hoverBubble.id].description && <Text variant="secondary" spaceAfter={1}>{mappedData[hoverBubble.id].description}</Text>}
							{mappedData[hoverBubble.id].totals && mappedData[hoverBubble.id].totals.map((value,index) => (
								<Scaffold key={index} direction="horizontal" spaceBetween={1}>
									<Label>{value.score.mean.toFixed(2)}</Label>
									<Text size="small" variant="tertiary">{value.label}</Text>
								</Scaffold>
							))}
						</Scaffold>
					</TooltipInner>
				</Tooltip>
			}

			<Controls selected={chartType} colorScheme={colorScheme} onSelect={(event) => setChartType(event.chartType)} />

		</Container>
	)

}

export default Visualisation; 
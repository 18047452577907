import firebase from "firebase/app";
import uniqid from "uniqid";
import {
  useDocument as useDocumentHook,
  useDocumentOnce as useDocumentOnceHook,
  useCollection as useCollectionHook,
} from "react-firebase-hooks/firestore";

import {
  prepareDataForCreate,
  prepareDataForUpdate,
  prepareDataForDuplicate,
  getUser,
} from "../helpers/firestore";

import project from "../project";

const FIELDS = [
  "name",
  "description",
  "state",
  "entries",
  "prioritised",
  "values",
  "documents",
  "notes",
];
const COLLECTION_NAME = "ideas";
const STATE = {
  ACTIVE: "active",
  ARCHIVED: "archived",
};

const createData = (data) => {
  return prepareDataForCreate(
    {
      entries: [],
      prioritised: [],
      state: STATE.ACTIVE,
      ...data,
    },
    FIELDS
  );
};

const updateData = (data = {}) => {
  return prepareDataForUpdate(data, FIELDS);
};

const duplicateData = (data = {}) => {
  return prepareDataForDuplicate(data, FIELDS);
};

export const newDocumentRef = (projectId) => {
  return project.getDocumentRef(projectId).collection(COLLECTION_NAME).doc();
};

export const getDocumentRef = (projectId, ideaId) => {
  return project
    .getDocumentRef(projectId)
    .collection(COLLECTION_NAME)
    .doc(ideaId);
};

export const getCollectionRef = (projectId) => {
  return project
    .getDocumentRef(projectId)
    .collection(COLLECTION_NAME)
    .where("state", "==", STATE.ACTIVE);
};

export const getCollection = (projectId) => {
  return getCollectionRef(projectId).get();
};

export const useCollection = (projectId) => {
  return useCollectionHook(getCollectionRef(projectId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
};

export const useDocument = (projectId, ideaId) => {
  return useDocumentHook(getDocumentRef(projectId, ideaId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
};

export const useDocumentOnce = (projectId, ideaId) => {
  return useDocumentOnceHook(getDocumentRef(projectId, ideaId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
};

export const create = (projectId, data) => {
  return createDocument(projectId, data);
};

export const archive = (projectId, ideaId) => {
  return updateDocument(projectId, ideaId, { state: STATE.ARCHIVED });
};

export const update = (projectId, ideaId, data) => {
  return updateDocument(projectId, ideaId, data);
};

export const addPrioritisedContent = (projectId, ideaId, data) => {
  return getDocumentRef(projectId, ideaId).set(
    {
      prioritised: firebase.firestore.FieldValue.arrayUnion({
        ...data,
        createdBy: getUser(),
        createdTime: firebase.firestore.Timestamp.now(),
      }),
      ...updateData(),
    },
    { merge: true }
  );
};

const updatePrioritisedData = (prioritised, id, data) => {
  const index = prioritised.findIndex((n) => n.parameterId === id);
  prioritised[index] = {
    ...prioritised[index],
    values: data,
    updatedBy: getUser(),
    updatedTime: firebase.firestore.Timestamp.now(),
  };

  return prioritised;
};

export const updatePrioritisedContent = (
  projectId,
  ideaId,
  prioritisedId,
  prioritisedData
) => {
  return new Promise((resolve, reject) => {
    const ref = getDocumentRef(projectId, ideaId);
    ref
      .get()
      .then((doc) => {
        const data = doc.data();
        const prioritised = updatePrioritisedData(
          data.prioritised,
          prioritisedId,
          prioritisedData
        );
        ref
          .set({ prioritised, ...updateData() }, { merge: true })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addEntry = (projectId, ideaId, data) => {
  return getDocumentRef(projectId, ideaId).set(
    {
      entries: firebase.firestore.FieldValue.arrayUnion({
        ...data,
        createdBy: getUser(),
        createdTime: firebase.firestore.Timestamp.now(),
      }),
      ...updateData(),
    },
    { merge: true }
  );
};

export const deleteEntry = (projectId, ideaId, data) => {
  console.log("deleteEntry",projectId, ideaId, data)
  return getDocumentRef(projectId, ideaId).set(
    {
      entries: firebase.firestore.FieldValue.arrayRemove(data),
      ...updateData(),
    },
    { merge: true }
  );
};

export const addNote = (projectId, ideaId, noteData) => {
  return getDocumentRef(projectId, ideaId).set(
    {
      notes: firebase.firestore.FieldValue.arrayUnion({
        id: uniqid(),
        ...noteData,
        createdBy: getUser(),
        createdTime: firebase.firestore.Timestamp.now(),
        updatedBy: getUser(),
        updatedTime: firebase.firestore.Timestamp.now(),
      }),
      ...updateData(),
    },
    { merge: true }
  );
};

const removeNoteFromData = (notes, id) => {
  const index = notes.findIndex((note) => note.id === id);
  if (index >= 0) {
    notes.splice(index, 1);
  }

  return notes;
};

const updateNoteData = (notes, id, data) => {
  const index = notes.findIndex((n) => n.id === id);
  notes[index] = {
    ...notes[index],
    ...data,
    updatedBy: getUser(),
    updatedTime: firebase.firestore.Timestamp.now(),
  };

  return notes;
};

export const updateNote = (projectId, ideaId, noteId, noteData) => {
  return new Promise((resolve, reject) => {
    const ref = getDocumentRef(projectId, ideaId);
    ref
      .get()
      .then((doc) => {
        const data = doc.data();
        const notes = updateNoteData(data.notes, noteId, noteData);
        ref
          .set({ notes, ...updateData() }, { merge: true })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteNote = (projectId, ideaId, noteId) => {
  return new Promise((resolve, reject) => {
    const ref = getDocumentRef(projectId, ideaId);
    ref
      .get()
      .then((doc) => {
        const data = doc.data();
        const notes = removeNoteFromData(data.notes, noteId);
        ref
          .set({ notes, ...updateData() }, { merge: true })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addDocumentFile = (projectId, ideaId, filename) => {
  return getDocumentRef(projectId, ideaId).set(
    {
      documents: firebase.firestore.FieldValue.arrayUnion(filename),
      ...updateData(),
    },
    { merge: true }
  );
};

export const duplicate = (projectId, ideaId) => {
  return new Promise((resolve, reject) => {
    const functions =
      window.location.hostname === "localhost" &&
      process.env.REACT_APP_EMULATOR === "true"
        ? firebase.functions()
        : firebase.app().functions("europe-west1");

    getDocument(projectId, ideaId)
      .then((doc) => {
        const data = doc.data();

        functions
          .httpsCallable("duplicateIdea")({
            projectId,
            ideaId,
            name: `Copy of ${data.name}`,
          })
          .then((result) => {
            resolve();
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
};

export const getDocument = (projectId, ideaId) => {
  return getDocumentRef(projectId, ideaId).get();
};

export const createDocument = (projectId, data, batch) => {
  if (batch) {
    return batch.set(newDocumentRef(projectId), createData(data));
  }
  return newDocumentRef(projectId).set(createData(data));
};

export const updateDocument = (projectId, ideaId, data, batch) => {
  if (batch) {
    return batch.set(getDocumentRef(projectId, ideaId), updateData(data), {
      merge: true,
    });
  }
  return getDocumentRef(projectId, ideaId).set(updateData(data), {
    merge: true,
  });
};

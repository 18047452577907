import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import inputs from "./add-edit-assumption.json";

import { addEditAssumption } from "../../../../actions/project/project.actions";

import Box from "../../../../components/layout/box";
import Modal from "../../../../components/feedback/modal";
import Help from "../../../../components/feedback/help";
import TextArea from "../../../../components/form/text-area";
import Dropdown from "../../../../components/form/dropdown";
import Button from "../../../../components/form/button";
import MultipleChoice from "../../../../components/form/multiple-choice";
import Label from "../../../../components/typography/label";
import { useProcessing } from "../../../../components/processing";
import { useCallouts } from "../../../../components/callout";

const Input = ({ id, type, label, checkbox, placeholder, options, formik }) => {
  const [dropdown, setDropdown] = useState(false);
  const { values, setValues, handleChange, handleBlur, errors } = formik;

  const handleDropdownChange = (option) => {
    if (Array.isArray(values[id])) {
      const find = values[id].find((_) => _.id === option.id);
      if (!find) {
        setValues({
          ...values,
          [id]: [...values[id], { value: option.value, id: option.id }],
        });
      } else {
        setValues({
          ...values,
          [id]: values[id].filter((_) => _.id !== option.id),
        });
      }
    } else {
      setValues({
        ...values,
        [id]: option.value,
      });
      setDropdown(!dropdown);
    }
  };

  switch (type) {
    case "text-area":
      return (
        <TextArea
          name={id}
          value={values[id]}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          hasError={errors[id]}
          errorMessage={errors[id]}
          rows={3}
        />
      );
    case "dropdown":
      return (
        <Dropdown
          type="input"
          label={
            Array.isArray(values[id])
              ? values[id].length
                ? values[id].map(({ value }, index) => {
                    const ellipsis =
                      value.length > 4 ? `${value.substr(0, 4)}...` : value;
                    return index < values[id].length - 1
                      ? `${ellipsis}, `
                      : `${ellipsis}`;
                  })
                : label
              : values[id]
              ? values[id]
              : label
          }
          open={dropdown}
          onClick={() => setDropdown(!dropdown)}
          justify="space-between"
          content={
            options &&
            options.map((option) => ({
              text: option.label,
              name: option.id,
              size: "small",
              actionType: checkbox ? "checkbox" : "none",
              inputOnly: true,
              checked: Array.isArray(values[id])
                ? Boolean(values[id].find((_) => _.id === option.id))
                : values[id] === option.label,
              onChange: () => handleDropdownChange(option),
              onClick: () => handleDropdownChange(option),
            }))
          }
        />
      );
    case "multiple-choice":
      return (
        <MultipleChoice
          name={id}
          items={options}
          active={values[id] == null}
          selected={values[id]}
          onSelect={(item) => setValues({ ...values, [id]: item.value })}
          onBlur={handleBlur}
        />
      );
    default:
      break;
  }
};

const AddEditAssumption = ({
  onClose,
  assumptionId,
  projectId,
  projectData,
  ideasSnapshot,
  defaultOption,
}) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  useEffect(() => {
    if (ideasSnapshot) {
      const data = ideasSnapshot.docs.map((_) => {
        const { name } = _.data();
        return { label: name, id: _.id, value: name };
      });
      const input = inputs.find(({ id }) => id === "options");

      if (input.options.length) {
        data.map((_) => {
          const option = input.options.find(({ id }) => id === _.id);
          if (!option) {
            input.options.push(_);
          }
        });
      } else input.options.push(...data);
    }
  }, [ideasSnapshot]);

  const formik = useFormik({
    initialValues: {
      assumption: "",
      area: "",
      options: defaultOption ? [defaultOption] : [],
      status: "Open",
      impact: "",
      certainty: "",
    },
    validationSchema: yup.object().shape({
      assumption: yup
        .string()
        .max(500, "You have reached the maximum character length."),
      area: yup.string(),
      options: yup
        .array()
        .of(yup.object().shape({ value: yup.string(), id: yup.string() })),
      status: yup.string(),
      impact: yup.number(),
      certainty: yup.number(),
    }),
    onSubmit: (values) => {
      let id = assumptionId || 1;
      const assumptions = projectData?.assumptions;
      if (!assumptionId && assumptions?.length)
        id = Math.max.apply(
          Math,
          assumptions.map(({ id }) => (id += 1))
        );

      const pid = addToQueue();
      addEditAssumption(projectId, id, values)
        .then((status) => {
          const message =
            status === "add"
              ? t("planToLearn.callout.add")
              : t("planToLearn.callout.update", { id });
          removeFromQueue(pid);
          addCallout({
            message,
          });
          onClose();
        })
        .catch((error, status) => {
          const message =
            status === "add"
              ? t("planToLearn.callout.error-add")
              : t("planToLearn.callout.error-update", { id });
          removeFromQueue(pid);
          addCallout({
            type: "error",
            message,
            error,
          });
          onClose();
        });
    },
  });
  const {
    setValues,
    values,
    errors,
    handleSubmit,
    isValid,
    isSubmitting,
  } = formik;

  useEffect(() => {
    if (assumptionId) {
      const assumption = projectData?.assumptions.find(
        ({ id }) => id === assumptionId
      );
      if (assumption) setValues(assumption.values);
    }
  }, []);

  const isFormValid =
    isValid &&
    !isSubmitting &&
    !Object.entries(values).every(([_, value]) => !value) &&
    isEmpty(errors);

  return (
    <Modal
      heading="Assumption"
      headingSize="medium"
      color="green"
      align="left"
      onClose={onClose}
    >
      {inputs.map((input) => (
        <Box key={input.id} direction="vertical" paddingBottom={3}>
          <Box mainAxisAlignment="evenly">
            {input.label && input.type !== "dropdown" && (
              <Label variant="tertiary" spaceAfter={1}>
                {input.label}
              </Label>
            )}
            {input.tooltip && (
              <Help
                content={input.tooltip}
                color="white"
                variant="question-mark"
                size={0.75}
              />
            )}
          </Box>
          <Input formik={formik} {...input} />
        </Box>
      ))}
      <Button
        label="Save"
        size="medium"
        onClick={handleSubmit}
        disabled={!isFormValid}
        fitContainer
      />
    </Modal>
  );
};

export default AddEditAssumption;

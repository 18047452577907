import styled, { css } from "styled-components";

import { typeText1Regular } from "../../../theme/typography";

import { unit } from "../../../theme/metrics";

import {
  WHITE_01,
  BLUE_WASH,
  GREEN_02,
  DARK_GREY_02,
  GREEN_01,
  GREY_02,
  GREY_00,
} from "../../../theme/colors";

const primary = css`
  background-color: ${GREEN_01};
  color: ${WHITE_01};
  &:hover {
    background-color: ${GREEN_02};
  }
`;

const seconday = css`
  background-color: ${BLUE_WASH};
  &:hover {
    background-color: ${GREY_02};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: ${(props) => (props.size === "large" ? unit(8.75) : unit(6))};
  padding-left: ${unit(2)};
  padding-right: ${unit(2)};
  cursor: pointer;
  ${({ theme }) =>
    (theme === "primary" && primary) ||
    (theme === "secondary" && seconday) ||
    (theme === "tertiary" && `background-color: ${WHITE_01};`) ||
    (theme === "transparent" && `background-color: transparent`)};
  ${({ roundedTop }) =>
    roundedTop
      ? `
		border-top-left-radius: ${unit(1)};
		border-top-right-radius: ${unit(1)};
	`
      : ""};

  ${({ roundedBottom }) =>
    roundedBottom
      ? `
		border-bottom-left-radius: ${unit(1)};
		border-bottom-right-radius: ${unit(1)};
	`
      : ""};

  ${({ borderBottom }) =>
    borderBottom ? `border-bottom: 1px solid ${DARK_GREY_02};` : ""};
  transition: all 0.3s;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  ${typeText1Regular};

  color: ${({ theme }) => (theme === "primary" ? WHITE_01 : GREY_00)};
`;

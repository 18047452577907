import styled from 'styled-components'

import { unit } from '../../../theme/metrics'
import { typeText3Regular } from '../../../theme/typography'

export const Container = styled.div`
	width: 390px;
	background-color: ${props => props.theme.background.primary};
	position: absolute;
	border-radius: ${unit(1)};
	${props => props.swap ? `top: ${unit(6)};` : `bottom: ${unit(6)};`}
	${props => props.horizontalAlign === "left" ? `right: ${unit(5)};` : `left: calc(100% - ${unit(5)});`}
	overflow: hidden;
	z-index: 101;
	cursor: default;
`

export const Background = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
`

export const ButtonContainer = styled.div`
	${typeText3Regular}
	background-color: ${props => props.over ? props.theme.background.secondary : props.theme.background.primary};
	color: ${props => props.theme.foreground.primary};
	padding: ${unit(2)};
	cursor: pointer;
`

export const Divider = styled.div`
	height: 1px;
	background-color: ${props => props.theme.keyline};
`

export const CloseButton = styled.button`
	width: ${unit(3)};
	height: ${unit(3)};
	cursor: pointer;
	background-color: transparent;
	position: absolute;
	border-radius: ${unit(1.5)};
	top: ${unit(1)};
	right: ${unit(1)};
	border: 1px solid ${props => props.theme.keyline};

	&:focus {
		outline:none;
	}

	&:before, &:after{
		position: absolute;
		top: 50%;
		left: 50%;
		transform-origin: center center;
		transform: translate(-50%,-50%);
		content: "";
		width: ${unit(1)};
		height: 1px;
		background-color: ${props => props.theme.foreground.primary};
	}

	&:before {
		transform: translate(-50%,-50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%,-50%) rotate(-45deg);
	}
`
import Color from "color";
import styled from "styled-components";
import { BLUE_WASH, GREEN_WASH } from "../../../theme/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  width: fit-content;
  min-width: 25px;
  height: 25px;
  border-radius: 72px;
  background-color: ${({ selected }) =>
    selected ? Color(GREEN_WASH) : Color(BLUE_WASH)};
`;

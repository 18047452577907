import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize';

import { typeHeading1, typeText2Regular } from '../../../theme/typography'
import { unit } from '../../../theme/metrics'
import { dropShadow } from '../../../theme/general'

export const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

`

export const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.5);
`

export const Content = styled.div`
	height: 172px;
	border-radius: 86px;
	padding: 0 86px;
	background-color: white;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: stretch;
	${dropShadow}
`

export const Description = styled(TextareaAutosize)`
	${typeText2Regular}
	outline: none;
	border: none;
	resize: none;
	text-align: center;
	margin-bottom: ${unit(1)};
`

export const NameContainer = styled.div`
	position: relative;
`

export const NameHidden = styled.div`
	${typeHeading1}
	height: 80px;
	line-height: 80px;
	visibility: hidden;
	padding: 0 ${unit(2)};
`

export const Name = styled.input.attrs(props => ({
	type: 'text',
}))`
	${typeHeading1}
	height: 80px;
	line-height: 80px;
	border: none;
	outline: none;
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
`


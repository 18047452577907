import styled from "styled-components";

import { unit } from "../../../theme/metrics";
import { dropShadow } from "../../../theme/general";
import { KEYLINE_LIGHT, WHITE_01, BLUE_WASH } from "../../../theme/colors";

export const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${unit(0)} ${unit(2)};
  gap: ${unit(1)};
  width: 100%;
  height: ${() => unit(6)};
  background-color: ${({ open }) => (open ? BLUE_WASH : WHITE_01)};
  border: ${({ open }) => `1px solid ${open ? "transparent" : KEYLINE_LIGHT}`};
  border-radius: ${({ open }) =>
    `${unit(0.5)} ${unit(0.5)}  ${unit(open ? 0 : 0.5)} ${unit(
      open ? 0 : 0.5
    )}`};
  box-sizing: border-box;
`;

export const DropdownBox = styled.div`
  position: absolute;
  width: 100%;
  top: ${unit(6)};
  z-index: 1;
  max-height: ${unit(35)};
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  border-radius: 0 0 ${unit(0.5)} ${unit(0.5)};
  ${dropShadow}
`;

export const Container = styled.div`
  width: ${unit(34)};
  background-color: ${({ theme }) => theme.background.primary};
  position: absolute;
  border-radius: ${unit(1)};
  overflow: hidden;
  top: calc(100% + ${unit(1)});
  ${dropShadow}
  z-index: 1;
`;

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import idea from "../../actions/idea";

import Modal from "../../components/feedback/modal";
import MultipleChoice from "../../components/form/multiple-choice";
import TextArea from "../../components/form/text-area";
import Button from "../../components/form/button";
import Divider from "../../components/layout/divider";
import Scaffold from "../../components/layout/scaffold";
import Box from "../../components/layout/box";
import Heading from "../../components/typography/heading";
import Text from "../../components/typography/text";
import Help from "../../components/feedback/help";

import { useCallouts } from "../../components/callout";
import { useProcessing } from "../../components/processing";

const getMinValue = (options) => {
  return Math.min(...options.map((o) => o.value));
};

const getMaxValue = (options) => {
  return Math.max(...options.map((o) => o.value));
};

const AddEntry = ({
  projectId,
  ideaId,
  groupId,
  parameter,
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const noteRef = useRef();

  const formik = useFormik({
    initialValues: {
      note: "",
    },
    initialErrors: {
      scoreValue: t("entry.validation.score.required"),
      confidenceValue: t("entry.validation.confidence.required"),
    },
    validationSchema: yup.object({
      scoreValue: yup.number().required(t("entry.validation.score.required")),
      confidenceValue: yup
        .number()
        .required(t("entry.validation.confidence.required")),
      note: yup.string(),
    }),
    onSubmit: (values) => {
      const pid = addToQueue();
      idea
        .addEntry(projectId, ideaId, {
          parameterId: parameter.id,
          groupId: groupId,
          score: {
            min: getMinValue(parameter.score),
            max: getMaxValue(parameter.score),
            value: values.scoreValue,
          },
          confidence: {
            min: getMinValue(parameter.confidence),
            max: getMaxValue(parameter.confidence),
            value: values.confidenceValue,
          },
          note: values.note,
        })
        .then((result) => {
          removeFromQueue(pid);
          addCallout({
            message: t("entry.alert.create.success"),
          });
          onComplete();
        })
        .catch((error) => {
          removeFromQueue(pid);
          addCallout({
            type: "error",
            message: t("entry.alert.create.fail"),
            error: error,
          });
        });
    },
  });

  let help = "";
  parameter.score.forEach((s) => {
    if (s.help) {
      help += `- **${s.label}** - ${s.help}\n`;
    }
  });

  return (
    <Modal color="black" onClose={onClose}>
      <Scaffold direction="vertical" spaceBetween={3}>
        <Scaffold direction="vertical" spaceBetween={1}>
          <Box flex="shrink">
            <Heading size="medium">{t("idea.label.score")}</Heading>
            {help.length > 0 && (
              <Box paddingLeft={1} crossAxisAlignment="center">
                <Help
                  content={help}
                  color="white"
                  variant="question-mark"
                  size={0.75}
                />
              </Box>
            )}
          </Box>
          {parameter.description && (
            <Text size="large">{parameter.description}</Text>
          )}
        </Scaffold>
        <MultipleChoice
          direction="vertical"
          items={parameter.score}
          onSelect={(item) =>
            formik.setValues({ ...formik.values, scoreValue: item.value })
          }
          active={formik.values.scoreValue == null}
          selected={formik.values.scoreValue}
        />
        <Box flex="shrink">
          <Heading size="medium">{t("idea.label.confidence")}</Heading>
          <Box paddingLeft={1} crossAxisAlignment="center">
            <Help
              content={t("idea.help.confidence")}
              color="white"
              variant="question-mark"
              size={0.75}
            />
          </Box>
        </Box>
        <MultipleChoice
          items={parameter.confidence}
          onSelect={(item) =>
            formik.setValues({ ...formik.values, confidenceValue: item.value })
          }
          active={
            formik.values.confidenceValue == null &&
            formik.values.scoreValue != null
          }
          selected={formik.values.confidenceValue}
        />
        <Divider />
        <TextArea
          ref={noteRef}
          rows={2}
          name="note"
          value={formik.values.note}
          placeholder={t("idea.label.note")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <Button
          label={t("idea.action.save")}
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
        />
      </Scaffold>
    </Modal>
  );
};

export default AddEntry;

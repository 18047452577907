import React, { useEffect, useCallback } from "react";

import { Items, Item, Button } from "./multiple-choice.styles.js";

const MultipleChoice = ({
  items,
  selected,
  active,
  direction,
  onSelect,
  onBlur,
  name,
}) => {
  const handleKeyPress = useCallback(
    (event) => {
      const { key } = event;
      const selected = items.find((item) => item.value.toString() === key);

      if (selected) {
        onSelect(selected);
      }
    },
    [items, onSelect]
  );

  useEffect(() => {
    if (active) {
      window.addEventListener("keyup", handleKeyPress);
    } else {
      window.removeEventListener("keyup", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [active, handleKeyPress]);

  return (
    <Items direction={direction}>
      {items &&
        items.map((item, index) => (
          <Item key={index}>
            <Button
              name={name}
              onClick={() => onSelect(item)}
              selected={selected === item.value}
              onBlur={onBlur}
            >
              {item.label}
            </Button>
          </Item>
        ))}
    </Items>
  );
};

export default MultipleChoice;

import styled from 'styled-components'

import { unit } from '../../../../theme/metrics'

export const ContentContainer = styled.div`
	flex: 1 1 auto;
	display: flex;
	padding-right: ${unit(4)};
`

export const ButtonContainer = styled.div`
	
`

export const MenuContainer = styled.div`
  position: relative;
  flex: 0;
  display: flex;
  justify-content: flex-end;
`;
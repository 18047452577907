import React, {useEffect, useRef} from 'react'
import gsap from "gsap";

import { Container, Bar } from './processing.styles.js'

const Processing = ({
	inProgress
}) => {
	const barRef = useRef();

	useEffect(() => {
		const bar = barRef.current;
		gsap.set(bar, {xPercent: -100, visibility:"hidden"});

		return () => {
			gsap.killTweensOf(bar);
		}
	},[])

	useEffect(() => {
		const bar = barRef.current;
		if(inProgress === true){
			gsap.set(bar, {visibility:"visible"});
			gsap.fromTo(bar, {xPercent: -100}, {xPercent: 0, duration: 100});
		}
		else{
			gsap.killTweensOf(bar)
			gsap.to(bar, {xPercent: 0, duration: 0.3, ease: "power2.in", onComplete: () => gsap.set(bar, {visibility:"hidden"})});
		}
	},[inProgress])

	return (
		<Container inProgress={inProgress}>
			<Bar ref={barRef} />
		</Container>
	);
}

export default Processing
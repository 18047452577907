import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import { addRemoveIdeaFromPrioritised } from "../../../actions/project/project.actions";

import { WHITE } from "../../../theme/theme";

import Heading from "../../../components/typography/heading";
import Button from "../../../components/form/button";
import IconButton from "../../../components/form/icon-button";

import List from "../../../components/layout/list";
import Shortlist from "../../../components/data-display/shortlist";

import {
  Container,
  Wrapper,
  Content,
  Navigation,
  ButtonGroup,
  Menu,
  StyledModalMenu,
  HeaderContainer,
  Header,
  Scroll,
} from "./modal-content.styles";

const ModalContent = ({
  heading,
  menu,
  selected,
  navigation,
  onClose,
  onClick,
  project,
  idea,
  children,
}) => {
  const contentRef = useRef();
  const { id, data } = project;

  const handleClick = (event) => {
    if (contentRef && !contentRef.current.contains(event.target)) {
      if (onClose) {
        onClose();
      }
    }
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <ThemeProvider theme={WHITE}>
      <Container onClick={handleClick}>
        <Wrapper ref={contentRef}>
          <Content>
            <HeaderContainer>
              <Header>
                {heading && (
                  <>
                    <Heading size="medium" level={2} align="left">
                      {heading}
                    </Heading>
                    <Button
                      label="Prioritised"
                      variant={
                        data.prioritised?.includes(idea)
                          ? "primary"
                          : "tertiary"
                      }
                      iconStart="star"
                      wash
                      onClick={() => addRemoveIdeaFromPrioritised(id, idea)}
                    />
                    <Shortlist
                      projectId={id}
                      ideaId={idea}
                      projectData={data}
                    />
                  </>
                )}
              </Header>
            </HeaderContainer>

            <Scroll>{children}</Scroll>
          </Content>

          <div>
            <Navigation>
              <ButtonGroup>
                {menu && (
                  <Menu>
                    <StyledModalMenu items={menu} variant="primary" />
                  </Menu>
                )}
                <IconButton type="close" onClick={onClose} />
              </ButtonGroup>
              {Array.isArray(navigation) ? (
                navigation.map((item, index) => (
                  <List
                    key={`${item.label}${index}`}
                    heading={item.label}
                    data={item.list}
                    selected={selected}
                    onClick={(key) => onClick(item.label, key, item.id)}
                  />
                ))
              ) : (
                <List
                  heading={navigation.label}
                  data={navigation.list}
                  selected={selected}
                  onClick={(key) =>
                    onClick(navigation.label, key, navigation.id)
                  }
                />
              )}
            </Navigation>
          </div>
        </Wrapper>
      </Container>
    </ThemeProvider>
  );
};

ModalContent.propTypes = {
  heading: PropTypes.string,
  menu: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  navigation: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  project: PropTypes.object.isRequired,
  idea: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ModalContent.defaultProps = {
  heading: "heading",
  menu: undefined,
  selected: 0,
  onClose: undefined,
  onClick: undefined,
};

export default ModalContent;

import Color from "color";
import styled, { css } from "styled-components";
import {
  BEIGE_01,
  BLUE_01,
  BLUE_WASH,
  ORANGE_01,
  PINK_01,
  RED_01,
  WHITE_01,
} from "../../../theme/colors";
import { HEADER_HEIGHT, unit } from "../../../theme/metrics";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  width: 100%;
  height: ${({ isTable }) =>
    isTable ? `calc(100vh - ${unit(HEADER_HEIGHT)})` : "100%"};
  padding: ${({ isTable }) =>
    isTable && `${unit(7)} ${unit(20)} ${unit(13)} ${unit(20)}`};
`;

const getColor = (color) => css`
  ${() => color === "white" && `background-color: ${WHITE_01}`}
  ${() => color === "blue" && `background-color: ${BLUE_01}`};
  ${() => color === "pink" && `background-color: ${PINK_01}`};
  ${() => color === "beige" && `background-color: ${BEIGE_01}`};
  ${() => color === "orange" && `background-color: ${ORANGE_01}`};
  ${() => color === "red" && `background-color: ${RED_01}`};
`;

export const Option = styled.div`
  padding: ${unit(1)};
  border-radius: ${unit(0.5)};
  ${({ color }) =>
    color ? getColor(color) : `background-color: ${Color(BLUE_WASH)}}`};
  max-width: 67px;
`;

export const ControlsContainer = styled.div`
  position: absolute;
  top: ${unit(2)};
  left: ${unit(2)};
`;

import React, { useRef, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { useTranslation } from "react-i18next";
import Bugsnag from '@bugsnag/js'

import { BLACK } from '../../theme/theme'

import { Container, Content, Inner, Dismiss } from './callout.styles.js'
import { useCallouts } from './callout.provider'

import Text from '../typography/text'

const Callout = ({
	id,
	type = "info",
	message,
	description,
	error
}) => {
	const { t } = useTranslation();
	const { removeCallout } = useCallouts();

	const contentRef = useRef();

	const handleClick = (event) => {
		if(id){
			removeCallout(id)
		}
	}

	useEffect(() => {if(error){Bugsnag.notify(error)}},[error])



	return (
		<ThemeProvider theme={BLACK}>
			<Container onClick={handleClick} type={type}>
				<Content ref={contentRef} type={type}>
					<Inner>
						<Text variant="primary" size="medium">{message}</Text>
						{description && <Text variant="secondary" size="small">{description}</Text>}
						{type==="error" && error && <Text variant="secondary" size="small">{t("callout.error",{replace:error})}</Text>}
					</Inner>
					<Dismiss onClick={handleClick}>
						<Text variant="secondary" size="medium">{t("callout.button")}</Text>
					</Dismiss>
				</Content>
			</Container>
		</ThemeProvider>
	);
}

export default Callout
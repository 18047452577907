import styled from "styled-components";

import { unit, pixelate } from "../../../theme/metrics";
import {
  WHITE_01,
  ORANGE_01,
  GREY_01,
  GREEN_01,
  RED_01,
  PINK_01,
  BLUE_01,
  BEIGE_01,
  OFF_WHITE,
} from "../../../theme/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${(props) =>
    props.gridLines
      ? `
			background-repeat: 		repeat-x,
									repeat-x,
									repeat-y,
									repeat-y,
									
									repeat-x,
									repeat-y;

			background-size: 		100% 20%,
									100% 20%,
									10% 100%,
									10% 100%,

									calc(100% / 12) 100%,
									100% calc(100% / 12);
			
			background-image: 		linear-gradient(to bottom, ${OFF_WHITE}, transparent),
									linear-gradient(to top, ${OFF_WHITE}, transparent),
									linear-gradient(to right, ${OFF_WHITE}, transparent),
									linear-gradient(to left, ${OFF_WHITE}, transparent),

									linear-gradient(to right, ${GREY_01} 1px, transparent 1px, transparent),
									linear-gradient(to bottom, ${GREY_01} 1px, transparent 1px, transparent);
			
			background-position: 	top left,
									bottom left,
									top left,
									top right,

									top left,
									top left;
		`
      : ``}
`;

export const Tooltip = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(
    ${(props) => (props.x ? pixelate(props.x) : "50%")},
    ${(props) => (props.y ? pixelate(props.y) : "50%")},
    0
  );
`;

export const TooltipInner = styled.div`
  position: absolute;
  padding: ${unit(3)};
  background-color: ${WHITE_01};
  transform: translate3d(50px, -50%, 0);
  width: ${unit(45)};
  border-radius: ${unit(1)};
`;

export const ControlsContainer = styled.div`
  position: absolute;
  top: ${unit(2)};
  right: ${unit(2)};
  border-radius: ${unit(1)};
  overflow: hidden;
  background-color: ${WHITE_01};
  display: flex;
  border: 1px solid ${GREY_01};
`;

export const ControlsOption = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  height: ${unit(5)};
  padding: 0 ${unit(2)};
  background-color: ${(props) =>
    props.selected
      ? props.color === "green"
        ? GREEN_01
        : props.color === "red"
        ? RED_01
        : props.color === "orange"
        ? ORANGE_01
        : props.color === "pink"
        ? PINK_01
        : props.color === "blue"
        ? BLUE_01
        : props.color === "beige"
        ? BEIGE_01
        : GREY_01
      : "transparent"};
  &:focus {
    outline: none;
  }
`;

export const ScoreLabel = styled.div`
  position: absolute;
  bottom: ${unit(3)};
  left: 50%;
  transform: translateX(-50%);
`;

export const ConfidenceLabel = styled.div`
  position: absolute;
  left: ${unit(3)};
  top: 50%;
  transform-origin: 0% 0%;
  transform: rotate(-90deg) translateX(-50%);
`;

import React from "react";

const Logo = () => (
  <svg
    width="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1446 0C14.5696 0 13.2964 1.27317 13.2964 2.86136C13.2964 4.43642 14.5696 5.72272 16.1446 5.72272C17.7197 5.72272 18.9929 4.44955 18.9929 2.86136C18.9929 1.27317 17.7197 0 16.1446 0Z"
        fill="#FC3718"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1446 26.2904C14.5696 26.2904 13.2964 27.5636 13.2964 29.1518C13.2964 30.7268 14.5696 32.0131 16.1446 32.0131C17.7197 32.0131 18.9929 30.74 18.9929 29.1518C18.9929 27.5636 17.7197 26.2904 16.1446 26.2904Z"
        fill="#FC3718"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66448 13.7162C1.19442 13.7162 0 14.9106 0 16.3806C0 17.8507 1.19442 19.0451 2.66448 19.0451C4.13454 19.0451 5.32896 17.8507 5.32896 16.3806C5.31583 14.9106 4.13454 13.7162 2.66448 13.7162Z"
        fill="#FC3718"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4184 7.23215C20.9483 7.23215 19.7539 8.42657 19.7539 9.89663C19.7539 11.3667 20.9483 12.5611 22.4184 12.5611C23.8884 12.5611 25.0829 11.3667 25.0829 9.89663C25.0829 8.42657 23.8884 7.23215 22.4184 7.23215Z"
        fill="#FC3718"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9609 13.7162C14.4908 13.7162 13.2964 14.9106 13.2964 16.3806C13.2964 17.8507 14.4908 19.0451 15.9609 19.0451C17.4309 19.0451 18.6253 17.8507 18.6253 16.3806C18.6122 14.9106 17.4309 13.7162 15.9609 13.7162Z"
        fill="#FC3718"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.30587 21.7096C8.78086 21.7096 8.36084 22.1296 8.36084 22.6678C8.36084 23.1928 8.78086 23.6259 9.30587 23.6259C9.83089 23.6259 10.2509 23.2059 10.2509 22.6678C10.264 22.1427 9.83089 21.7096 9.30587 21.7096Z"
        fill="#FC3718"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2568 13.7162C27.7867 13.7162 26.5923 14.9106 26.5923 16.3806C26.5923 17.8507 27.7867 19.0451 29.2568 19.0451C30.7268 19.0451 31.9212 17.8507 31.9212 16.3806C31.9212 14.9106 30.7268 13.7162 29.2568 13.7162Z"
        fill="#FC3718"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45752 9.90975C6.45752 11.3798 7.65194 12.5742 9.122 12.5742C10.5921 12.5742 11.7865 11.3798 11.7865 9.90975C11.7865 8.43969 10.5921 7.24527 9.122 7.24527C7.65194 7.24527 6.45752 8.43969 6.45752 9.90975Z"
        fill="#FC3718"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6572 22.6678C21.6572 23.1928 22.0772 23.6128 22.6154 23.6128C23.1404 23.6128 23.5736 23.1928 23.5736 22.6678C23.5736 22.1427 23.1535 21.7227 22.6154 21.7227C22.0772 21.7227 21.6572 22.1427 21.6572 22.6678Z"
        fill="#FC3718"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="31.9212" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;

import styled from "styled-components";
import Color from "color";

import { dropShadow } from "../../../theme/general";
import { unit } from "../../../theme/metrics";
import {
  GREEN_01,
  GREEN_02,
  RED_01,
  ORANGE_01,
  DARK_GREY_01,
  BLUE_01,
  PINK_01,
  BEIGE_01,
  WHITE_01,
  BLUE_WASH,
} from "../../../theme/colors";

export const Btn = styled.button`
	width: ${(props) =>
    props.size === "xlarge"
      ? unit(10)
      : props.size === "large"
      ? unit(5)
      : props.size === "small"
      ? unit(3)
      : unit(4)};
	height: ${(props) =>
    props.size === "xlarge"
      ? unit(10)
      : props.size === "large"
      ? unit(5)
      : props.size === "small"
      ? unit(3)
      : unit(4)};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: ${(props) =>
    !props.onClick || props.disabled ? `default` : `pointer`};
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
	transition: all 0.3s;
	background-color: ${(props) =>
    props.variant === "tertiary"
      ? props.color === "white"
        ? props.selected
          ? WHITE_01
          : props.over
          ? Color(WHITE_01).alpha(0.15)
          : "transparent"
        : props.color === "green"
        ? props.selected
          ? GREEN_02
          : props.over
          ? Color(GREEN_02).alpha(0.15)
          : "transparent"
        : props.color === "red"
        ? props.selected
          ? RED_01
          : props.over
          ? Color(RED_01).alpha(0.15)
          : "transparent"
        : props.color === "orange"
        ? props.selected
          ? ORANGE_01
          : props.over
          ? Color(ORANGE_01).alpha(0.15)
          : "transparent"
        : props.color === "pink"
        ? props.selected
          ? PINK_01
          : props.over
          ? Color(PINK_01).alpha(0.15)
          : "transparent"
        : props.color === "blue"
        ? props.selected
          ? BLUE_01
          : props.over
          ? Color(BLUE_01).alpha(0.15)
          : "transparent"
        : props.color === "beige"
        ? props.selected
          ? BEIGE_01
          : props.over
          ? Color(BEIGE_01).alpha(0.15)
          : "transparent"
        : props.selected
        ? DARK_GREY_01
        : props.over
        ? Color(DARK_GREY_01).alpha(0.15)
        : "transparent"
      : props.variant === "secondary"
      ? props.color === "white"
        ? props.selected
          ? props.theme.foreground.primary
          : props.over
          ? Color(WHITE_01).alpha(0.3)
          : Color(WHITE_01).alpha(0.3)
        : props.color === "green"
        ? props.selected
          ? props.theme.foreground.primary
          : props.over
          ? Color(GREEN_02).alpha(0.3)
          : Color(GREEN_01).alpha(0.3)
        : props.color === "red"
        ? props.selected
          ? props.theme.foreground.primary
          : props.over
          ? Color(RED_01).alpha(0.3)
          : Color(RED_01).alpha(0.3)
        : props.color === "orange"
        ? props.selected
          ? props.theme.foreground.primary
          : props.over
          ? Color(ORANGE_01).alpha(0.3)
          : Color(ORANGE_01).alpha(0.3)
        : props.color === "pink"
        ? props.selected
          ? props.theme.foreground.primary
          : props.over
          ? Color(PINK_01).alpha(0.3)
          : Color(PINK_01).alpha(0.3)
        : props.color === "blue"
        ? props.selected
          ? props.theme.foreground.primary
          : props.over
          ? Color(BLUE_01).alpha(0.3)
          : Color(BLUE_01).alpha(0.3)
        : props.color === "beige"
        ? props.selected
          ? props.theme.foreground.primary
          : props.over
          ? Color(BEIGE_01).alpha(0.3)
          : Color(BEIGE_01).alpha(0.3)
        : props.selected
        ? props.theme.foreground.primary
        : props.over
        ? Color(BLUE_WASH).alpha(0.7)
        : BLUE_WASH
      : props.color === "white"
      ? props.selected
        ? props.theme.foreground.primary
        : props.over
        ? Color(WHITE_01).alpha(0.7)
        : WHITE_01
      : props.color === "green"
      ? props.selected
        ? props.theme.foreground.primary
        : props.over
        ? Color(GREEN_02).alpha(0.7)
        : GREEN_01
      : props.color === "red"
      ? props.selected
        ? props.theme.foreground.primary
        : props.over
        ? Color(RED_01).alpha(0.7)
        : RED_01
      : props.color === "orange"
      ? props.selected
        ? props.theme.foreground.primary
        : props.over
        ? Color(ORANGE_01).alpha(0.7)
        : ORANGE_01
      : props.color === "pink"
      ? props.selected
        ? props.theme.foreground.primary
        : props.over
        ? Color(PINK_01).alpha(0.7)
        : PINK_01
      : props.color === "blue"
      ? props.selected
        ? props.theme.foreground.primary
        : props.over
        ? Color(BLUE_01).alpha(0.7)
        : BLUE_01
      : props.color === "beige"
      ? props.selected
        ? props.theme.foreground.primary
        : props.over
        ? Color(BEIGE_01).alpha(0.7)
        : BEIGE_01
      : props.selected
      ? props.theme.foreground.primary
      : props.over
      ? Color(DARK_GREY_01).alpha(0.7)
      : DARK_GREY_01};
	
	border-radius: 50%;
	border: 0;

	${(props) =>
    props.left || props.right || props.top || props.bottom
      ? `position:fixed;`
      : ``}
	${(props) => (props.left ? `left: ${unit(props.left)};` : ``)}
	${(props) => (props.right ? `right: ${unit(props.right)};` : ``)}
	${(props) => (props.bottom ? `bottom: ${unit(props.bottom)};` : ``)}
	${(props) => (props.top ? `top: ${unit(props.top)};` : ``)}
	${(props) => (props.shadow ? dropShadow : ``)}

	&:focus {
		outline:none;
	}
`;

export const Char = styled.span`
  color: ${(props) => props.color};
`;

import React, {useEffect} from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import { ErrorBoundary } from '../services/bugsnag'
import { pageView } from '../services/analytics'
import Router from '../routing/router'
import Layout from './layout'
import PageError from '../components/page-error'
import {GlobalStyle} from '../theme/global'

import { ProcessingProvider, useProcessing } from '../components/processing'

const ErrorScreen = () => {
	const { t } = useTranslation();
	return <PageError heading={t(`error.general.heading`)} description={t(`error.general.description`)} />
}

const usePageViews = () => {
	const location = useLocation();
	useEffect(() => {
		const page = location.pathname + location.search
		pageView(page)
	}, [location]);
}

const useClearProcessingQueue = () => {
	const { clearQueue } = useProcessing();
	const location = useLocation();
	useEffect(() => {
		clearQueue()
	}, [location,clearQueue]);
}

const AppContent = () => {
	usePageViews();
	useClearProcessingQueue();

	return (
		<ErrorBoundary FallbackComponent={ErrorScreen}>
			<Layout>
				<Router />
			</Layout>
		</ErrorBoundary>
	)
}

const App = () => {
	return (
		<BrowserRouter>
			<ProcessingProvider>
				<GlobalStyle />
				<AppContent />
			</ProcessingProvider>
		</BrowserRouter>
	)
}

export default App
import styled from "styled-components";
import { unit } from "../../../theme/metrics";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  height: 100%;
  padding: ${() => `${unit(3)} ${unit(20)} ${unit(3)} ${unit(20)}`};
`;

import React, { useState, Fragment } from "react";

import Dropdown from "../../../components/form/dropdown";
import Checkbox from "../../../components/form/checkbox";

const Options = ({ groups, selected, onChange }) => {
  const [open, setOpen] = useState(false);
  return (
    <Dropdown
      label="Criteria"
      active={selected.length > 0}
      open={open}
      onClick={() => setOpen(!open)}
    >
      {groups
        .filter((group) => group.type !== "comments" && group.type !== "notes")
        .map((group) => (
          <Option
            key={group.id}
            {...group}
            selected={selected}
            onChange={onChange}
          />
        ))}
    </Dropdown>
  );
};

const Option = ({ id, label, parameters, selected, onChange }) => {
  const allSelected = parameters.every((parameter) =>
    selected.includes(`${id}.${parameter.id}`)
  );

  return (
    <Fragment>
      <Checkbox
        bold={true}
        label={`All ${label}`}
        variant="switch"
        checked={allSelected}
        onChange={(event) => {
          onChange({
            options: parameters.map((parameter) => ({
              groupId: id,
              parameterId: parameter.id,
              value: !allSelected,
            })),
          });
        }}
      />
      {parameters.map((parameter, index) => {
        const checked = selected.includes(`${id}.${parameter.id}`);
        return (
          <Checkbox
            label={parameter.label}
            checked={checked}
            key={parameter.id}
            borderBottom={index === parameters.length - 1}
            onChange={(event) => {
              onChange({
                options: [
                  { groupId: id, parameterId: parameter.id, value: !checked },
                ],
              });
            }}
          />
        );
      })}
    </Fragment>
  );
};

export default Options;

import styled from 'styled-components'

import { typeText2Regular, typeText2Bold } from '../../../theme/typography'
import { unit } from '../../../theme/metrics'

import { GREEN_02, GREEN_WASH } from '../../../theme/colors'

export const Container = styled.div`
	
`

export const Items = styled.ul`
	display: flex;
	list-style-type: none;
	padding: 0;
	margin: ${unit(-0.5)};
	flex-direction: ${props => props.direction === "vertical" ? "column" : "row"};
`

export const Item = styled.li`
	flex: 1;
	padding: ${unit(0.5)};
	display: flex;
`

export const Button = styled.button`
	${props => props.selected ? typeText2Bold : typeText2Regular}
	cursor: pointer;
	flex: 1;
	border-radius: 4px;
	border: ${props => props.selected ? `2px solid ${GREEN_02}` : `1px solid ${props.theme.keyline}`};
	color: ${props => props.selected ? GREEN_02 : props.theme.foreground.secondary};
	height: ${unit(5)};
	line-height: ${unit(4)};
	background-color: ${props => props.selected ? GREEN_WASH : `transparent`};
	outline: none;

	&:hover{
		border: ${props => props.selected ? `2px solid ${GREEN_02}` : `1px solid ${GREEN_02}`};
	}
`
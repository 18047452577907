import firebase from 'firebase/app'
import { useDocument as useDocumentHook, useDocumentOnce as useDocumentOnceHook } from 'react-firebase-hooks/firestore';
import { useDownloadURL as useDownloadURLHook } from 'react-firebase-hooks/storage';

import { prepareDataForCreate, prepareDataForUpdate } from '../helpers/firestore'

export const FIELDS = ['firstName','lastName','organisation','avatar'];
export const COLLECTION_NAME = 'profiles';

export const exists = () => {
	return new Promise((resolve, reject) => {
		getDocumentRef().get()
		.then((snapshot) => {
			resolve(snapshot.exists);
		})
		.catch((error) => {
			reject(error);
		});
	})
}

export const create = (data) => {
	return createDocument(data)
}

export const update = (data) => {
	return updateDocument(data)
}

export const uploadAvatar = (file) => {
	return getAvatarRef(file.name).put(file,{contentType:file.type})
}

const createData = (data) => {
	return prepareDataForCreate({
		...data,
	},FIELDS)
}

const updateData = (data) => {
	return prepareDataForUpdate(data,FIELDS)
}

export const newDocumentRef = (userId) => {
	userId = userId || firebase.auth().currentUser.uid;
	return firebase.firestore().collection(COLLECTION_NAME).doc(userId);
}

export const getDocumentRef = (userId) => {
	userId = userId || firebase.auth().currentUser.uid;
	return firebase.firestore().collection(COLLECTION_NAME).doc(userId);
}

export const getDocument = (userId) => {
	userId = userId || firebase.auth().currentUser.uid;
	return getDocumentRef(userId).get()
}

export const getAvatarRef = (filename,userId) => {
	userId = userId || firebase.auth().currentUser.uid;
	return firebase.storage().ref().child('avatar').child(userId).child(filename)
}

export const getAvatarURL = (filename,userId) => {
	userId = userId || firebase.auth().currentUser.uid;
	return getAvatarRef(filename,userId).getDownloadURL()
}

export const useAvatarURL = () => {
	return useDownloadURLHook(getAvatarRef());
}

export const useDocument = (userId) => {
	userId = userId || firebase.auth().currentUser.uid;
	return useDocumentHook(getDocumentRef(userId),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useDocumentOnce = (userId) => {
	userId = userId || firebase.auth().currentUser.uid;
	return useDocumentOnceHook(getDocumentRef(userId));
}

export const createDocument = (data,batch) => {
	if(batch){
		return batch.set(newDocumentRef(),createData(data));
	}
	return newDocumentRef().set(createData(data));
}

export const updateDocument = (data,batch) => {
	if(batch){
		return batch.set(getDocumentRef(),updateData(data),{merge:true});
	}
	return getDocumentRef().set(updateData(data),{merge:true});
}


import React from 'react'
import { ThemeProvider } from 'styled-components'

import { WHITE } from '../../../theme/theme'

import { Container, Content, Inner, Header } from './page.styles.js'

import Heading from '../../typography/heading'
import Text from '../../typography/text';

const Page = ({
	heading,
	description,
	children,
	onClose,
	size="medium",
	center=true
}) => {
	return (
		<ThemeProvider theme={WHITE}>
			<Container center={center}>
				<Inner>
					<Content size={size} center={center}>
						{(heading || description) && <Header>
							{heading && <Heading variant="primary" size="large" level={1} align="center">{heading}</Heading>}
							{description && <Text size="large" spaceBefore={2} variant="tertiary" align="center">{description}</Text>}
						</Header>}
						{children}
					</Content>
				</Inner>
			</Container>
		</ThemeProvider>
	);
}

export default Page
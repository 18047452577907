import styled from "styled-components";

import {
  typeHeading1,
  typeHeading2,
  typeHeading3,
} from "../../../theme/typography";
import { unit } from "../../../theme/metrics";

import {
  GREEN_01,
  RED_01,
  BLACK_01,
  GREY_04,
  WHITE_01,
  DARK_GREY_01,
  GREY_00,
} from "../../../theme/colors";

export const Content = styled.span`
	display: block;
	flex: 1 1 auto;
	${(props) =>
    props.size === "small"
      ? typeHeading3
      : props.size === "large"
      ? typeHeading1
      : typeHeading2}
	color: ${(props) =>
    props.color === "black"
      ? BLACK_01
      : props.color === "green"
      ? GREEN_01
      : props.color === "grey"
      ? GREY_04
      : props.color === "dark-grey"
      ? DARK_GREY_01
      : props.color === "grey-0"
      ? GREY_00
      : props.color === "red"
      ? RED_01
      : props.color === "white"
      ? WHITE_01
      : props.variant === "tertiary"
      ? props.theme.foreground.tertiary
      : props.variant === "secondary"
      ? props.theme.foreground.secondary
      : props.theme.foreground.primary};
	${(props) => (props.align ? `text-align: ${props.align};` : ``)}
	margin-top: ${(props) => (props.spaceBefore ? unit(props.spaceBefore) : `0`)};
	margin-bottom: ${(props) => (props.spaceAfter ? unit(props.spaceAfter) : `0`)};
`;

import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { Content } from "./label.styles.js";

const Label = forwardRef(
  ({ children, align, color, spaceBefore, spaceAfter, variant }, ref) => {
    return (
      <Content
        variant={variant}
        color={color}
        align={align}
        spaceAfter={spaceAfter}
        spaceBefore={spaceBefore}
      >
        {children}
      </Content>
    );
  }
);

Label.displayName = "Label";

Label.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(["left", "center", "right"]),
  color: PropTypes.oneOf(["black", "grey", "grey-2", "grey-0", "green", "red"]),
  spaceBefore: PropTypes.number,
  spaceAfter: PropTypes.number,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
};

Label.defaultProps = {
  children: undefined,
  align: "left",
  color: undefined,
  spaceBefore: 0,
  spaceAfter: 0,
  variant: "primary",
};

export default Label;

import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth';

const getURL = () => `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ``}`;

export const sendLink = (email,redirect) => {
	return new Promise((resolve, reject) => {
		firebase.auth().sendSignInLinkToEmail(email, {
			url: `${getURL()}/login/verify${redirect ? `?redirect=${redirect}` : ``}`,
			handleCodeInApp: true,
		})
		.then(() => {
			window.localStorage.setItem('emailForSignIn', email);
			resolve();
		})
		.catch((error) => {
			reject(error);
		});
	})
}

export const signOut = () => {
	return new Promise((resolve, reject) => {
		firebase.auth().signOut()
		.then((result) => {
			resolve(result);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

export const isValidVerifyEmail = () => window.localStorage.getItem('emailForSignIn') != null;

export const isValidVerifyLink = () => firebase.auth().isSignInWithEmailLink(window.location.href);

export const isValidReauthenticationEmail = () => firebase.auth().currentUser.email === window.localStorage.getItem('emailForSignIn');

export const isValidReauthenticationLink = () => firebase.auth().isSignInWithEmailLink(window.location.href);

export const verifySignInWithoutEmail = () => {
	return new Promise((resolve, reject) => {
		var email = window.localStorage.getItem('emailForSignIn');
		firebase.auth().signInWithEmailLink(email, window.location.href)
		.then((result) => {
			window.localStorage.removeItem('emailForSignIn');
			resolve(result);
		})
		.catch((error) => {
			reject(error);
		});
	})
}

export const verifySignInWithEmail = (email) => {
	return new Promise((resolve, reject) => {
		firebase.auth().signInWithEmailLink(email, window.location.href)
		.then((result) => {
			resolve(result);
		})
		.catch((error) => {
			reject(error);
		});
	})
}

export const updateEmail = (email) => {
	const user = firebase.auth().currentUser;
	return user.updateEmail(email)
	// return user.verifyBeforeUpdateEmail(email, {
	// 	url: `${getURL()}/login`,
	// 	handleCodeInApp: true,
	// })
}

export const useCurrent = () => {
	return useAuthState(firebase.auth());
}

export const getCurrentId = () => {
	if(firebase.auth().currentUser){
		return firebase.auth().currentUser.uid;
	}
	return null;
}

export const getCurrentEmail = () => {
	return firebase.auth().currentUser.email;
}
import firebase from 'firebase/app'
import { useCollection as useCollectionHook, useCollectionOnce as useCollectionOnceHook } from 'react-firebase-hooks/firestore';

import { prepareDataForCreate, prepareDataForUpdate } from '../helpers/firestore'
import { writePermissions, readPermissions } from '../helpers/permissions'

import project from '../project'

import projectData from 'shared/data/project'

export const FIELDS = ['email','admin','read','write','state','project','user','inviter'];
export const COLLECTION_NAME = 'invitations';

export const STATE = {
	PENDING:"pending",
	ACCEPTED:"accepted",
	REJECTED:"rejected",
	UNINVITED:"uninvited"
}

const createData = (data) => {
	return prepareDataForCreate({
		...data,
		state:STATE.PENDING,
	},FIELDS)
}

const updateData = (data) => {
	return prepareDataForUpdate(data,FIELDS)
}

export const blank = (projectId) => {
	let data = {
		email:'',
		admin:false,
	}

	return new Promise((resolve, reject) => {
		if(projectId){
			project.getDocument(projectId)
			.then((doc) => {
				if(doc.exists){
					const d = doc.data();
					data.read = readPermissions(d.structure,true);
					data.write = writePermissions(d.structure,false);
				}
				else{
					data.read = readPermissions(projectData,true);
					data.write = writePermissions(projectData,false);
				}
				resolve(data);
			})
			.catch((error) => {
				resolve(data);
			})
		}
		else{
			resolve({
				...data,
				read: readPermissions(projectData,true),
				write: writePermissions(projectData,false),
			})
		}
	})
	
}

export const newDocumentRef = () => {
	return firebase.firestore().collection(COLLECTION_NAME).doc();
}

export const getDocumentRef = (id) => {
	return firebase.firestore().collection(COLLECTION_NAME).doc(id);
}

export const getCollectionRef = (projectId,state) => {
	if(state){
		return firebase.firestore().collection(COLLECTION_NAME).where("project", "==", projectId).where("state", "==", state)
	}

	return firebase.firestore().collection(COLLECTION_NAME).where("project", "==", projectId)
}

export const useCollection = (projectId,state) => {
	return useCollectionHook(getCollectionRef(projectId,state),{snapshotListenOptions: { includeMetadataChanges: true }});
}

export const useCollectionOnce = (projectId,state) => {
	return useCollectionOnceHook(getCollectionRef(projectId,state));
}

export const create = (projectId,data) => {
	const userId = firebase.auth().currentUser.uid;
	return new Promise((resolve, reject) => {
		// get invitations and members first and verify not already a user or invited
		createDocument({
			project: projectId,
			inviter: userId,
			state: STATE.PENDING,
			...data,
		})
		.then(() => {
			resolve({success:true});
		})
		.catch(error => reject(error))
	})
}

export const update = (invitationId, invitationData) => {
	return updateDocument(invitationId, invitationData);
}

export const accept = (invitationId) => {
	return updateDocument(invitationId,{
		state: STATE.ACCEPTED,
		user: firebase.auth().currentUser.uid
	})
}

export const uninvite = (invitationId) => {
	return updateDocument(invitationId,{
		state: STATE.UNINVITED
	})
}

export const resend = (invitationId) => {
	const functions = window.location.hostname === 'localhost' && process.env.REACT_APP_EMULATOR === 'true' ? firebase.functions() : firebase.app().functions('europe-west1');
	return functions.httpsCallable('resendInvitation')({invitationId:invitationId})
}

export const getDocument = (invitationId) => {
	return getDocumentRef(invitationId).get()
}

export const createDocument = (data,batch) => {
	if(batch){
		return batch.set(newDocumentRef(),createData(data));
	}
	return newDocumentRef().set(createData(data));
}

export const updateDocument = (invitationId,data,batch) => {
	if(batch){
		return batch.set(getDocumentRef(invitationId),updateData(data),{merge:true});
	}
	return getDocumentRef(invitationId).set(updateData(data),{merge:true});
}
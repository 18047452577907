import styled from "styled-components";

import {
  typeText1Regular,
  typeText2Regular,
  typeText3Regular,
  typeText1Bold,
  typeText2Bold,
  typeText3Bold,
} from "../../../theme/typography";
import { unit } from "../../../theme/metrics";
import {
  GREEN_01,
  RED_01,
  WHITE_01,
  GREY_04,
  BLACK_01,
  NEW_GREY_02,
  GREY_00,
  NEW_GREY_01,
} from "../../../theme/colors";

export const Content = styled.div`
	padding: 0;
	margin: 0;
	user-select: none;
  white-space: pre-line;
	${(props) => (props.gap ? `gap: ${unit(props.gap)};` : ``)};
	${(props) =>
    props.size === "small"
      ? props.bold
        ? typeText1Bold
        : typeText1Regular
      : props.size === "large"
      ? props.bold
        ? typeText3Bold
        : typeText3Regular
      : props.bold
      ? typeText2Bold
      : typeText2Regular}
	display: ${(props) => props.display};
	color: ${(props) =>
    props.color === "black"
      ? BLACK_01
      : props.color === "green"
      ? GREEN_01
      : props.color === "grey"
      ? GREY_04
      : props.color === "grey-00"
      ? GREY_00
      : props.color === "grey-01"
      ? NEW_GREY_01
      : props.color === "grey-02"
      ? NEW_GREY_02
      : props.color === "red"
      ? RED_01
      : props.color === "white"
      ? WHITE_01
      : props.variant === "tertiary"
      ? props.theme.foreground.tertiary
      : props.variant === "secondary"
      ? props.theme.foreground.secondary
      : props.theme.foreground.primary};
	${(props) => (props.align ? `text-align: ${props.align};` : ``)}
	${(props) =>
    props.underline === "black"
      ? `border-bottom: 1px solid ${BLACK_01};`
      : props.underline === "white"
      ? `border-bottom: 1px solid ${WHITE_01};`
      : props.underline === "grey"
      ? `border-bottom: 1px solid ${GREY_04};`
      : props.underline === "green"
      ? `border-bottom: 1px solid ${GREEN_01};`
      : props.underline === "red"
      ? `border-bottom: 1px solid ${RED_01};`
      : ``}
	${(props) =>
    props.underline === "green" || props.underline === "red"
      ? `padding-bottom: ${unit(1)};`
      : ``}

	opacity: ${(props) => (props.opacity ? props.opacity : 1)};
	${(props) =>
    props.spaceBefore ? `margin-top: ${unit(props.spaceBefore)};` : ``}
	${(props) =>
    props.spaceAfter ? `margin-bottom: ${unit(props.spaceAfter)};` : ``}
  ${(props) =>
    props.ellipsis &&
    `white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; `}

	strong{
		${(props) =>
      props.size === "small"
        ? typeText1Bold
        : props.size === "large"
        ? typeText3Bold
        : typeText2Bold}
	}

	> ul{
		margin-bottom: ${unit(1)};

		> li{
			list-style-type: disc;
			margin-bottom: ${unit(1)};
    		margin-left: ${unit(2)};

			&:last-child{
				margin-bottom: 0;
			}
		}

		&:last-child{
			margin-bottom: 0;
		}
	}
`;

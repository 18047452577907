import styled from "styled-components";
import Color from "color";

import {
  typeText1Regular,
  typeText3Regular,
  typeText1Bold,
  typeText3Bold,
} from "../../../theme/typography";
import { unit, pixelate } from "../../../theme/metrics";
import { GREEN_02 } from "../../../theme/colors";

export const Container = styled.label`
	display: flex;
	user-select: none;
	position: relative;
	align-items: center;
	${(props) =>
    props.borderTop ? `border-top: 1px solid ${props.theme.keyline};` : ``}
	${(props) =>
    props.borderBottom
      ? `border-bottom: 1px solid ${props.theme.keyline};`
      : ``}
	padding: ${unit(1.5)} ${unit(2)};
	cursor: pointer;
	background-color: ${(props) =>
    props.over
      ? props.theme.background.secondary
      : props.theme.background.primary};
`;

export const Help = styled.span`
	${typeText1Regular}
	margin-top: ${unit(0.5)};
	margin-right: ${unit(4)};
	display: block;
	color: ${(props) => props.theme.foreground.secondary};
`;

export const Icon = styled.span`
  position: relative;
  width: ${(props) => (props.variant === "switch" ? unit(4) : unit(2))};
  flex: 0 0 ${(props) => (props.variant === "switch" ? unit(4) : unit(2))};
  height: ${unit(2)};
  border: ${(props) =>
    props.variant === "switch"
      ? `none`
      : props.checked
      ? `1px solid ${Color(GREEN_02).alpha(0.5)}`
      : `1px solid ${Color(props.theme.foreground.primary).alpha(0.3)}`};
  border-radius: ${unit(1)};
  background-color: ${(props) =>
    props.variant === "switch"
      ? props.checked
        ? Color(GREEN_02).alpha(0.5)
        : Color(props.theme.foreground.primary).alpha(0.3)
      : `transparent`};

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:after {
    content: "";
    position: absolute;
    left: ${(props) => (props.variant === "switch" ? `0px` : `50%`)};
    top: ${(props) => (props.variant === "switch" ? `0px` : `50%`)};
    width: ${(props) =>
      props.variant === "switch"
        ? unit(2)
        : pixelate(unit(2, { pixelate: false }) - 6)};
    height: ${(props) =>
      props.variant === "switch"
        ? unit(2)
        : pixelate(unit(2, { pixelate: false }) - 6)};
    border-radius: 50%;
    background-color: ${(props) =>
      props.variant === "switch" && !props.checked
        ? props.theme.foreground.primary
        : GREEN_02};
    opacity: ${(props) =>
      props.variant === "switch" || props.checked ? `1` : `0`};
    transform: translateX(
      ${(props) =>
        props.variant === "switch" && props.checked
          ? pixelate(unit(2, { pixelate: false }))
          : `0px`}
    );
    transform: translate(
      ${(props) => (props.variant !== "switch" ? `-50%, -50%` : ``)}
    );
  }
`;

export const Input = styled.input`
  position: absolute;
  visibility: hidden;
  cursor: pointer;
`;

export const Content = styled.div`
  ${({ inputOnly }) =>
    inputOnly &&
    `display: flex;
  position: relative;
  align-items: center;
  position: relative;`};
  ${({ inputOnly, size, bold }) =>
    !inputOnly && size === "large"
      ? bold
        ? typeText3Bold
        : typeText3Regular
      : bold
      ? typeText1Bold
      : typeText1Regular}
  flex-direction: column;
  color: ${(props) => props.theme.foreground.primary};
  flex: ${({ inputOnly }) => !inputOnly && `1 1 auto`};
  padding: ${({ inputOnly }) => !inputOnly && `0 ${unit(2)} 0 0`};
`;

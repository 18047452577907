export const writePermissions = (data,defaultValue) => {
	let result = {};
	data.forEach(group => (result[group.id] = group.write ? group.write : defaultValue))
	return result
}

export const readPermissions = (data,defaultValue) => {
	let result = {};
	data.forEach(group => (result[group.id] = group.read ? group.read : defaultValue))
	return result
}
import React from "react";

const ChevronIcon = ({ position, color }) => {
  const rotation = () => {
    switch (position) {
      case "up":
        return "180deg";
      case "left":
        return "90deg";
      case "right":
        return "270deg";
      case "down":
        return "0deg";
      default:
        return "0deg";
    }
  };

  return (
    <svg
      width="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${position ? rotation(position) : 0})`,
      }}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronIcon;

import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import { getProjectPath } from "../../routing/paths";

import { useCallouts } from "../../components/callout";
import { useProcessing } from "../../components/processing";

import IconButton from "../../components/form/icon-button";
import Page from "../../components/layout/page";
import DataTable from "../../components/data-display/data-table";
import Text from "../../components/typography/text";
import RelativeTime from "../../components/relative-time";

import PageLoading from "../../components/page-loading";
import PageError from "../../components/page-error";

import project from "../../actions/project";
import member from "../../actions/member";

import AddProject from "../add-project";
import EditProject from "../edit-project";

const Projects = ({
  projectsData,
  membersData,
  onEditProject,
  onArchiveProject,
  onDuplicateProject,
  onExportProject,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const columns = [
    { header: t("project.label.name") },
    { header: t("project.label.organisation"), width: 200 },
    { header: t("member.label.role"), width: 100 },
    { header: t("member.label.permissions"), width: 175 },
    { header: t("project.label.updated"), width: 150 },
  ];

  let rows = projectsData.map((projectData) => {
    const memberData = membersData.find((m) => m.projectId === projectData.id);
    const admin = get(memberData, "admin") === true;

    let menu = null;

    let access = admin ? t("project.label.admin") : t("project.label.member");
    let permissions = projectData.structure
      .filter((group) => memberData && memberData.write[group.id] === true)
      .map((group) => group.label)
      .join(", ");
    let updated = projectData.metadata.updatedTime
      ? projectData.metadata.updatedTime.toDate()
      : null;

    if (admin) {
      menu = [
        {
          label: t("project.action.settings"),
          icon: "edit",
          onClick: () => onEditProject(projectData.id),
        },
        {
          label: t("project.action.duplicate"),
          icon: "copy",
          onClick: () => onDuplicateProject(projectData.id),
        },
        {
          label: t("project.action.export"),
          icon: "export",
          onClick: () => onExportProject(projectData.id),
        },
        {
          label: t("project.action.archive"),
          icon: "bin",
          confirm: "Sure?",
          onClick: () => onArchiveProject(projectData.id),
        },
      ];
    }

    return {
      onClick: () =>
        history.push(
          `${getProjectPath(projectData.id)}/${t(
            "project.navigation.option.id"
          )}`
        ),
      cells: [
        <Text key="name" variant="primary" size="medium">
          {projectData.displayName}
          {projectData.scientificName ? ` (${projectData.scientificName})` : ``}
        </Text>,
        projectData.organisation,
        access,
        permissions.length > 0 ? permissions : "None",
        <Text key="updated" variant="tertiary" size="small">
          <RelativeTime time={updated} />
        </Text>,
      ],
      menu: menu,
    };
  });

  return <DataTable rows={rows} columns={columns} />;
};

const PageSuccess = ({ projectsData, membersData }) => {
  const { t } = useTranslation();
  const [addProjectVisible, setAddProjectVisible] = useState(false);
  const [editProject, setEditProject] = useState(null);
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const handleArchiveProject = (id) => {
    const pid = addToQueue();
    project
      .archive(id)
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("project.alert.archive.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("project.alert.archive.fail"),
          error: error,
        });
      });
  };

  const handleEditProject = (id) => {
    setEditProject(id);
  };

  const handleExportProject = (id) => {
    const pid = addToQueue();
    project
      .exportCSV(id)
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("project.alert.export.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("project.alert.export.fail"),
          error: error,
        });
      });
  };

  const handleDuplicateProject = (id) => {
    const pid = addToQueue();
    project
      .duplicate(id)
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("project.alert.duplicate.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("project.alert.duplicate.fail"),
          error: error,
        });
      });
  };

  return (
    <Fragment>
      <Page
        size="large"
        heading={t("project.heading.view")}
        center={projectsData.length > 0 ? false : true}
      >
        {projectsData.length > 0 ? (
          <Projects
            projectsData={projectsData}
            membersData={membersData}
            onArchiveProject={handleArchiveProject}
            onEditProject={handleEditProject}
            onDuplicateProject={handleDuplicateProject}
            onExportProject={handleExportProject}
          />
        ) : (
          <Text align="center">{t("project.help.noProjects")}</Text>
        )}
      </Page>

      <IconButton
        type="add"
        size="xlarge"
        color="orange"
        bottom={3}
        right={2}
        onClick={() => setAddProjectVisible(true)}
      />
      {addProjectVisible && (
        <AddProject
          onClose={() => setAddProjectVisible(false)}
          onComplete={() => setAddProjectVisible(false)}
        />
      )}
      {editProject && (
        <EditProject
          id={editProject}
          onClose={() => setEditProject(null)}
          onComplete={() => setEditProject(null)}
        />
      )}
    </Fragment>
  );
};

const Dashboard = () => {
  const { t } = useTranslation();
  const [projectsSnapshot, , projectsError] = project.useCollection();
  const [membersSnapshot, , membersError] = member.useCollectionGroup();

  const [projectsData, setProjectsData] = useState(null);
  const [membersData, setMembersData] = useState(null);

  const [pageError, setPageError] = useState(null);

  useEffect(() => {
    if (projectsError || membersError) {
      setPageError({ type: "general", error: projectsError || membersError });
    }
  }, [projectsError, membersError]);

  useEffect(() => {
    if (projectsSnapshot && projectsSnapshot.docs) {
      setProjectsData(
        projectsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    }
  }, [projectsSnapshot]);

  useEffect(() => {
    if (membersSnapshot && membersSnapshot.docs) {
      setMembersData(
        membersSnapshot.docs.map((doc) => ({
          id: doc.id,
          projectId: get(doc, "ref.parent.parent.id"),
          ...doc.data(),
        }))
      );
    }
  }, [membersSnapshot]);

  // ERROR
  if (pageError)
    return (
      <PageError
        heading={t(`error.${pageError.type}.heading`)}
        description={t(`error.${pageError.type}.description`)}
        error={pageError.error}
      />
    );

  // SUCCESS
  if (projectsData && membersData)
    return (
      <PageSuccess projectsData={projectsData} membersData={membersData} />
    );

  // LOADING
  return <PageLoading />;
};

export default Dashboard;

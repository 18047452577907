import styled from "styled-components";

import { unit } from "../../../theme/metrics";
import {
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  roundingTopLeft,
  roundingTopRight,
  roundingBottomRight,
  roundingBottomLeft,
} from "../../../theme/helpers";

import { GREEN_01, RED_01, PINK_WASH, BLUE_WASH } from "../../../theme/colors";

export const mainAxisAlignment = (align) => {
  switch (align) {
    case "start":
      return "justify-content: flex-start;";
    case "end":
      return "justify-content: flex-end;";
    case "center":
      return "justify-content: center;";
    case "evenly":
      return "justify-content: space-between;";
    case "around":
      return "justify-content: space-around;";
    default:
      return "";
  }
};

export const crossAxisAlignment = (align) => {
  switch (align) {
    case "start":
      return "align-items: flex-start;";
    case "end":
      return "align-items: flex-end;";
    case "center":
      return "align-items: center;";
    case "stretch":
      return "align-items: stretch;";
    default:
      return "";
  }
};

export const Container = styled.div`
	display: block;
  ${({ fitContainer }) => fitContainer && `width: 100%`};
  ${({ fullHeight }) => fullHeight && "height: 100%"};
	${(props) => (props.overflow ? `overflow: ${props.overflow};` : ``)}
	${(props) => (props.onClick ? `cursor: pointer;` : ``)}
	${(props) => (props.width ? `width: ${unit(props.width)};` : ``)}
	${(props) =>
    props.direction !== "vertical" && props.width
      ? `flex: 0 0 ${unit(props.width)};`
      : ``}
	${(props) =>
    props.direction === "vertical" && props.height
      ? `flex: 0 0 ${unit(props.height)};`
      : ``}
	${(props) => (props.height ? `height: ${unit(props.height)};` : ``)}
	${(props) =>
    props.flex === "grow"
      ? `flex: 1 1 auto;`
      : props.flex === "shrink"
      ? `flex: 0 0 auto;`
      : ``}
	${(props) => (props.flex === "shrink" ? `align-self: start;` : ``)}
	${(props) =>
    props.position &&
    (props.position === "relative" || props.position === "absolute")
      ? `position: ${props.position};`
      : ``}
	${(props) =>
    props.borderTop ? `border-top: 1px solid ${props.theme.keyline};` : ``}
	${(props) =>
    props.borderBottom
      ? `border-bottom: 1px solid ${props.theme.keyline};`
      : ``}
  border-color: ${({ borderColor }) =>
    borderColor === "green" ? GREEN_01 : ""};
	margin: ${(props) =>
    `${marginTop(props)} ${marginRight(props)} ${marginBottom(
      props
    )} ${marginLeft(props)}`};
	${(props) => (props.cursor ? `cursor: ${props.cursor};` : ``)}
	background-color: ${(props) =>
    props.color === "red"
      ? RED_01
      : props.color === "green"
      ? GREEN_01
      : props.color === "pink"
      ? PINK_WASH
      : props.color === "blue"
      ? BLUE_WASH
      : `transparent`};
	border-radius: ${(props) =>
    `${roundingTopLeft(props)} ${roundingTopRight(props)} ${roundingBottomRight(
      props
    )} ${roundingBottomLeft(props)}`};
	${(props) =>
    props.shadow
      ? "filter: drop-shadow(0px 26px 68px rgba(0, 0, 0, 0.07)) drop-shadow(0px 5.80744px 15.1887px rgba(0, 0, 0, 0.0417275)) drop-shadow(0px 1.72903px 4.52207px rgba(0, 0, 0, 0.0282725));"
      : ""};
  ${({ spaceBefore }) =>
    spaceBefore ? `margin-left: ${unit(spaceBefore)};` : ``};
	${({ spaceAfter }) => (spaceAfter ? `margin-right: ${unit(spaceAfter)};` : ``)};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
`;

export const Content = styled.div`
	display:flex;
	height: 100%;
  ${({ gap }) => (gap ? `gap: ${unit(gap)};` : ``)};
  	padding: ${(props) =>
      `${paddingTop(props)} ${paddingRight(props)} ${paddingBottom(
        props
      )} ${paddingLeft(props)}`};
	${(props) => (props.wrapItems ? `flex-wrap: wrap;` : ``)}
	${(props) => (props.direction === "vertical" ? `flex-direction: column;` : ``)}
	${(props) => mainAxisAlignment(props.mainAxisAlignment)}
	${(props) => crossAxisAlignment(props.crossAxisAlignment)}
	
`;

import styled from 'styled-components'

import { unit } from '../../../theme/metrics'
import { BLACK_01 } from '../../../theme/colors'

export const Container = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: calc(100% + ${unit(1)});
	border-radius: ${unit(1)};
	background-color: ${BLACK_01};
	padding: ${unit(1)};
`
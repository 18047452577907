import React from "react";
import {
  Route,
  Redirect
} from "react-router-dom";
import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from "react-i18next";

import profile from '../actions/profile'

import PageLoading from '../components/page-loading';
import PageError from '../components/page-error';

import { getOnboardingPath, getDashboardPath, getLoginPath } from './paths'

const User = ({children,location,profileRequired}) => {
	const [user, loading, error] = useAuthState(firebase.auth());
	const { t } = useTranslation();

	if (loading) return <PageLoading />
	if (error) return <PageError heading={t(`error.general.heading`)} description={t(`error.general.description`)} />
	if (user) return <Profile location={location} required={profileRequired}>{children}</Profile>;

	return (
		<Redirect
			to={{
				pathname: getLoginPath(),
				state: { from: location }
			}}
		/>
	)
}

const Profile = ({children, location, required}) => {
	const [doc, loading, error] = profile.useDocument();
	const { t } = useTranslation();

	if (loading) return <PageLoading />
	if (error) return <PageError heading={t(`error.general.heading`)} description={t(`error.general.description`)} />

	if(doc){
		if(doc.exists && required){
			return children
		}
		else if(!doc.exists && !required){
			return children
		}
	}
	
	return (
		<Redirect
			to={{
				pathname: required ? getOnboardingPath() : (location.state && location.state.from ? location.state.from.pathname : getDashboardPath()),
				state: { from: location }
			}}
		/>
	)
}

const PrivateRoute = ({ children, profileRequired, ...props }) => {
	return (
		<Route
			{...props}
			render={({ location }) => {
				return (
					<User location={location} profileRequired={profileRequired}>
						{children}
					</User>
				)
			}}
		/>
	);
}

export default PrivateRoute;
import styled from "styled-components";

import { WHITE_01, GREY_04 } from "../../theme/colors";
import { unit } from "../../theme/metrics";
import { typeText2Regular } from "../../theme/typography";

export const Container = styled.div`
  /* 4px - top loading bar 3px - navbar border 1px */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 4px);
  width: 100%;
`;

export const Content = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
`;

export const Footer = styled.ul`
  position: absolute;
  bottom: ${unit(3)};
  left: 50%;
  transform: translateX(-50%);
`;

export const FooterItem = styled.ul``;

export const FooterLink = styled.a`
  ${typeText2Regular}
  color: ${GREY_04};
`;

export const Settings = styled.div``;

export const ProfileContainer = styled.div`
  position: relative;
`;

export const ScreenSize = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${WHITE_01};
  display: none;
  @media screen and (max-width: 1023px) {
    display: block;
  }
`;

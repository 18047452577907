import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Icon from "../../feedback/icon";
import Box from "../../layout/box";
import Tabs from "../../layout/tabs";
import Avatar from "../../form/avatar";

import { Container, NavigationContainer, Wrapper } from "./navbar.styles";

const Navbar = ({ title, to, navigation, selected, onClick, profile }) => {
  return (
    <Container>
      <Wrapper>
        <Link to={to}>
          <Box paddingRight={2}>
            <Icon variant="logo" size={4} />
          </Box>
        </Link>
        {title}
      </Wrapper>
      <NavigationContainer>
        {navigation && (
          <Tabs navigation={navigation} selected={selected} onClick={onClick} />
        )}
        {profile ? profile : <Avatar />}
      </NavigationContainer>
    </Container>
  );
};

Navbar.displayName = "Navbar";

Navbar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  to: PropTypes.string,
  navigation: PropTypes.array,
  selected: PropTypes.string,
  onClick: PropTypes.func,
  profile: PropTypes.node,
};

export default Navbar;

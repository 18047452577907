import React from "react";
import PropTypes from "prop-types";

import Text from "../../typography/text";

import { Container, Dot } from "./status.styles";

const Status = ({ toggle }) => {
  return (
    <Container toggle={toggle}>
      <Dot toggle={toggle} />
      <Text size="small">{toggle ? "Open" : "Closed"}</Text>
    </Container>
  );
};

Status.propTypes = {
  toggle: PropTypes.bool,
};

Status.defaultProps = {
  toggle: true,
};

export default Status;

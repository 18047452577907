import React from "react";
import PropTypes from "prop-types";

import { Container, Content } from "./box.styles.js";

const filter = (doc, allowed) => {
  return Object.keys(doc)
    .filter((key) => allowed.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: doc[key],
      };
    }, {});
};

const Box = ({ children, ...props }) => {
  const contentProps = filter(props, [
    "fitContainer",
    "paddingTop",
    "paddingBottom",
    "paddingLeft",
    "paddingRight",
    "paddingVertical",
    "paddingHorizontal",
    "padding",
    "gap",
    "wrapItems",
    "direction",
    "mainAxisAlignment",
    "crossAxisAlignment",
    "shadow",
  ]);

  return (
    <Container {...props}>
      <Content {...contentProps}>{children}</Content>
    </Container>
  );
};

Box.propTypes = {
  fitContainer: PropTypes.bool,
  fullHeight: PropTypes.bool,
  overflow: PropTypes.oneOf(["scroll", "hidden", "auto", "clip", "visible"]),
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
  flex: PropTypes.oneOf(["grow", "shrink"]),
  position: PropTypes.oneOf(["relative", "absolute"]),
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderColor: PropTypes.oneOf(["green"]),
  margin: PropTypes.string,
  color: PropTypes.oneOf(["red", "green", "pink", "blue", "transparent"]),
  borderRadius: PropTypes.string,
  shadow: PropTypes.bool,
  gap: PropTypes.number,
  padding: PropTypes.number,
  wrapItems: PropTypes.bool,
  crossAxisAlignment: PropTypes.oneOf(["start", "end", "center", "stretch"]),
  mainAxisAlignment: PropTypes.oneOf([
    "start",
    "end",
    "center",
    "evenly",
    "around",
  ]),
  spaceBefore: PropTypes.number,
  spaceAfter: PropTypes.number,
  zIndex: PropTypes.number,
};

export default Box;

import { css } from 'styled-components';

export const typeLabel = css`
	font-family: 'InterSemibold';
	font-size: 11px;
	letter-spacing: 1px;
	line-height: 15px;
	text-transform: uppercase;
`;

export const typeHeading1 = css`
	font-family: 'SailecRegular';
	font-size: 48px;
	line-height: 60px;
	letter-spacing: -1.2px;
`;

export const typeHeading2 = css`
	font-family: 'InterSemibold';
	font-size: 20px;
	line-height: 24px;
`;

export const typeHeading3 = css`
	font-family: 'InterSemibold';
	font-size: 16px;
	line-height: 19px;
`;

const typeText4 = css`
	font-size: 24px;
	line-height: 1.4em;
`;

const typeText3 = css`
	font-size: 16px;
	line-height: 1.4em;
`;

const typeText2 = css`
	font-size: 14px;
	line-height: 1.214em;
`;

const typeText1 = css`
	font-size: 12px;
	line-height: 1.25em;
`;

const typeText0 = css`
	font-size: 10px;
	line-height: 1em;
`;

export const typeText4Regular = css`
	font-family: 'InterRegular';
	${typeText4}
`;

export const typeText3Regular = css`
	font-family: 'InterRegular';
	${typeText3}
`;

export const typeText2Regular = css`
	font-family: 'InterRegular';
	${typeText2}
`;

export const typeText1Regular = css`
	font-family: 'InterRegular';
	${typeText1}
`;

export const typeText0Regular = css`
	font-family: 'InterRegular';
	${typeText0}
`;

export const typeText3Bold = css`
	font-family: 'InterSemibold';
	${typeText3}
`;

export const typeText2Bold = css`
	font-family: 'InterSemibold';
	${typeText2}
`;

export const typeText1Bold = css`
	font-family: 'InterSemibold';
	${typeText1}
`;
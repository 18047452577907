import React, {useState} from 'react'
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useCallouts } from '../../components/callout'
import { useProcessing } from '../../components/processing'

import user from '../../actions/user'

import Modal from '../../components/feedback/modal'
import TextField from '../../components/form/text-field'
import Button from '../../components/form/button'
import Scaffold from '../../components/layout/scaffold'
import Divider from '../../components/layout/divider'

const ChangeEmail = ({onClose, onComplete}) => {
	const { t } = useTranslation();
	
	const { addCallout } = useCallouts();
	const { addToQueue, removeFromQueue } = useProcessing();
	const [ reauthenticate, setReauthenticate ] = useState(false);

	const formik = useFormik({
		initialValues: {
			newEmail: "",
			confirmEmail: "",
		},
		initialErrors: {
			newEmail: t("user.validation.newEmail.required"),
			confirmEmail: t("user.validation.confirmEmail.required")
		},
		validationSchema: yup.object({
			newEmail: yup.string()
				.required(t("user.validation.newEmail.required"))
				.email(t("user.validation.newEmail.valid")),
			confirmEmail: yup.string()
				.required(t("user.validation.confirmEmail.required"))
				.test('passwords-match', t("user.validation.confirmEmail.mismatch"), function(value){ return this.parent.newEmail === value}),
		}),
		onSubmit: (values) => {
			const pid = addToQueue();
			user.updateEmail(values.newEmail)
			.then((result) => {
				removeFromQueue(pid);
				addCallout({
					message:t("user.alert.changeEmail.success")
				})
				onComplete();
			})
			.catch((error) => {
				removeFromQueue(pid);
				if(error.code === "auth/requires-recent-login"){
					setReauthenticate(true);
				}
				else{
					addCallout({
						type: "error",
						message:t("user.alert.changeEmail.fail"),
						error:error
					})
				}
			})
		},
	});

	const handleReauthenticate = (event) => {
		const pid = addToQueue();
		user.sendLink(user.getCurrentEmail())
		.then((result) => {
			removeFromQueue(pid);
			addCallout({
				message:t("user.alert.reauthenticate.success"),
			})
			onComplete();
		})
		.catch((error) => {
			removeFromQueue(pid);
			addCallout({
				type: "error",
				message:t("user.alert.reauthenticate.fail"),
				error: error
			})
		})
	}
	

	if(reauthenticate){
		return (
			<Modal onClose={onClose} heading={t("user.heading.reauthenticate")} description={t("user.description.reauthenticate")}>
				<Scaffold>
					<Scaffold direction="horizontal" align="center">
						<Button variant="primary" onClick={handleReauthenticate} label={t("user.action.reauthenticate")} />
					</Scaffold>
				</Scaffold>
			</Modal>
		);
	}

	return (
		<Modal onClose={onClose} heading={t("user.heading.changeEmail")} description={t("user.description.changeEmail")}>
			<Scaffold>
				<Scaffold direction="vertical" spaceBetween={2} spaceAfter={1}>
					<TextField name="newEmail" value={formik.values.newEmail} placeholder={t("user.label.newEmail")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.newEmail && Boolean(formik.errors.newEmail)} errorMessage={formik.errors.newEmail} />
					<TextField name="confirmEmail" value={formik.values.confirmEmail} placeholder={t("user.label.confirmEmail")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.confirmEmail && Boolean(formik.errors.confirmEmail)} errorMessage={formik.errors.confirmEmail} />
				</Scaffold>
				<Divider spaceBefore={1} />
				<Scaffold direction="horizontal" align="end" spaceBetween={1} spaceBefore={3}>
					<Button variant="primary" onClick={formik.handleSubmit} label={t("user.action.updateEmail")} disabled={!formik.isValid} />
				</Scaffold>
			</Scaffold>
		</Modal>
	);
}

export default ChangeEmail

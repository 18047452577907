import Color from "color";
import styled from "styled-components";

import { unit } from "../../../theme/metrics";
import { GREY_01, BLUE_WASH } from "../../../theme/colors";

export const mainAxisAlignment = (align) => {
  switch (align) {
    case "start":
      return "justify-content: flex-start;";
    case "end":
      return "justify-content: flex-end;";
    case "center":
      return "justify-content: center;";
    case "evenly":
      return "justify-content: space-between;";
    case "around":
      return "justify-content: space-around;";
    default:
      return "justify-content: center;";
  }
};

export const crossAxisAlignment = (align) => {
  switch (align) {
    case "start":
      return "align-items: flex-start;";
    case "end":
      return "align-items: flex-end;";
    case "center":
      return "align-items: center;";
    case "stretch":
      return "align-items: stretch;";
    default:
      return "";
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-width: ${({ width }) => width && `${width}px`};
  ${(props) => mainAxisAlignment(props.mainAxisAlignment)}
  ${(props) => crossAxisAlignment(props.crossAxisAlignment)};
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${() => unit(1)};
  gap: ${() => unit(1)};
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${() => unit(1)};
  width: 100%;
  border-bottom: ${() => `1px solid ${Color(GREY_01)}`};
  height: 100%;
  min-height: ${({ height }) => (height ? `${height}px` : "40px")};
  padding: ${() => `${unit(1)} 0`};
  &:last-child {
    border-bottom: unset;
  }
`;

export const NestedRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${() => unit(1)};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: 100%;
  min-height: 40px;
`;

export const LabelCell = styled.div`
  display: flex;
  align-items: center;
  width: 144px;
  min-height: 31px;
  gap: ${() => unit(1.25)};
  cursor: ${({ click }) => (click ? "pointer" : "default")};
  word-break: break-word;
`;

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${() => unit(1)};
  ${({ align }) => mainAxisAlignment(align)};
  flex: ${({ width }) => !width && 1};
  width: ${({ width }) => width && `${width}px`};
  padding: ${() => `${unit(1)}`};
  cursor: ${({ click }) => (click ? "pointer" : "default")};
  word-break: ${({ width }) => (width ? "break-word" : "unset")}};
  border-radius: ${unit(0.5)};
  &:hover {
    background-color: ${({ hover, click }) =>
      hover && click && Color(BLUE_WASH)};
  }
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  min-height: ${({ height }) => (height ? `${height}px` : "31px")};
  white-space: pre-line;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${Color(GREY_01)};
  width: ${({ width }) => width && `calc(100% + ${unit(3)})`};
`;

export const DragCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 31px;
`;

import React, { useContext, createContext, useState, useCallback  } from 'react'
import uniqid from 'uniqid';

import Processing from './processing.component'

const Context = createContext()

const removeId = (queue, id) => {
	var i = 0;
	var result = [];
	while (i < queue.length) {
		if (queue[i] !== id) {
			result.push(id)
		}
		i++;
	}
	return result;
}

const addId = (queue, id) => {
	return [id, ...queue];
}

export const ProcessingProvider = ({ children }) => {
	const [queue, setQueue] = useState([])

	const addToQueue = useCallback(() => {
		const id = uniqid()
		setQueue((queue) => addId(queue,id))
		return id;
	},[])
	
	const removeFromQueue = useCallback((id) => {
		setQueue((queue) => removeId(queue,id))
	},[])

	const clearQueue = useCallback(() => setQueue([]), [])

	const value = { addToQueue, removeFromQueue, clearQueue }

	return (
		<Context.Provider value={value}>
			{children}
			<Processing inProgress={queue.length > 0} />
		</Context.Provider>
	)
}

export const useProcessing = () => useContext(Context);
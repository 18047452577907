import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as yup from 'yup';

import projectStructure from 'shared/data/project'
import { writePermissions, readPermissions } from '../../actions/helpers/permissions'

import project from '../../actions/project'

import Scaffold from '../../components/layout/scaffold'
import Label from '../../components/typography/label'
import TextField from '../../components/form/text-field'
import Button from '../../components/form/button'
import Modal from '../../components/feedback/modal'
import Box from '../../components/layout/box'
import Popout from '../../components/feedback/popout'
import Form from '../../components/layout/form'
import Divider from '../../components/layout/divider'
import Checkbox from '../../components/form/checkbox';

import { useCallouts } from '../../components/callout'
import { useProcessing } from '../../components/processing'

const Invitation = ({index, data, onChange}) => {
	const { t } = useTranslation();
	const [ settingsActive, setSettingsActive ] = useState(false);

	const handleEmailChange = (event) => {
		let value = {...data};
		const email = event.value || "";
		value.email = email.toLowerCase();
		onChange({index,data:value})
	}

	const handleAdminChange = (event) => {
		let value = {...data};
		value.admin = event.checked;
		onChange({index,data:value})
	}

	const handlePermissionChange = (event,id) => {
		let value = {...data};
		value.write[id] = event.checked;
		onChange({index,data:value})
	}
	
	return (
		<Box position="relative">
			<TextField value={data.email} placeholder={t("invitation.label.email")} onAction={(event) => setSettingsActive(true)} onChange={(event) => handleEmailChange(event)} actionLabel={t("member.action.settings")} />
			{settingsActive && 
				<Popout onClose={() => setSettingsActive(false)}>
					<Checkbox variant="switch" label="Admin" help={t("member.help.admin")} checked={data.admin} borderBottom={true} onChange={(event) => handleAdminChange(event)} />
					{projectStructure.map((group, index) =>(
						data.write[group.id] != null ? <Checkbox variant="switch" label={group.label} help={t("member.help.permission",{replace:{group:group.label}})} checked={data.write[group.id]} onChange={(event) => handlePermissionChange(event,group.id)} /> : null
					))}
				</Popout>
			}
		</Box>
	)
}

const AddProject = ({onClose, onComplete}) => {
	const { t } = useTranslation();
	const { addToQueue, removeFromQueue } = useProcessing();
	const { addCallout } = useCallouts();

	const blankInvitation = {
		email:'',
		admin:false,
		read: readPermissions(projectStructure,true),
		write: writePermissions(projectStructure,false),
	}

	const formik = useFormik({
		initialValues: {
			displayName: "",
			scientificName: "",
			organisation: "",
			invitations: [{...blankInvitation}]
		},
		initialErrors: {
			displayName: t("project.validation.displayName.required"),
			scientificName: t("project.validation.scientificName.required")
		},
		validationSchema: yup.object({
			displayName: yup.string()
				.required(t("project.validation.displayName.required")),
			scientificName: yup.string()
				.required(t("project.validation.scientificName.required")),
			organisation: yup.string(),
			invitations: yup.array().of(
				yup.object().shape({
					email: yup.string()
						.email(t("project.validation.email.valid"))
				})
			)
		}),
		onSubmit: (values) => {
			values.invitations = values.invitations.filter(invitation => invitation.email.length > 0)

			const pid = addToQueue();
			project.create(values)
			.then((result) => {
				removeFromQueue(pid);
				addCallout({
					message:t("project.alert.create.success"),
				})
				onComplete();
			})
			.catch((error) => {
				removeFromQueue(pid);
				addCallout({
					type: "error",
					message:t("project.alert.create.fail"),
					error: error
				})
			})
		},
	});


	const handleAddInvite = () => {
		const invitations = [...formik.values.invitations];
		invitations.push({...blankInvitation});
        formik.setValues({...formik.values, invitations})
	}

	const handleInvitationChange = (event) => {
		const invitations = [...formik.values.invitations];
		invitations[event.index] = event.data;
        formik.setValues({...formik.values, invitations})
	}

	return (
		<Modal onClose={onClose} heading={t("project.heading.create")}>
			<Form onSubmit={formik.handleSubmit}>
				<Scaffold direction="vertical" align="start" spaceBetween={2}>
					<TextField name="displayName" value={formik.values.displayName} placeholder={t("project.label.displayName")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.displayName && Boolean(formik.errors.displayName)} errorMessage={formik.errors.displayName} />
					<TextField name="scientificName" value={formik.values.scientificName} placeholder={t("project.label.scientificName")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.scientificName && Boolean(formik.errors.scientificName)} errorMessage={formik.errors.scientificName} />
					<TextField name="organisation" value={formik.values.organisation} placeholder={t("project.label.organisation")} onBlur={formik.handleBlur} onChange={formik.handleChange} hasError={formik.touched.organisation && Boolean(formik.errors.organisation)} errorMessage={formik.errors.organisation} />
				</Scaffold>

				<Scaffold direction="vertical" align="start" spaceBetween={1}>
					<Label>{t("invitation.heading.invite")}</Label>
					{formik.values.invitations.map((invitation,index) => <Invitation key={index} index={index} data={invitation} onChange={handleInvitationChange} />)}
					<Scaffold direction="horizontal" align="start" spaceBefore={1}>
						<Button variant="secondary" onClick={handleAddInvite} label={t("invitation.action.another")} />
					</Scaffold>
				</Scaffold>

				<Divider />
				
				<Scaffold direction="horizontal" align="end" spaceBetween={1}>
					<Button variant="secondary" onClick={onClose} label={t("project.action.cancel")} />
					<Button variant="primary" onClick={formik.handleSubmit} label={t("project.action.create")} disabled={!formik.isValid} />
				</Scaffold>
			</Form>
		</Modal>
	);
}

export default AddProject
import React, { Children } from "react";
import PropTypes from "prop-types";

import Box from "../box";

const Scaffold = ({
  children,
  spaceBetween,
  spaceBefore,
  spaceAfter,
  direction,
  align,
}) => {
  const count = Children.count(children);

  if (count === 0) {
    return null;
  }

  return (
    <Box
      direction={direction}
      mainAxisAlignment={align}
      crossAxisAlignment="stretch"
      marginTop={spaceBefore - spaceBetween * 0.5}
      marginBottom={spaceAfter - spaceBetween * 0.5}
      marginLeft={-(spaceBetween * 0.5)}
      marginRight={-(spaceBetween * 0.5)}
      flex="grow"
      wrapItems
    >
      {Children.map(children, (child) =>
        child !== null && child !== undefined ? (
          <Box
            padding={spaceBetween * 0.5}
            direction="vertical"
            mainAxisAlignment="center"
            crossAxisAlignment="stretch"
            position="relative"
          >
            {child}
          </Box>
        ) : null
      )}
    </Box>
  );
};

Scaffold.displayName = "Scaffold";

Scaffold.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  align: PropTypes.oneOf(["start", "end"]),
  spaceBetween: PropTypes.number,
  spaceBefore: PropTypes.number,
  spaceAfter: PropTypes.number,
};

Scaffold.defaultProps = {
  children: undefined,
  direction: "vertical",
  align: "start",
  spaceBetween: 0,
  spaceBefore: 0,
  spaceAfter: 0,
};

export default Scaffold;

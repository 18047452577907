import React, { Children } from "react";
import PropTypes from "prop-types";
import Scaffold from "../../layout/scaffold";

import { Container } from "./icon-button-group.styles.js";

const IconButtonGroup = ({
  children,
  size,
  direction,
  spaceBefore,
  spaceAfter,
  border,
}) => {
  const count = Children.count(children);

  if (count === 0) {
    return null;
  }

  return (
    <Container
      spaceBefore={spaceBefore}
      spaceAfter={spaceAfter}
      border={border}
    >
      <Scaffold direction={direction} spaceBetween={1}>
        {children}
      </Scaffold>
    </Container>
  );
};

IconButtonGroup.displayName = "IconButtonGroup";

IconButtonGroup.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  spaceBefore: PropTypes.number,
  spaceAfter: PropTypes.number,
  border: PropTypes.bool,
};

IconButtonGroup.defaultProps = {
  size: "medium",
  direction: "horizontal",
  spaceBefore: 0,
  spaceAfter: 0,
  border: true,
};

export default IconButtonGroup;

import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Box from "../../../components/layout/box";
import Scaffold from "../../../components/layout/scaffold";
import Text from "../../../components/typography/text";
import Heading from "../../../components/typography/heading";
import Menu from "../../../components/data-display/menu";
import Button from "../../../components/form/button";
import RelativeTime from "../../../components/relative-time";
import Divider from "../../../components/layout/divider";
import IconButton from "../../../components/form/icon-button";

import note from "../../../actions/note";
import profile from "../../../actions/profile";

import { useCallouts } from "../../../components/callout";
import { useProcessing } from "../../../components/processing";

const Metadata = ({ updatedBy, updatedTime }) => {
  const { t } = useTranslation();

  const [doc] = profile.useDocumentOnce(updatedBy);

  const [data, setData] = useState();

  useEffect(() => {
    if (doc) {
      let d = doc.data();

      if (d) {
        setData(d);
      }
    }
  }, [doc]);

  const updated = updatedTime ? updatedTime.toDate() : null;

  return (
    <Text size="small" variant="tertiary">
      {data
        ? `${t("note.description.updated", { replace: { ...data } })}, `
        : ""}
      <RelativeTime time={updated} />
    </Text>
  );
};

const Notes = ({
  structure,
  groupId,
  projectId,
  ideaId,
  ideaData,
  projectData,
  write,
  onAdd,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [snapshot, loading, error] = note.useCollection(projectId, ideaId, {
    groupId,
    state: note.STATE.ACTIVE,
  });

  const [data, setData] = useState(null);

  useEffect(() => {
    if (snapshot && snapshot.docs) {
      setData(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    }
  }, [snapshot]);

  const handleAddClick = () => {
    onAdd({ projectId, ideaId, groupId });
  };

  const handleEditClick = ({ noteId }) => {
    onEdit({ projectId, ideaId, groupId, noteId });
  };

  if (data) {
    // const notes = ideaData.notes ? ideaData.notes.filter(note => note.type === noteType) : []

    return (
      <Fragment>
        <Heading size="medium" spaceAfter={3}>
          {structure.label}
        </Heading>
        {data.length > 0 ? (
          <Box flex="grow" direction="vertical">
            {data.map((note, index) => (
              <Note
                key={index}
                {...note}
                projectId={projectId}
                ideaId={ideaId}
                write={write}
                index={index}
                onEdit={() => handleEditClick({ noteId: note.id })}
              />
            ))}
          </Box>
        ) : (
          <Box paddingBottom={2} direction="vertical">
            <Text variant="tertiary" spaceAfter={2}>
              {t("note.help.description")}
            </Text>
            <Divider />
          </Box>
        )}

        {write && (
          <Scaffold direction="horizontal" align="end" spaceBetween={1}>
            <Button
              variant="primary"
              size="medium"
              label={t("note.action.add")}
              onClick={handleAddClick}
            />
          </Scaffold>
        )}
      </Fragment>
    );
  }

  return null;
};

Notes.displayName = "Notes";

export default Notes;

const Note = ({
  heading,
  text,
  id,
  type,
  projectId,
  write,
  ideaId,
  metadata,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();
  const [menuActive, setMenuActive] = useState(false);

  const handleDelete = () => {
    const pid = addToQueue();
    note
      .archive(projectId, ideaId, id)
      .then((id) => {
        removeFromQueue(pid);
        addCallout({
          message: t("note.alert.delete.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("note.alert.delete.fail"),
          error: error,
        });
      });
  };

  return (
    <Box direction="vertical" paddingBottom={2}>
      <Box paddingBottom={2} crossAxisAlignment="center">
        <Box direction="vertical" flex="grow" mainAxisAlignment="center">
          <Heading size="small" spaceAfter={1}>
            {heading}
          </Heading>
          <Text size="medium" spaceAfter={2}>
            {text
              ? text.split("\n").map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))
              : ""}
          </Text>
          <Metadata {...metadata} />
        </Box>
        <Box flex="shrink" position="relative">
          {write && (
            <IconButton
              type="dots"
              color="grey"
              variant="secondary"
              selected={menuActive}
              onClick={() => setMenuActive(true)}
            />
          )}
          {menuActive && (
            <Menu
              verticalAlign="bottom"
              horizontalAlign="left"
              onClose={() => setMenuActive(false)}
              items={[
                {
                  label: t("note.action.edit"),
                  icon: "edit",
                  onClick: () => onEdit({ id }),
                },
                {
                  label: t("note.action.delete"),
                  icon: "bin",
                  confirm: "Sure?",
                  onClick: handleDelete,
                },
              ]}
            />
          )}
        </Box>
      </Box>

      <Divider />
    </Box>
  );
};

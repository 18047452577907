import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";

import { Container, Input, ErrorMessage } from "./text-area.styles.js";

const TextArea = forwardRef(
  (
    {
      id,
      name,
      value,
      variant,
      className,
      placeholder,
      errorMessage,
      hasError,
      helperText,
      disabled,
      autoResize,
      rows,

      onBlur,
      onChange,
      onFocus,
      onKeyDown,
    },
    ref
  ) => {
    const innerRef = useRef(null);
    useImperativeHandle(ref, () => innerRef.current);

    const [focused, setFocused] = useState(false);
    const [over, setOver] = useState(false);
    const [empty, setEmpty] = useState(value && value.length === 0);

    useEffect(() => {
      if (value && value.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }, [value]);

    const handleChange = (event) => {
      if (onChange) {
        onChange({ ...event, id, value: event.currentTarget.value });
      }
    };

    const handleBlur = (event) => {
      setFocused(false);
      if (onBlur) {
        onBlur({ ...event, id });
      }
    };

    const handleFocus = (event) => {
      setFocused(true);
      if (onFocus) {
        onFocus({ ...event, id });
      }
    };

    const handleKeyDown = (event) => {
      if (onKeyDown) {
        onKeyDown({ ...event, id });
      }
    };

    return (
      <Container>
        <Input
          as={autoResize ? TextareaAutosize : "textarea"}
          ref={innerRef}
          aria-describedby={errorMessage && focused ? `${id}-error` : null}
          aria-invalid={errorMessage || hasError ? "true" : "false"}
          placeholder={placeholder}
          variant={variant}
          value={value}
          disabled={!!disabled}
          id={id}
          name={name}
          className={className}
          hasError={hasError}
          over={over}
          focused={focused}
          empty={empty}
          rows={rows}
          minRows={rows}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
        />
        {errorMessage && hasError && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </Container>
    );
  }
);

TextArea.displayName = "TextArea";

TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(["outline", "inline"]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  autoResize: PropTypes.bool,
  rows: PropTypes.number,

  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
};

TextArea.defaultProps = {
  id: undefined,
  name: undefined,
  value: undefined,
  variant: "outline",
  className: undefined,
  placeholder: undefined,
  errorMessage: undefined,
  hasError: false,
  helperText: undefined,
  disabled: false,
  autoResize: false,
  rows: 1,

  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  onKeyDown: undefined,
};

export default TextArea;

import React from "react";
import PropTypes from "prop-types";

import { ListWrapper, ListContainer, ListItem } from "./list.styles.js";

import Heading from "../../typography/heading";
import Text from "../../typography/text";

const List = ({ heading, data, onClick, selected }) => {
  return (
    <ListWrapper>
      <ListContainer>
        <Heading size="small" level={3} align="left">
          {heading}
        </Heading>
        {data &&
          data.map(({ id, label }) => {
            const isSelected = selected === id;
            return (
              <ListItem
                selected={isSelected}
                key={id}
                onClick={() => onClick(id)}
              >
                <Text color={isSelected ? "green" : "grey"} variant="tertiary">
                  {label}
                </Text>
              </ListItem>
            );
          })}
      </ListContainer>
    </ListWrapper>
  );
};

List.propTypes = {
  heading: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

List.defaultProps = {};

export default List;

import React, { Children, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import Box from "../../layout/box";
import ActionRow from "../../layout/action-row";
import Icon from "../../feedback/icon";
import Text from "../../typography/text";
import Button from "../button";

import { DropdownButton, Container, DropdownBox } from "./dropdown.styles";

const Dropdown = ({
  type = "button",
  label,
  children,
  content,
  active,
  open,
  onClick,
  justify,
  ...props
}) => {
  const ref = useRef();

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    };

    if (open) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [ref, open]);

  return (
    <Box
      direction="vertical"
      mainAxisAlignment="start"
      crossAxisAlignment="start"
      position="relative"
      {...props}
    >
      {type === "button" && (
        <Button
          variant="tertiary"
          selected={active}
          down={open}
          label={label}
          iconEnd="chevron"
          onClick={onClick}
          fitContainer
          justify={justify}
        />
      )}
      {type === "input" && (
        <DropdownButton onClick={onClick} open={open} active={active}>
          <Text color={open ? "grey-02" : "grey"}>{label}</Text>
          <Icon variant="chevron" position="down" />
        </DropdownButton>
      )}
      {type === "input" && open && content && (
        <DropdownBox ref={ref}>
          {content.map((args) => (
            <ActionRow {...args} theme="secondary" key={`${args.name}`} />
          ))}
        </DropdownBox>
      )}
      {type === "button" && open && children && (
        <Container ref={ref}>
          {Children.map(children, (child) =>
            child !== null && child !== undefined ? child : null
          )}
        </Container>
      )}
    </Box>
  );
};

Dropdown.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  type: PropTypes.oneOf(["button", "input"]),
  children: PropTypes.element,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string,
      label: PropTypes.string,
      size: PropTypes.oneOf(["large", "small"]),
      icon: PropTypes.oneOf(["none", "bin", "note"]),
      actionType: PropTypes.oneOf(["none", "icon", "checkbox", "switch"]),
      checked: PropTypes.bool,
      roundedTop: PropTypes.bool,
      roundedBottom: PropTypes.bool,
      borderBottom: PropTypes.bool,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
    })
  ),
};

export default Dropdown;

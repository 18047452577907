import firebase from "firebase/app";
import {
  useDocument as useDocumentHook,
  useDocumentOnce as useDocumentOnceHook,
  useCollection as useCollectionHook,
} from "react-firebase-hooks/firestore";
import uniqid from "uniqid";
import get from "lodash/get";
import { Parser } from "json2csv";

import {
  prepareDataForCreate,
  prepareDataForUpdate,
  prepareDataForDuplicate,
  getUser,
} from "../helpers/firestore";
import { downloadBlob } from "../helpers/file";

import member from "../member";
import invitation from "../invitation";

import structure from "shared/data/project";

export const FIELDS = [
  "displayName",
  "scientificName",
  "organisation",
  "members",
  "users",
  "structure",
  "owner",
  "state",
  "shortlist",
  "assumptions",
  "prioritised",
];
export const COLLECTION_NAME = "projects";
export const STATE = {
  ACTIVE: "active",
  ARCHIVED: "archived",
};

const createData = (data) => {
  const userId = firebase.auth().currentUser.uid;

  return prepareDataForCreate(
    {
      users: [userId],
      structure: structure,
      owner: userId,
      state: STATE.ACTIVE,
      shortlist: {
        f: null,
        i: null,
        v: null,
        e: null,
        x: null,
      },
      assumptions: [],
      shortlisted: [],
      ...data,
    },
    FIELDS
  );
};

const updateData = (data) => {
  return prepareDataForUpdate(data, FIELDS);
};

const duplicateData = (data) => {
  return prepareDataForDuplicate(data, FIELDS);
};

export const exportCSV = (id) => {
  return new Promise((resolve, reject) => {
    const functions =
      window.location.hostname === "localhost" &&
      process.env.REACT_APP_EMULATOR === "true"
        ? firebase.functions()
        : firebase.app().functions("europe-west1");

    functions
      .httpsCallable("exportProjectData")({ projectId: id })
      .then((result) => {
        const data = result.data;

        try {
          const fields = [
            "Idea",
            "Group",
            "Parameter",
            "Score",
            "Confidence",
            "Note",
            "User",
            "Date",
          ];
          const parser = new Parser({ fields });
          const csv = parser.parse(data);
          console.log(csv);
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, "export.csv");
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const newDocumentRef = () => {
  return firebase.firestore().collection(COLLECTION_NAME).doc();
};

export const getDocumentRef = (id) => {
  return firebase.firestore().collection(COLLECTION_NAME).doc(id);
};

export const getCollectionRef = (
  { state = STATE.ACTIVE } = { state: STATE.ACTIVE }
) => {
  return firebase
    .firestore()
    .collection(COLLECTION_NAME)
    .where("users", "array-contains", firebase.auth().currentUser.uid)
    .where("state", "==", state)
    .orderBy("metadata.createdTime", "desc");
};

export const useDocument = (id) => {
  return useDocumentHook(getDocumentRef(id), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
};

export const useDocumentOnce = (id) => {
  return useDocumentOnceHook(getDocumentRef(id));
};

export const useCollection = (
  { state = STATE.ACTIVE } = { state: STATE.ACTIVE }
) => {
  return useCollectionHook(getCollectionRef({ state }), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
};

export const getDocument = (id) => {
  return getDocumentRef(id).get();
};

export const blank = () => {
  return {
    displayName: "",
    scientificName: "",
    organisation: "",
    invitations: [invitation.blank()],
  };
};

export const uploadDocumentFile = (projectId, file) => {
  return getDocumentFileRef(projectId, `${uniqid()}-${file.name}`).put(file, {
    contentType: file.type,
    customMetadata: { originalName: file.name },
  });
};

export const getDocumentFileRef = (projectId, filename) => {
  return firebase
    .storage()
    .ref()
    .child("documents")
    .child(projectId)
    .child(filename);
};

export const getDocumentFileURL = (projectId, filename) => {
  return getDocumentFileRef(projectId, filename).getDownloadURL();
};

export const getDocumentFileMetadata = (projectId, filename) => {
  return getDocumentFileRef(projectId, filename).getMetadata();
};

export const updateOwner = (projectId, userId) => {
  return updateDocument(projectId, { owner: userId });
};

export const create = ({ invitations, ...project }) => {
  return new Promise((resolve, reject) => {
    const userId = firebase.auth().currentUser.uid;
    const batch = firebase.firestore().batch();
    const projectRef = newDocumentRef();

    projectRef
      .set(createData(project))
      .then(() => {
        invitations.forEach((invitationData) => {
          invitation.createDocument(
            {
              ...invitationData,
              inviter: userId,
              project: projectRef.id,
            },
            batch
          );
        });

        member.createDocument(projectRef.id, {}, batch);

        batch
          .commit()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const update = (projectId, projectData) => {
  return updateDocument(projectId, projectData);
};

export const addEditAssumption = (projectId, assumptionId, values) => {
  return new Promise((resolve, reject) => {
    getDocument(projectId).then((doc) => {
      const data = doc.data();
      const assumptions = get(data, "assumptions", []);
      const assumption = assumptions.findIndex(({ id }) => id === assumptionId);
      if (assumption >= 0) {
        const item = assumptions[assumption];
        item.values = values;
        item.updatedBy = getUser();
        item.updatedTime = firebase.firestore.Timestamp.now();
        updateDocument(projectId, {
          assumptions,
        })
          .then(() => resolve("update"))
          .catch((error) => reject(error, "update"));
      } else {
        updateDocument(projectId, {
          assumptions: assumptions.concat([
            {
              id: assumptionId,
              values,
              createdBy: getUser(),
              createdTime: firebase.firestore.Timestamp.now(),
              updatedBy: getUser(),
              updatedTime: firebase.firestore.Timestamp.now(),
            },
          ]),
        })
          .then(() => resolve("add"))
          .catch((error) => reject(error, "add"));
      }
    });
  });
};

export const removeAssumption = (projectId, assumptionId) => {
  return new Promise((resolve, reject) => {
    getDocument(projectId).then((doc) => {
      const data = doc.data();
      const assumptions = get(data, "assumptions", []);
      updateDocument(projectId, {
        assumptions: assumptions.filter(({ id }) => id !== assumptionId),
      })
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  });
};

export const addRemoveIdeaFromPrioritised = (projectId, ideaId) => {
  return new Promise((resolve, reject) => {
    getDocument(projectId)
      .then((doc) => {
        const data = doc.data();
        const ideas = get(data, "prioritised", []);
        if (ideas.includes(ideaId)) {
          updateDocument(projectId, {
            prioritised: ideas.filter((i) => i !== ideaId),
          })
            .then(() => resolve())
            .catch((error) => reject(error));
        } else {
          updateDocument(projectId, {
            prioritised: ideas.concat(ideaId),
          })
            .then(() => resolve())
            .catch((error) => reject(error));
        }
      })
      .catch((error) => reject(error));
  });
};

export const addIdeaToShortlist = (projectId, key, ideaId) => {
  return new Promise((resolve, reject) => {
    getDocument(projectId)
      .then((doc) => {
        const data = doc.data();
        const ideas = get(data, `shortlist[${key}].ideas`, []);

        if (ideas.includes(ideaId)) {
          resolve();
        } else {
          updateDocument(projectId, {
            shortlist: {
              [key]: {
                ideas: ideas.concat(ideaId),
                updatedBy: getUser(),
                updatedTime: firebase.firestore.Timestamp.now(),
              },
            },
          })
            .then(() => resolve())
            .catch((error) => reject(error));
        }
      })
      .catch((error) => reject(error));
  });
};

export const removeIdeaFromShortlist = (projectId, key, ideaId) => {
  return new Promise((resolve, reject) => {
    getDocument(projectId)
      .then((doc) => {
        const data = doc.data();
        const ideas = get(data, `shortlist[${key}].ideas`, []);

        if (ideas.includes(ideaId)) {
          updateDocument(projectId, {
            shortlist: {
              [key]: {
                ideas: ideas.filter((i) => i !== ideaId),
                updatedBy: getUser(),
                updatedTime: firebase.firestore.Timestamp.now(),
              },
            },
          })
            .then(() => resolve())
            .catch((error) => reject(error));
        } else {
          resolve();
        }
      })
      .catch((error) => reject(error));
  });
};

export const duplicate = (projectId) => {
  return new Promise((resolve, reject) => {
    const functions =
      window.location.hostname === "localhost" &&
      process.env.REACT_APP_EMULATOR === "true"
        ? firebase.functions()
        : firebase.app().functions("europe-west1");

    getDocument(projectId)
      .then((doc) => {
        const data = doc.data();

        functions
          .httpsCallable("duplicateProject")({
            projectId,
            displayName: `Copy of ${data.displayName}`,
          })
          .then((result) => {
            resolve();
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
};

export const archive = (projectId) => {
  return updateDocument(projectId, { state: STATE.ARCHIVED });
};

export const createDocument = (data, batch) => {
  if (batch) {
    return batch.set(newDocumentRef(), createData(data));
  }
  return newDocumentRef().set(createData(data));
};

export const updateDocument = (projectId, data, batch) => {
  if (batch) {
    return batch.set(getDocumentRef(projectId), updateData(data), {
      merge: true,
    });
  }
  return getDocumentRef(projectId).set(updateData(data), { merge: true });
};

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getProjectPath } from "../../../routing/paths";

import idea from "../../../actions/idea";

import layout from "./prioritised.json";
import Box from "../../../components/layout/box";
import Summary from "../../../components/data-display/summary";
import Table from "../../../components/data-display/table";
import Modal from "../../../components/feedback/modal/modal.component";
import FormContent from "../../../components/form/form-content";
import PageError from "../../../components/page-error/page-error.component";

import { useProcessing } from "../../../components/processing";

import { useCallouts } from "../../../components/callout";

import {
  buildSummaryTable,
  buildTable,
  getPrioritised,
  onUpdate,
} from "./helper";

import { Container } from "./prioritised.styles";

const Prioritised = ({ projectId, ideasSnapshot, projectDocument }) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();
  const history = useHistory();
  const project = projectDocument.data();

  const [page, setPage] = useState(layout[0].id);
  const [points, setPoints] = useState([]);
  const [table, setTable] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    data: { label: "", id: "", column: "", columnLabel: "" },
  });
  const content = layout.find(({ id }) => id === page);
  const isOverview = content.id === "overview";
  const overviewContent = layout.find(({ id }) => id === modal.data.column);

  const getModalData = () => {
    const idea = ideasSnapshot.docs.find(({ id }) => id === modal.data.id);
    const currentContent = isOverview ? overviewContent : content;
    return idea
      .data()
      .prioritised?.find((_) => _.parameterId === currentContent.id)?.values;
  };

  const error = points.length <= 0;

  useEffect(
    () => setPoints(getPrioritised(ideasSnapshot, project?.prioritised)),
    []
  );

  useEffect(() => {
    if (points) {
      if (isOverview) {
        /* Overview page */
        const { columns, data } = buildSummaryTable(
          content,
          points,
          project,
          ideasSnapshot
        );
        setTable({ columns, data });
      } else {
        const { columns, data } = buildTable(content, points, ideasSnapshot);
        setTable({ columns, data });
      }
    }
  }, [points, content]);

  const handleSubmit = (values) => {
    const data = getModalData();
    const pid = addToQueue();
    data
      ? idea
          .updatePrioritisedContent(
            projectId,
            modal.data.id,
            overviewContent ? overviewContent.id : content.id,
            values
          )
          .then(() => {
            setTable({
              ...table,
              data: onUpdate(
                table,
                values,
                modal.data.id,
                isOverview,
                modal.data.column
              ),
            });
            removeFromQueue(pid);
            addCallout({
              message: t("prioritised.callout.update", {
                point: modal.data.label,
                content: content.title,
              }),
            });
          })
          .catch((error) => {
            removeFromQueue(pid);
            addCallout({
              type: "error",
              message: t("prioritised.callout.error-update", {
                point: modal.data.label,
                content: content.title,
              }),
              error: error,
            });
          })
      : idea
          .addPrioritisedContent(projectId, modal.data.id, {
            parameterId: overviewContent ? overviewContent.id : content.id,
            values,
          })
          .then(() => {
            setTable({
              ...table,
              data: onUpdate(
                table,
                values,
                modal.data.id,
                isOverview,
                modal.data.column
              ),
            });
            removeFromQueue(pid);
            addCallout({
              message: t("prioritised.callout.add", {
                point: modal.data.label,
                content: content.title,
              }),
            });
          })
          .catch((error) => {
            removeFromQueue(pid);
            addCallout({
              type: "error",
              message: t("prioritised.callout.error-add", {
                point: modal.data.label,
                content: content.title,
              }),
              error: error,
            });
          });
    setModal({
      ...modal,
      open: false,
      data: { label: "", id: "", column: "", columnLabel: "" },
    });
  };

  return (
    <Box flex="grow" direction="vertical">
      <Summary
        heading={content.title}
        description={content.description}
        button={page !== layout[0].id}
        label={layout[0].title}
        onClick={() => setPage(layout[0].id)}
        icon="chevron-left"
      />
      {error ? (
        <PageError
          heading={t("prioritised.error.empty.heading")}
          description={t("prioritised.error.empty.description")}
        />
      ) : (
        <>
          <Container>
            {table && (
              <Table
                columns={table.columns}
                data={table.data}
                width={content.width}
                draggable={content.draggable && table.data.length > 1}
                crossAxisAlignment="center"
                onClickLabel={(id) => {
                  history.push({
                    pathname: `${getProjectPath(projectId)}/option/${id}`,
                    state: {
                      label: "POINT",
                      key: "overview",
                      id: "point",
                    },
                  });
                }}
                onClickColumn={(id) => {
                  layout.find((page) => page.id === id && setPage(id));
                }}
                onClickCell={(label, id, column, columnLabel) => {
                  setModal({
                    ...modal,
                    open: true,
                    data: { label, id, column, columnLabel },
                  });
                }}
              />
            )}
          </Container>
          {modal.open && (
            <Modal
              heading={modal.data.label}
              headingSize="medium"
              color="green"
              align="left"
              onClose={() => {
                setModal({
                  ...modal,
                  open: false,
                  data: { label: "", id: "", column: "", columnLabel: "" },
                });
              }}
            >
              <FormContent
                data={getModalData()}
                parameters={
                  isOverview ? overviewContent.parameters : content.parameters
                }
                onSubmit={handleSubmit}
              />
            </Modal>
          )}
        </>
      )}
    </Box>
  );
};

export default Prioritised;

import * as yup from "yup";

const validationType = (type) => {
  switch (type) {
    case "text-input":
      return yup
        .string()
        .max(50, "You have reached the maximum character length.");
    case "text-area":
      return yup
        .string()
        .max(500, "You have reached the maximum character length.");
    default:
      return yup.string();
  }
};

export const setNestedFormik = (parameters, parameter) => {
  const initial = { [parameter?.id]: {} };
  const validation = {};
  const nested = {};
  const _ = {};

  parameters.map(({ id, type, nestedParameters }) => {
    if (!nestedParameters) {
      Object.assign(initial, { [id]: "" });
      Object.assign(validation, { [id]: validationType(type) });
    }
  });

  if (parameter) {
    parameter.options.map(({ value }) => {
      Object.assign(nested, { [value]: {} });
    });
  }
  parameter.nestedParameters.map(({ id, type }) => {
    Object.assign(_, { [id]: validationType(type) });
  });
  Object.keys(nested).map((key) => {
    nested[key] = yup.object().shape(_);
  });

  Object.assign(validation, { [parameter?.id]: yup.object().shape(nested) });
  return { initial, validation: yup.object().shape(validation) };
};

export const setFormik = (parameters) => {
  const initial = {};
  const validation = {};

  parameters.map(({ id, type }) => {
    Object.assign(initial, { [id]: "" });
    Object.assign(validation, { [id]: validationType(type) });
  });

  return { initial, validation: yup.object().shape(validation) };
};

export const setNestedParameters = (state, label, id) => {
  /* Function used to populate modal with nested content locally */
  if (!state.find((_) => _.id === id)) {
    const meta = {
      id,
      label,
      collapse: false,
    };
    if (state.length) {
      const arr = state.map((_) => {
        return { ..._, collapse: true };
      });
      return [...arr, meta];
    } else return [...state, meta];
  } else return [...state];
};

export const getNestedParameters = (values, parameter) => {
  /* Function to populate modal with nested content from database */
  const nested = values && Object.keys(values);
  if (nested) {
    return nested.map((_) => {
      const label = parameter.options.find(({ value }) => value === _)?.label;
      return { id: _, label, collapse: nested.length > 1 };
    });
  }
};

export const setNestedParameterInput = (
  value,
  formik,
  id,
  nestedId,
  parentId
) => {
  /* Function to set formik values for nested content */
  formik.setValues({
    ...formik.values,
    [parentId]: {
      ...formik.values[parentId],
      [nestedId]: {
        ...formik.values[parentId][nestedId],
        [id]: value,
      },
    },
  });
};

export const removeNestedParameter = (formik, id, parentId) => {
  const filtered = Object.keys(formik.values[parentId])
    .filter((key) => key !== id)
    .reduce((obj, key) => {
      obj[key] = formik.values[parentId][key];
      return obj;
    }, {});

  formik.setValues({
    ...formik.values,
    [parentId]: filtered,
  });
};

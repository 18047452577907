import firebase from 'firebase/app'

export const initializeAnalytics = () => {
	firebase.analytics();
}

export const logEvent = (name) => {
	firebase.analytics().logEvent(name);
}

export const pageView = (page) => {
	console.log("pageView",page)
}
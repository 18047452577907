import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import profile from "../../../../actions/profile";
import idea from "../../../../actions/idea";

import Box from "../../../../components/layout/box";
import Text from "../../../../components/typography/text";
import IconButton from "../../../../components/form/icon-button";
import Avatar from "../../../../components/form/avatar";
import Help from "../../../../components/feedback/help";
import Menu from "../../../../components/data-display/menu";

import { useCallouts } from "../../../../components/callout";
import { useProcessing } from "../../../../components/processing";

import { formatScore, formatConfidence } from "../../../../helpers/score";

import { ContentContainer, MenuContainer } from "./row.styles";

const EntryMenu = ({items}) => {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <MenuContainer>
      <IconButton
        color="grey"
        size="small"
        variant={"secondary"}
        type="dots"
        selected={menuActive}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setMenuActive(!menuActive);
        }}
      />
      {menuActive && (
        <Menu
          onClose={() => setMenuActive(false)}
          verticalAlign={"bottom"}
          horizontalAlign={"left"}
          items={items}
        />
      )}
    </MenuContainer>
  );
}

const Entry = ({
  projectId,
  ideaId,
  parameter,
  note,
  score,
  confidence,
  createdBy,
  createdTime,
  groupId,
  parameterId,
  borderBottom,
  write,
}) => {
  const [doc] = profile.useDocumentOnce(createdBy);
  
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const [data, setData] = useState();
  const [avatar, setAvatar] = useState();
  const [avatarURL, setAvatarURL] = useState();

  useEffect(() => {
    if (avatar) {
      profile.getAvatarURL(avatar).then((url) => {
        setAvatarURL(url);
      });
    } else {
      setAvatarURL(null);
    }
  }, [avatar]);

  useEffect(() => {
    if (doc) {
      let d = doc.data();
      if (d) {
        setData(d);
        setAvatar(d.avatar);
      }
    }
  }, [doc]);

  const handleDelete = () => {
    const pid = addToQueue();
    idea
      .deleteEntry(projectId, ideaId, {
        confidence,
        createdBy,
        createdTime,
        groupId,
        note,
        parameterId,
        score
      })
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("entry.alert.delete.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("entry.alert.delete.fail"),
          error: error,
        });
      })
  }
  
  let menu = null;
  if (write) {
    menu = [
      {
        label: t("entry.action.delete"),
        icon: "bin",
        confirm: "Sure?",
        onClick: handleDelete,
      },
    ];
  }

  return (
    <Box
      paddingLeft={3}
      paddingRight={write ? 3 : 6}
      paddingVertical={2}
      borderBottom={borderBottom}
    >
      
      <Box flex="grow" crossAxisAlignment="center" mainAxisAlignment="center" paddingRight={4}>
        <Box flex="grow" crossAxisAlignment="center" mainAxisAlignment="center">
          <Box flex="grow" crossAxisAlignment="start">
            <Avatar
              size="small"
              color="red"
              src={avatarURL}
              firstName={data ? data.firstName : ""}
              lastName={data ? data.lastName : ""}
            />
            <Box direction="vertical" marginLeft={1} paddingTop={0.5}>
              <Text size="small" bold>
                {data ? `${data.firstName} ${data.lastName}` : ""}
              </Text>
              <Text size="small">
                {note
                  ? note.split("\n").map((line, index) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))
                  : ""}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box width={12} crossAxisAlignment="center" mainAxisAlignment="center">
          <Text align="center">{formatScore(score.value, parameter.score)}</Text>
        </Box>
        <Box width={12} crossAxisAlignment="center" mainAxisAlignment="center">
          <Text align="center">
            {formatConfidence(confidence.value, parameter.confidence)}
          </Text>
        </Box>
      </Box>

      {menu && <EntryMenu items={menu} />}

    </Box>
  );
};

const History = ({ parameter, parameterId, projectId, ideaId, entries, write }) => {
  console.log(entries);

  if (entries.length > 0) {
    return (
      <Box direction="vertical">
        {entries
          .sort((a, b) => b.createdTime.toDate() - a.createdTime.toDate())
          .map((entry, index) => (
            <Entry
              key={index}
              projectId={projectId}
              ideaId={ideaId}
              parameter={parameter}
              {...entry}
              borderBottom={index < entries.length - 1}
              write={write}
            />
          ))}
      </Box>
    );
  }

  return null;
};

const Row = ({
  text,
  firstScore,
  secondScore,
  addButton,
  active,
  roundedBottom,
  roundedTop,

  projectId,
  ideaId,
  entries,
  parameter,
  addVisible,
  selected,
  hover,
  write,
  group,
  scoreValue,
  confidenceValue,
  historyAvailable,
  onSelect,
  onAdd,
  onMouseOver,
  onMouseOut,
  setVisibility,
}) => {
  return (
    <Box
      flex="grow"
      direction="vertical"
      rounding={1}
      marginBottom={selected && historyAvailable ? 2 : 0}
    >
      <Box
        flex="grow"
        paddingLeft={3}
        paddingRight={write ? 3 : 6}
        rounding={selected ? 0 : 1}
        paddingVertical={2}
        color={selected ? null : hover && historyAvailable ? "blue" : null}
        cursor={historyAvailable ? "pointer" : null}
        roundingTop={1}
      >
        <ContentContainer
          onMouseOver={() => onMouseOver()}
          onMouseOut={() => onMouseOut()}
          onClick={historyAvailable ? () => onSelect() : null}
        >
          <Box crossAxisAlignment="center" flex="grow">
            <Text>{parameter.label}</Text>
            {parameter.help && (
              <Box paddingLeft={1}>
                <Help
                  content={parameter.help}
                  color="white"
                  variant="question-mark"
                  size={0.75}
                />
              </Box>
            )}
          </Box>
          <Box
            width={12}
            crossAxisAlignment="center"
            mainAxisAlignment="center"
          >
            <Text align="center">
              {formatScore(scoreValue, parameter.score)}
            </Text>
          </Box>
          <Box
            width={12}
            crossAxisAlignment="center"
            mainAxisAlignment="center"
          >
            <Text align="center">
              {formatConfidence(confidenceValue, parameter.confidence)}
            </Text>
          </Box>
        </ContentContainer>

        {write && (
          <IconButton
            color="grey"
            size="small"
            type="add"
            onClick={(event) => {
              event.stopPropagation();
              onAdd({ projectId, ideaId, groupId: group.id, parameter });
            }}
          />
        )}
      </Box>
      {selected && (
        <History
          entries={entries}
          parameter={parameter}
          parameterId={parameter.id}
          projectId={projectId}
          ideaId={ideaId}
          write={write}
        />
      )}
    </Box>
  );
};

Row.displayName = "ScoreItemRow";

Row.propTypes = {
  text: PropTypes.string,
  firstScore: PropTypes.number,
  secondScore: PropTypes.number,
  addButton: PropTypes.bool,
  active: PropTypes.bool,
  roundedBottom: PropTypes.bool,
  roundedTop: PropTypes.bool,
};

Row.defaultProps = {
  addButton: false,
  active: false,
  roundedBottom: false,
  roundedTop: false,
};

export default Row;

import styled from "styled-components";

import { unit } from "../../../theme/metrics";

export const Content = styled.hr`
	${({ size, direction }) =>
    direction === "vertical"
      ? `height: ${size === "half" ? "50%" : "100%"}; width: 1px;`
      : `height: 1px; width: ${size === "half" ? "50%" : "100%"};`}
    background-color: ${({ theme }) => theme.keyline};
    border: none;
    margin: 0;
    display: block;
    ${({ direction, spaceBefore }) =>
      direction === "vertical"
        ? spaceBefore && `margin-left: ${unit(spaceBefore)}`
        : spaceBefore && `margin-top: ${unit(spaceBefore)}`};
    ${({ direction, spaceAfter }) =>
      direction === "vertical"
        ? spaceAfter && `margin-right: ${unit(spaceAfter)}`
        : spaceAfter && `margin-bottom: ${unit(spaceAfter)}`};
    ${({ size, direction }) =>
      size === "half" &&
      ((direction === "vertical" && `margin-top: 12.5%`) ||
        (direction === "horizontal" && `margin-left: 12.5%`))};
    
`;

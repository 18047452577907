import styled from "styled-components";

import { unit } from "../../../theme/metrics";

import {
  WHITE_01,
  ORANGE_01,
  GREY_01,
  GREEN_01,
  RED_01,
  PINK_01,
  BLUE_01,
  BEIGE_01,
} from "../../../theme/colors";

export const ToggleContainer = styled.div`
  position: absolute;
  top: ${unit(2)};
  right: ${unit(2)};
  border-radius: ${unit(1)};
  overflow: hidden;
  background-color: ${WHITE_01};
  display: flex;
  border: 1px solid ${GREY_01};
`;

export const ToggleOption = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  height: ${unit(5)};
  padding: 0 ${unit(2)};
  background-color: ${(props) =>
    props.selected
      ? props.color === "green"
        ? GREEN_01
        : props.color === "red"
        ? RED_01
        : props.color === "orange"
        ? ORANGE_01
        : props.color === "pink"
        ? PINK_01
        : props.color === "blue"
        ? BLUE_01
        : props.color === "beige"
        ? BEIGE_01
        : GREY_01
      : "transparent"};
  &:focus {
    outline: none;
  }
`;

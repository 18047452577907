import styled from "styled-components";
import { unit } from "../../../../theme/metrics";

export const Scroll = styled.div`
  height: ${() => `calc(100% - ${unit(8.5)})`};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

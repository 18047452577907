import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Box from "../../../components/layout/box";
import Scaffold from "../../../components/layout/scaffold";
import Text from "../../../components/typography/text";
import Heading from "../../../components/typography/heading";
import Help from "../../../components/feedback/help";
import TextArea from "../../../components/form/text-area";
import Button from "../../../components/form/button";
import RelativeTime from "../../../components/relative-time";

import comment from "../../../actions/comment";
import profile from "../../../actions/profile";

import { useCallouts } from "../../../components/callout";
import { useProcessing } from "../../../components/processing";

const Metadata = ({ updatedBy, updatedTime }) => {
  const { t } = useTranslation();

  const [doc] = profile.useDocumentOnce(updatedBy);

  const [data, setData] = useState();

  useEffect(() => {
    if (doc) {
      let d = doc.data();

      if (d) {
        setData(d);
      }
    }
  }, [doc]);

  const updated = updatedTime ? updatedTime.toDate() : null;

  return (
    <Text size="small" variant="tertiary">
      {data
        ? `${t("comment.description.updated", { replace: { ...data } })}, `
        : ""}
      <RelativeTime time={updated} />
    </Text>
  );
};

const Parameter = ({ projectId, ideaId, groupId, parameter, data, write }) => {
  const { t } = useTranslation();
  const { addCallout } = useCallouts();
  const { addToQueue, removeFromQueue } = useProcessing();

  const [text, setText] = useState("");
  const [editing, setEditing] = useState(false);
  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    if (data && !editing) {
      setText(data.text);
    }
  }, [data]);

  const handleTextChange = (event) => {
    setEditing(true);
    setEmpty(event.value.length === 0);
    setText(event.value);
  };

  const handleSaveClick = (event) => {
    setEditing(false);

    const pid = addToQueue();
    comment
      .update(projectId, ideaId, groupId, parameter.id, {
        parameterId: parameter.id,
        text: text,
      })
      .then((result) => {
        removeFromQueue(pid);
        addCallout({
          message: t("comment.alert.update.success"),
        });
      })
      .catch((error) => {
        removeFromQueue(pid);
        addCallout({
          type: "error",
          message: t("comment.alert.update.fail"),
          error: error,
        });
      });
  };

  const handleCancelClick = (event) => {
    setEditing(false);
    setText(data ? data.text : "");
  };

  return (
    <Scaffold spaceBetween={1} spaceAfter={2}>
      <Box marginBottom={1}>
        <Text>{parameter.label}</Text>
        {parameter.help && (
          <Box paddingLeft={1}>
            <Help
              content={parameter.help}
              color="white"
              variant="question-mark"
              size={0.75}
            />
          </Box>
        )}
      </Box>
      <Box>
        <Box flex="grow">
          {write ? (
            <TextArea
              name="text"
              value={text}
              onChange={handleTextChange}
              autoResize={true}
              variant="outline"
            />
          ) : (
            <Text spaceAfter={1} spaceBefore={1}>
              {text
                ? text.split("\n").map((line, index) => (
                    <Fragment key={index}>
                      {line}
                      <br />
                    </Fragment>
                  ))
                : ""}
            </Text>
          )}
        </Box>
      </Box>
      <Box direction="horizontal" crossAxisAlignment="center" height={4}>
        {data && data.metadata && <Metadata {...data.metadata} />}
        {editing && (
          <Scaffold direction="horizontal" spaceBetween={1} align="end">
            <Button
              label="Cancel"
              size="small"
              variant="secondary"
              onClick={handleCancelClick}
            />
            <Button label="Save" size="small" onClick={handleSaveClick} />
          </Scaffold>
        )}
      </Box>
    </Scaffold>
  );
};

const Comments = ({
  structure,
  groupId,
  projectId,
  ideaId,
  ideaData,
  projectData,
  write,
}) => {
  const [snapshot, loading, error] = comment.useCollection(
    projectId,
    ideaId,
    groupId
  );
  const [lookup, setLookup] = useState({});

  useEffect(() => {
    if (snapshot && snapshot.docs) {
      let l = {};

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        l[`${data.groupId}_${data.parameterId}`] = data;
      });

      setLookup(l);
    }
  }, [snapshot]);

  return (
    <Box marginBottom={3} flex="grow" direction="vertical">
      <Fragment>
        {structure.parameters.map((parameter, index) =>
          parameter.type === "heading" ? (
            <Heading key={parameter.id} size="medium" spaceAfter={3}>
              {parameter.label}
            </Heading>
          ) : (
            <Parameter
              key={parameter.id}
              data={lookup[`${groupId}_${parameter.id}`]}
              projectId={projectId}
              ideaId={ideaId}
              groupId={groupId}
              group={structure}
              ideaData={ideaData}
              projectData={projectData}
              parameter={parameter}
              write={write}
            />
          )
        )}
      </Fragment>
    </Box>
  );
};

Comments.displayName = "Comments";

export default Comments;

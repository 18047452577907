import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import { Redirect, useLocation } from "react-router-dom";

import { getDashboardPath } from "../../routing/paths";

import user from "../../actions/user";

import Page from "../../components/layout/page";
import PageError from "../../components/page-error";
import Form from "../../components/layout/form";
import Button from "../../components/form/button";
import TextField from "../../components/form/text-field";
import { useCallouts } from "../../components/callout";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Verify = () => {
  const { t } = useTranslation();

  const emailRequired = !user.isValidVerifyEmail();

  const [pageError, setPageError] = useState(
    user.isValidVerifyLink() ? null : "verificationLink"
  );

  const [verified, setVerified] = useState(false);
  const { addCallout } = useCallouts();
  const query = useQuery();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    initialErrors: {
      email: t("user.validation.email.required"),
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required(t("user.validation.email.required"))
        .email(t("user.validation.email.valid")),
    }),
    onSubmit: (values) => {
      user
        .verifySignInWithEmail(values.email)
        .then(() => {
          addCallout({
            message: t("user.alert.verify.success"),
          });
          setVerified(true);
        })
        .catch((error) => {
          if (error.code === "auth/expired-action-code") {
            setPageError("verificationExpired");
          } else if (error.code === "auth/invalid-action-code") {
            setPageError("verificationInvalid");
          } else {
            setPageError("verificationGeneral");
          }
        });
    },
  });

  useEffect(() => {
    if (user.isValidVerifyLink() && user.isValidVerifyEmail()) {
      user
        .verifySignInWithoutEmail()
        .then((result) => {
          setVerified(true);
        })
        .catch((error) => {
          if (error.code === "auth/expired-action-code") {
            setPageError("verificationExpired");
          } else if (error.code === "auth/invalid-action-code") {
            setPageError("verificationInvalid");
          } else {
            setPageError("verificationGeneral");
          }
        });
    }
  }, []);

  if (verified) {
    const redirect = query.get("redirect");
    return (
      <Redirect
        to={{
          pathname: redirect ? redirect : getDashboardPath(),
        }}
      />
    );
  }

  if (pageError)
    return (
      <PageError
        heading={t(`error.${pageError}.heading`)}
        description={t(`error.${pageError}.description`)}
      />
    );

  if (emailRequired) {
    return (
      <Page heading={t("user.heading.verifyEmailRequired")} size="small">
        <Form direction="horizontal" onSubmit={formik.handleSubmit}>
          <TextField
            name="email"
            value={formik.values.email}
            placeholder={t("user.label.email")}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            hasError={formik.touched.email && Boolean(formik.errors.email)}
            errorMessage={formik.errors.email}
          />
          <Button
            type="submit"
            size="large"
            variant="primary"
            disabled={!formik.isValid}
            onClick={formik.handleSubmit}
            label={t("user.action.sendLink")}
          />
        </Form>
      </Page>
    );
  }

  return <Page heading={t("user.heading.verify")} size="small" />;
};

export default Verify;

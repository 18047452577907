import styled from "styled-components";
import Color from "color";
import { unit } from "../../../theme/metrics";
import { GREY_01 } from "../../../theme/colors";

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${() => unit(1)};
  width: 100%;
  min-height: ${() => unit(15)};
  background-color: ${() => Color(GREY_01).alpha(0.3)};
  border-bottom: 1px solid ${({ theme }) => theme.keyline};
`;

export const Button = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: ${() => unit(2)};
  left: ${({ align }) => (align === "left" ? unit(3.25) : "")};
  right: ${({ align }) => (align === "right" ? unit(3.25) : "")};
  cursor: pointer;
`;

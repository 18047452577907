import React from "react";

import Text from "../../../../components/typography/text";
import Status from "../../../../components/data-display/status";

export const cellBuilder = (assumption, columns) => {
  const cells = Array(columns.length).fill("");

  let closed = false;
  Object.entries(assumption.values).forEach(([key, value]) => {
    if (key === "status") {
      const toggle = value ? value.toLowerCase() === "closed" : false;
      closed = toggle;
    }
  });
  const opacity = closed ? 0.4 : 1;

  Object.entries(assumption.values).forEach(([key, value]) => {
    const index = columns.findIndex(({ id }) => id === key);

    if (index >= 0) {
      if (key === "status") {
        const toggle = value.toLowerCase() !== "closed";
        cells[index] = value ? <Status toggle={toggle} /> : "";
      } else
        cells[index] = (
          <Text
            opacity={opacity}
            size={key === "assumption" ? "medium" : "small"}
          >
            {value}
          </Text>
        );
    }
  });
  return cells;
};

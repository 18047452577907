import { unit } from './metrics'

export const marginTop = (props) => props.marginTop ? unit(props.marginTop) : props.marginVertical ? unit(props.marginVertical) : props.margin ? unit(props.margin) : `0`;
export const marginBottom = (props) => props.marginBottom ? unit(props.marginBottom) : props.marginVertical ? unit(props.marginVertical) : props.margin ? unit(props.margin) : `0`;
export const marginLeft = (props) => props.marginLeft ? unit(props.marginLeft) : props.marginHorizontal ? unit(props.marginHorizontal) : props.margin ? unit(props.margin) : `0`;
export const marginRight = (props) => props.marginRight ? unit(props.marginRight) : props.marginHorizontal ? unit(props.marginHorizontal) : props.margin ? unit(props.margin) : `0`;

export const paddingTop = (props) => props.paddingTop ? unit(props.paddingTop) : props.paddingVertical ? unit(props.paddingVertical) : props.padding ? unit(props.padding) : `0`;
export const paddingBottom = (props) => props.paddingBottom ? unit(props.paddingBottom) : props.paddingVertical ? unit(props.paddingVertical) : props.padding ? unit(props.padding) : `0`;
export const paddingLeft = (props) => props.paddingLeft ? unit(props.paddingLeft) : props.paddingHorizontal ? unit(props.paddingHorizontal) : props.padding ? unit(props.padding) : `0`;
export const paddingRight = (props) => props.paddingRight ? unit(props.paddingRight) : props.paddingHorizontal ? unit(props.paddingHorizontal) : props.padding ? unit(props.padding) : `0`;


export const roundingTopLeft = (props) => props.roundingTopLeft ? unit(props.roundingTopLeft) : props.roundingTop ? unit(props.roundingTop) : props.rounding ? unit(props.rounding) : `0`;
export const roundingTopRight = (props) => props.roundingTopRight ? unit(props.roundingTopRight) : props.roundingTop ? unit(props.roundingTop) : props.rounding ? unit(props.rounding) : `0`;
export const roundingBottomRight = (props) => props.roundingBottomRight ? unit(props.roundingBottomRight) : props.roundingBottom ? unit(props.roundingBottom) : props.rounding ? unit(props.rounding) : `0`;
export const roundingBottomLeft = (props) => props.roundingBottomLeft ? unit(props.roundingBottomLeft) : props.roundingBottom ? unit(props.roundingBottom) : props.rounding ? unit(props.rounding) : `0`;

import React, { useState } from 'react'
import PropTypes from 'prop-types';

import { Container, Input } from './file-button.styles'

const FileButton = ({
	label,
	size,
	className,
	disabled,
	accept,
	accessibilityLabel,
	onChange,
	onMouseOver,
	onMouseOut,
}) => {
	const [ over, setOver ] = useState(false);

	return (
		<Container
			size={size}
			className={className}
			disabled={disabled}
			onMouseOver={(e) => {
				setOver(true)
				if(onMouseOver){onMouseOver(e)}
			}}
			onMouseOut={(e) => {
				setOver(false)
				if(onMouseOut){onMouseOut(e)}
			}}
			over={over}
		>
			<Input
				aria-label={accessibilityLabel}
				type="file"
				accept={accept}
				onChange={onChange}
			/>
			{label}
		</Container>
	);
}

FileButton.displayName = "FileButton";

FileButton.propTypes = {
	label: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	className: PropTypes.string,
	disabled: PropTypes.bool,
	accessibilityLabel: PropTypes.string,
	onChange: PropTypes.func,
	onMouseOver: PropTypes.func,
	onMouseOut: PropTypes.func,
};

FileButton.defaultProps = {
	label: undefined,
	size: 'medium',
	className: undefined,
	disabled: false,
	accessibilityLabel: undefined,
	onChange: undefined,
	onMouseOver: undefined,
	onMouseOut: undefined,
};

export default FileButton
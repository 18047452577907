import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import { BUBBLE_COLOR_SCHEME } from "../../theme/colors";

import Prioritised from "./prioritised";
import PlanToLearn from "./plan-to-learn";

import Idea from "../idea";
import IconButton from "../../components/form/icon-button";
import Visualisation from "../../components/layout/visualisation";
import Scaffold from "../../components/layout/scaffold";

import PageLoading from "../../components/page-loading";
import PageError from "../../components/page-error";

import Shortlist from "../../components/data-display/shortlist";

import AddIdea from "./add-idea";

import Options from "./options";

import idea from "../../actions/idea";
import project from "../../actions/project";

import { Container, ControlsContainer } from "./project.styles";

const generateVisualisationData = (projectData, ideasData, selected) => {
  if (projectData && ideasData) {
    const anySelected =
      Object.entries(selected).findIndex(
        ([groupKey, groupValue]) =>
          Object.entries(groupValue).findIndex(
            ([parameterKey, parameterValue]) => parameterValue
          ) >= 0
      ) >= 0;

    return ideasData.map((idea) => {
      let scoreTotal = 0;
      let confidenceTotal = 0;
      let count = 0;
      let active = false;
      let totals = [];

      if (idea.values) {
        projectData.structure
          .filter(
            (group) => group.type !== "comments" && group.type !== "notes"
          )
          .forEach((group) => {
            const groupValue = idea.values[group.id];
            if (groupValue) {
              const total = {
                id: group.id,
                label: group.label,
                score: {
                  total: 0,
                  count: 0,
                },
                confidence: {
                  total: 0,
                  count: 0,
                },
              };

              Object.entries(groupValue).forEach(
                ([parameterId, parameterValue]) => {
                  let score = get(parameterValue, `score.mean.percentage`);
                  let confidence = get(
                    parameterValue,
                    `confidence.mean.percentage`
                  );

                  if (score !== null && confidence !== null) {
                    total.score.total += score;
                    total.score.count++;
                    total.confidence.total += confidence;
                    total.confidence.count++;

                    if (
                      selected.length === 0 ||
                      selected.includes(`${group.id}.${parameterId}`)
                    ) {
                      scoreTotal += score;
                      confidenceTotal += confidence;
                      count++;
                      active = true;
                    }
                  }
                }
              );

              total.score.mean = total.score.total / total.score.count;
              total.confidence.mean =
                total.confidence.total / total.confidence.count;

              totals.push(total);
            }
          });
      }

      let parametersTotal = 0;
      let parametersComplete = 0;

      projectData.structure
        .filter((group) => group.type !== "comments" && group.type !== "notes")
        .forEach((group) => {
          group.parameters.forEach((parameter) => {
            parametersTotal++;
            parametersComplete += get(
              idea,
              `values[${group.id}][${parameter.id}]`
            )
              ? 1
              : 0;
          });
        });

      return {
        label: idea.name,
        description: idea.description,
        id: idea.id,
        score: count > 0 ? scoreTotal / count : 0,
        confidence: count > 0 ? confidenceTotal / count : count,
        completed: parametersComplete / parametersTotal,
        totals: totals,
        active: active,
        shortlisted: {
          f: get(projectData, "shortlist.f.ideas", []).includes(idea.id),
          i: get(projectData, "shortlist.i.ideas", []).includes(idea.id),
          v: get(projectData, "shortlist.v.ideas", []).includes(idea.id),
          e: get(projectData, "shortlist.e.ideas", []).includes(idea.id),
          x: get(projectData, "shortlist.x.ideas", []).includes(idea.id),
        },
      };
    });
  }

  return null;
};

const PageSuccess = ({ projectDocument, ideasSnapshot, projectId }) => {
  const { path, url } = useRouteMatch();

  const history = useHistory();

  const [ideasData, setIdeasData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [visualisationData, setVisualisationData] = useState(null);

  const [addIdeaVisible, setAddIdeaVisible] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    if (ideasSnapshot && ideasSnapshot.docs && ideasSnapshot.docs.length) {
      setIdeasData(
        ideasSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    }
  }, [ideasSnapshot]);

  useEffect(() => {
    if (projectDocument && projectDocument.exists) {
      setProjectData(projectDocument.data());
    }
  }, [projectDocument]);

  useEffect(() => {
    setVisualisationData(
      generateVisualisationData(projectData, ideasData, selectedOptions)
    );
  }, [projectData, ideasData, selectedOptions]);

  useEffect(() => {
    if (projectData && selectedFilter) {
      const ideas = get(projectData, `shortlist.${selectedFilter}.ideas`, []);
      if (ideas.length === 0) {
        setSelectedFilter(null);
      }
    }
  }, [projectData, selectedFilter]);

  const handleSelect = (event) => {
    history.push(`${url}/${event.id}`);
  };

  const handleOptionsChange = (event) => {
    let selected = [...selectedOptions];

    event.options.forEach((option) => {
      if (option.value === true) {
        selected.push(`${option.groupId}.${option.parameterId}`);
      } else {
        selected = selected.filter(
          (o) => o !== `${option.groupId}.${option.parameterId}`
        );
      }
    });

    setSelectedOptions(selected);
  };

  const handleFiltersChange = (event) => {
    setSelectedFilter(
      event.selected === selectedFilter ? null : event.selected
    );
  };

  return (
    <Container>
      {visualisationData && (
        <Visualisation
          data={visualisationData}
          selected={get(projectData, `shortlist.${selectedFilter}.ideas`)}
          colorScheme={
            selectedFilter
              ? BUBBLE_COLOR_SCHEME[selectedFilter]
              : BUBBLE_COLOR_SCHEME.default
          }
          onSelect={handleSelect}
        />
      )}
      {projectData && (
        <Controls
          projectData={projectData}
          selectedOptions={selectedOptions}
          selectedFilter={selectedFilter}
          onOptionsChange={handleOptionsChange}
          onFiltersChange={handleFiltersChange}
        />
      )}
      <IconButton
        type="add"
        size="xlarge"
        shadow={true}
        variant="primary"
        color={
          selectedFilter
            ? BUBBLE_COLOR_SCHEME[selectedFilter]
            : BUBBLE_COLOR_SCHEME.default
        }
        bottom={3}
        right={2}
        onClick={() => setAddIdeaVisible(true)}
      />
      {addIdeaVisible && (
        <AddIdea
          projectId={projectId}
          onClose={() => setAddIdeaVisible(false)}
          onComplete={() => setAddIdeaVisible(false)}
        />
      )}
      <Switch>
        <Route path={`${path}/:ideaId`}>
          <Idea />
        </Route>
      </Switch>
    </Container>
  );
};

const Controls = ({
  projectData,
  onOptionsChange,
  onFiltersChange,
  selectedFilter,
  selectedOptions,
}) => {
  return (
    <ControlsContainer>
      <Scaffold direction="horizontal" spaceBetween={2}>
        <Shortlist
          filter
          projectData={projectData}
          selected={selectedFilter}
          onChange={onFiltersChange}
        />
        <Options
          groups={projectData.structure}
          selected={selectedOptions}
          onChange={onOptionsChange}
        />
      </Scaffold>
    </ControlsContainer>
  );
};

const Project = (props) => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { projectId } = useParams();

  const [projectDocument, , projectError] = project.useDocument(projectId);
  const [ideasSnapshot, , ideasError] = idea.useCollection(projectId);

  const [pageError, setPageError] = useState(null);

  useEffect(() => {
    if (projectError || ideasError) {
      if (
        get(projectError, "code") === "permission-denied" ||
        get(ideasError, "code") === "permission-denied"
      ) {
        setPageError("notFound");
      } else {
        setPageError("general");
      }
    }
  }, [projectError, ideasError]);

  // ERROR
  if (pageError)
    return (
      <PageError
        heading={t(`error.${pageError}.heading`)}
        description={t(`error.${pageError}.description`)}
      />
    );

  // SUCCESS
  if (projectDocument && ideasSnapshot)
    return (
      <Switch>
        <Route path={`${path}/${t("project.navigation.option.id")}`}>
          <PageSuccess
            projectId={projectId}
            projectDocument={projectDocument}
            ideasSnapshot={ideasSnapshot}
          />
        </Route>
        <Route path={`${path}/${t("project.navigation.prioritised.id")}`}>
          <Prioritised
            projectId={projectId}
            projectDocument={projectDocument}
            ideasSnapshot={ideasSnapshot}
          />
        </Route>
        <Route path={`${path}/${t("project.navigation.planToLearn.id")}`}>
          <PlanToLearn
            projectId={projectId}
            projectDocument={projectDocument}
            ideasSnapshot={ideasSnapshot}
          />
        </Route>
        <Route
          exact
          path={`${path}/*`}
          render={() => (
            <Redirect to={`${url}/${t("project.navigation.option.id")}`} />
          )}
        />
      </Switch>
    );
  // LOADING
  return <PageLoading />;
};

export default Project;

import React from "react";
import PropTypes from "prop-types";

import Heading from "../../typography/heading";
import Text from "../../typography/text";
import IconButton from "../../form/icon-button";

import { Container, Button } from "./summary.styles";

const Summary = ({
  heading,
  description,
  button,
  label,
  onClick,
  buttonAlign = "left",
  icon,
}) => {
  return (
    <Container>
      <div>
        <Heading size="medium" level={2} align="center">
          {heading}
        </Heading>
      </div>
      <Text align="center" color="grey-01">
        {description}
      </Text>
      {button && (
        <Button onClick={onClick} align={buttonAlign}>
          <IconButton color="white" variant="primary" type={icon} />
          <Text>{label}</Text>
        </Button>
      )}
    </Container>
  );
};

Summary.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  buttonAlign: PropTypes.oneOf(["left", "right"]),
  icon: PropTypes.string,
};

export default Summary;

import styled from "styled-components";

import { typeLabel } from "../../../theme/typography";
import { unit } from "../../../theme/metrics";

import {
  GREEN_01,
  RED_01,
  BLACK_01,
  GREY_04,
  WHITE_01,
  NEW_GREY_02,
  GREY_00,
} from "../../../theme/colors";

export const Content = styled.span`
	${typeLabel}
	display: block;
	color: ${(props) =>
    props.color === "black"
      ? BLACK_01
      : props.color === "green"
      ? GREEN_01
      : props.color === "grey"
      ? GREY_04
      : props.color === "grey-2"
      ? NEW_GREY_02
      : props.color === "grey-0"
      ? GREY_00
      : props.color === "red"
      ? RED_01
      : props.color === "white"
      ? WHITE_01
      : props.variant === "tertiary"
      ? props.theme.foreground.tertiary
      : props.variant === "secondary"
      ? props.theme.foreground.secondary
      : props.theme.foreground.primary};
	${(props) => (props.align ? `text-align: ${props.align};` : ``)}
	${(props) =>
    props.spaceBefore ? `margin-top: ${unit(props.spaceBefore)};` : ``}
	${(props) =>
    props.spaceAfter ? `margin-bottom: ${unit(props.spaceAfter)};` : ``}
`;

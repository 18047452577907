import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import project from "../../../actions/project";

import { BUBBLE_COLOR_SCHEME } from "../../../theme/colors";

import AddEditAssumption from "./add-edit-assumption";
import AssumptionGraph from "./assumption-graph";

import Box from "../../../components/layout/box";
import IconButton from "../../../components/form/icon-button";
import DataTable from "../../../components/data-display/data-table";
import DisplayToggle from "../../../components/layout/display-toggle";
import Help from "../../../components/feedback/help";
import Scaffold from "../../../components/layout/scaffold";
import Dropdown from "../../../components/form/dropdown";
import Checkbox from "../../../components/form/checkbox";

import Shortlist from "../../../components/data-display/shortlist";

import { Container, ControlsContainer } from "./plan-to-learn.styles";
import { cellBuilder } from "./helper";
import { filter, orderBy, some, includes, concat, without, findIndex } from "lodash";

const Controls = ({
  projectData,
  ideasData,
  onIdeasChange,
  onFiltersChange,
  selectedFilter,
  selectedIdeas,
}) => {
  return (
    <ControlsContainer>
      <Scaffold direction="horizontal" spaceBetween={2}>
        <Shortlist
          filter
          projectData={projectData}
          selected={selectedFilter}
          onChange={onFiltersChange}
        />
        <FilterByIdea 
          projectData={projectData}
          ideasData={ideasData}
          selected={selectedIdeas}
          onChange={onIdeasChange}
        />
      </Scaffold>
    </ControlsContainer>
  );
};

const FilterByIdea = ({
  projectData,
  ideasData,
  selected,
  onChange
}) => {
  const [open, setOpen] = useState(false);

  console.log(projectData);
  console.log(ideasData);
  console.log(selected);
  
  return (
    <Dropdown
      label="Filter"
      active={selected.length > 0}
      open={open}
      onClick={() => setOpen(!open)}
    >
      {ideasData.map((idea) => (
          <FilterByIdeaItem
            key={idea.id}
            {...idea}
            selected={selected}
            onChange={onChange}
          />
        ))}
    </Dropdown>
  );
}

const FilterByIdeaItem = ({
  name,
  id,
  selected,
  onChange
}) => {
  
  
  return (
    <Checkbox
      label={name}
      checked={includes(selected,id)}
      onChange={() => onChange({selected:id})}
    />
  );
}

const PlanToLearn = ({ projectId, projectDocument, ideasSnapshot }) => {
  const history = useHistory()
  
  const [assumption, setAssumption] = useState(false);
  const [assumptionId, setAssumptionId] = useState("");
  const [graphData, setGraphData] = useState(null);
  const [selected, setSelected] = useState("table");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [rows, setRows] = useState([]);
  const [hover, setHover] = useState();
  const [sort, setSort] = useState({ id: "", order: "" });

  const [ideasData, setIdeasData] = useState();
  const [projectData, setProjectData] = useState();

  const [selectedIdeas,setSelectedIdeas] = useState([]);

  useEffect(() => {
    if (ideasSnapshot && ideasSnapshot.docs && ideasSnapshot.docs.length) {
      setIdeasData(
        ideasSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    }
  }, [ideasSnapshot]);

  useEffect(() => {
    if (projectDocument && projectDocument.exists) {
      setProjectData(projectDocument.data());
    }
  }, [projectDocument]);

  const setOrder = (id) => {
    const { order } = sort;
    if (sort.id === "" || sort.id === "impact" || sort.id === "certainty")
      return { id, order: "asc" };
    if (order === "asc") return { ...sort, order: "desc" };
    if (order === "desc") return { id: "", order: "" };
  };

  const assumptions = projectDocument.data()?.assumptions;
  const columns = [
    { header: "ID", width: 28, id: "id" },
    { header: "Assumption", width: 475, id: "assumption" },
    { header: "Options", width: 208, id: "options" },
    {
      header: "Area",
      width: 108,
      id: "area",
      sort: sort.id === "area",
      order: sort.order,
      onClick: () => setSort(setOrder("area")),
    },
    {
      header: (
        <Help
          content="Impact"
          variant="impact"
          background={false}
          size={3}
          color={sort.id === "impact" ? "green" : "default"}
        />
      ),
      justify: "center",
      padding: false,
      width: 56,
      id: "impact",
      onClick: () =>
        setSort(
          sort.id === "impact"
            ? { id: "", order: "" }
            : { id: "impact", order: "desc" }
        ),
    },
    {
      header: (
        <Help
          content="Certainty"
          variant="thumbs-up"
          background={false}
          size={3}
          color={sort.id === "certainty" ? "green" : "default"}
        />
      ),
      justify: "center",
      padding: false,
      width: 56,
      id: "certainty",
      onClick: () =>
        setSort(
          sort.id === "certainty"
            ? { id: "", order: "" }
            : { id: "certainty", order: "desc" }
        ),
    },
    { header: "Status", width: 88, id: "status" },
  ];

  useEffect(() => {
    if (assumptions) {
      const filtered = selectedIdeas.length > 0
        ? filter(assumptions, (assumption) => (
          findIndex(assumption.values.options, (option) => includes(selectedIdeas,option.id)) > -1
        ))
        : assumptions;

      
      const sorted = sort.id
        ? orderBy(assumptions, [`values.${sort.id}`], [sort.order])
        : filtered;

        /* Sorts data so that closed items appear at the end of the data table */
      const groupedClosed = [];
      const groupedOpen = [];
      sorted.forEach((_) => {
        if (_.values.status.toLowerCase() === "closed") groupedClosed.push(_);
        else return groupedOpen.push(_);
      });
      const grouped = [...groupedOpen, ...groupedClosed];

      const data = grouped.map((_) => {
        const isOver = _.id === hover;
        const cells = cellBuilder(
          projectId,
          _,
          columns,
          projectDocument.data(),
          selectedFilter,
          isOver,
          history
        );
        return {
          id: _.id,
          cells,
          onClick: () => setAssumptionId(_.id),
          menu: [
            {
              actionType: "icon",
              icon: "edit",
              label: "Edit assumption",
              onClick: () => setAssumptionId(_.id),
            },
            {
              actionType: "icon",
              icon: "bin",
              confirm: "Sure?",
              label: "Delete",
              onClick: () => project.removeAssumption(projectId, _.id),
            },
          ],
        };
      });

      if (JSON.stringify(rows) !== JSON.stringify(data)) setRows(data);
    }
  }, [assumptions, hover, sort]);

  useEffect(() => {
    if (assumptionId) setAssumption(true);
  }, [assumptionId]);

  const handleFiltersChange = (event) => {
    setSelectedFilter(
      event.selected === selectedFilter ? null : event.selected
    );
  };

  const handleIdeasChange = (event) => {
    let selected;
    console.log(event.selected);


    if(includes(selectedIdeas, event.selected)){
      selected = without(selectedIdeas, event.selected);
    }
    else{
      selected = concat(selectedIdeas, event.selected);
    }
    
    setSelectedIdeas(selected);
  };

  useEffect(() => {
    if (projectDocument.data()?.assumptions) {
      const data = projectDocument
        .data()
        ?.assumptions.map((_) => {
          if (_.values.status.toLowerCase() === "open")
            return {
              id: _.id,
              label: _.id,
              area: _.values.area,
              assumption: _.values.assumption,
              impact: _.values.impact,
              certainty: _.values.certainty,
            };
        })
        .filter((_) => _ != null);
      if (JSON.stringify(graphData) !== JSON.stringify(data))
        setGraphData(data);
    }
  }, [projectDocument.data()?.assumptions]);

  return (
    <Container isTable={selected !== "graph"}>
      <Box zIndex={1}>
        {projectData && ideasData && selected !== "graph" && (
          <Controls 
            projectData={projectData}
            ideasData={ideasData}
            selectedIdeas={selectedIdeas}
            selectedFilter={selectedFilter}
            onFiltersChange={handleFiltersChange}
            onIdeasChange={handleIdeasChange}
          />
        )}
        <DisplayToggle
          options={[
            {
              id: "table",
              icon: "align-left",
              iconSize: 2,
            },
            {
              id: "graph",
              icon: "graph",
              iconSize: 2,
            },
          ]}
          selected={selected}
          color="orange"
          onClick={(page) => setSelected(page)}
        />
      </Box>

      {selected === "graph" ? (
        <AssumptionGraph
          data={graphData}
          editBubble={(id) => setAssumptionId(id)}
        />
      ) : (
        <DataTable
          columns={columns}
          rows={rows}
          isOver={(id) => setHover(id)}
          scroll
        />
      )}
      <Box>
        <IconButton
          type="add"
          size="xlarge"
          shadow={true}
          variant="primary"
          color={
            selectedFilter
              ? BUBBLE_COLOR_SCHEME[selectedFilter]
              : BUBBLE_COLOR_SCHEME.default
          }
          bottom={3}
          right={2}
          onClick={() => setAssumption(!assumption)}
        />
      </Box>
      {assumption && (
        <AddEditAssumption
          onClose={() => {
            if (assumptionId) setAssumptionId("");
            setAssumption(!assumption);
          }}
          assumptionId={assumptionId}
          projectId={projectId}
          projectData={projectDocument.data()}
          ideasSnapshot={ideasSnapshot}
        />
      )}
    </Container>
  );
};

export default PlanToLearn;

import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import { WHITE } from "../../../theme/theme";
import { RED_01 } from "../../../theme/colors";

import { Content, ItemContainer, ItemContent } from "./menu.styles.js";

import Text from "../../typography/text";

const Item = ({ label, confirm, icon, borderTop, borderBottom, onClick }) => {
  const [over, setOver] = useState(false);
  const [checking, setChecking] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();

    if (confirm && !checking) {
      setChecking(true);
    } else {
      if (onClick) {
        onClick(event);
      }
    }
  };

  const handleOver = (event) => {
    event.stopPropagation();
    setOver(true);
  };

  const handleOut = (event) => {
    event.stopPropagation();
    setOver(false);
  };

  return (
    <ItemContainer
      borderTop={borderTop}
      borderBottom={borderBottom}
      over={over}
      onMouseOver={handleOver}
      onMouseOut={handleOut}
      onClick={handleClick}
    >
      <ItemContent>
        <Text color={checking ? "red" : null}>
          {checking ? confirm : label}
        </Text>
      </ItemContent>
      <Icon type={icon} checking={checking} />
    </ItemContainer>
  );
};

const Icon = ({ type, checking }) => {
  const color = checking ? RED_01 : WHITE.foreground.primary;

  switch (type) {
    case "bin":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 5H4.16667H17.5"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66699 4.99999V3.33332C6.66699 2.8913 6.84259 2.46737 7.15515 2.15481C7.46771 1.84225 7.89163 1.66666 8.33366 1.66666H11.667C12.109 1.66666 12.5329 1.84225 12.8455 2.15481C13.1581 2.46737 13.3337 2.8913 13.3337 3.33332V4.99999M15.8337 4.99999V16.6667C15.8337 17.1087 15.6581 17.5326 15.3455 17.8452C15.0329 18.1577 14.609 18.3333 14.167 18.3333H5.83366C5.39163 18.3333 4.96771 18.1577 4.65515 17.8452C4.34259 17.5326 4.16699 17.1087 4.16699 16.6667V4.99999H15.8337Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "edit":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16699 3.33334H3.33366C2.89163 3.33334 2.46771 3.50894 2.15515 3.8215C1.84259 4.13406 1.66699 4.55798 1.66699 5.00001V16.6667C1.66699 17.1087 1.84259 17.5326 2.15515 17.8452C2.46771 18.1577 2.89163 18.3333 3.33366 18.3333H15.0003C15.4424 18.3333 15.8663 18.1577 16.1788 17.8452C16.4914 17.5326 16.667 17.1087 16.667 16.6667V10.8333"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3418 2.17998C15.6705 1.8513 16.1163 1.66666 16.5811 1.66666C17.0459 1.66666 17.4917 1.8513 17.8203 2.17998C18.149 2.50865 18.3337 2.95442 18.3337 3.41924C18.3337 3.88405 18.149 4.32982 17.8203 4.6585L9.97169 12.5071L6.66699 13.3333L7.49317 10.0286L15.3418 2.17998Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "user":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.8753 17.7083V16.0417C16.8753 15.1576 16.5241 14.3098 15.899 13.6847C15.2739 13.0595 14.426 12.7083 13.542 12.7083H6.87533C5.99127 12.7083 5.14342 13.0595 4.5183 13.6847C3.89318 14.3098 3.54199 15.1576 3.54199 16.0417V17.7083"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.2083 9.37501C12.0493 9.37501 13.5417 7.88263 13.5417 6.04168C13.5417 4.20073 12.0493 2.70834 10.2083 2.70834C8.36738 2.70834 6.875 4.20073 6.875 6.04168C6.875 7.88263 8.36738 9.37501 10.2083 9.37501Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "sign-out":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.333 14.1667L17.4997 10L13.333 5.83334"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.5 10H7.5"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "copy":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.1665 12.5H3.33317C2.89114 12.5 2.46722 12.3244 2.15466 12.0118C1.8421 11.6992 1.6665 11.2753 1.6665 10.8333V3.33329C1.6665 2.89127 1.8421 2.46734 2.15466 2.15478C2.46722 1.84222 2.89114 1.66663 3.33317 1.66663H10.8332C11.2752 1.66663 11.6991 1.84222 12.0117 2.15478C12.3242 2.46734 12.4998 2.89127 12.4998 3.33329V4.16663"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "export":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 13L19 17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19L3 19C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17L1 13"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 8L10 13L15 8"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13L10 1"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "legal":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M14 2V8H20" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16 13H8" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16 17H8" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M10 9H9H8" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case "email":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99984 13.3333C11.8408 13.3333 13.3332 11.8409 13.3332 9.99996C13.3332 8.15901 11.8408 6.66663 9.99984 6.66663C8.15889 6.66663 6.6665 8.15901 6.6665 9.99996C6.6665 11.8409 8.15889 13.3333 9.99984 13.3333Z"
            stroke={color}
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6079 16.9433C13.061 17.97 11.2154 18.4506 9.36418 18.309C7.51293 18.1674 5.762 17.4116 4.3892 16.1615C3.01641 14.9115 2.10038 13.2388 1.78648 11.4089C1.47258 9.57894 1.77877 7.69659 2.65648 6.06049C3.53419 4.4244 4.93314 3.12827 6.63136 2.37778C8.32958 1.62728 10.2298 1.4654 12.0305 1.91783C13.8312 2.37025 15.4292 3.41106 16.571 4.87511C17.7128 6.33917 18.333 8.1426 18.3332 9.99926C18.3332 11.9167 17.6388 13.4712 15.9028 13.4712C14.1669 13.4712 13.4725 11.9167 13.4725 9.99926V6.52733"
            stroke={color}
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
  }

  return null;
};

const Menu = ({
  items,
  onClose,
  horizontalAlign = "right",
  verticalAlign = "bottom",
  absolute,
}) => {
  const contentRef = useRef();

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        if (onClose) {
          onClose();
        }
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [contentRef, onClose]);

  return (
    <ThemeProvider theme={WHITE}>
      <Content
        ref={contentRef}
        verticalAlign={verticalAlign}
        horizontalAlign={horizontalAlign}
        absolute={absolute}
      >
        {items &&
          items.map((item, index) => (
            <Item
              {...item}
              key={index}
              index={index}
              borderTop={index === 0}
              borderBottom={index === items.length - 1}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (onClose) {
                  onClose();
                }

                if (item.onClick) {
                  item.onClick(event);
                }
              }}
            />
          ))}
      </Content>
    </ThemeProvider>
  );
};

Menu.displayName = "Menu";

Menu.propTypes = {
  items: PropTypes.array,
  onClose: PropTypes.func,
  absolute: PropTypes.bool,
};

Menu.defaultProps = {
  onClose: undefined,
  absolute: true,
};

export default Menu;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Heading from "../../typography/heading";
import Label from "../../typography/label";
import Text from "../../typography/text";
import Help from "../../feedback/help";
import Icon from "../../feedback/icon/icon.component";

import {
  Container,
  HeaderRow,
  Content,
  ContentRow,
  NestedRow,
  LabelCell,
  Cell,
  DragCell,
  Divider,
  CellWrapper,
} from "./table.styles";

const Table = ({
  columns,
  data,
  draggable,
  width,
  crossAxisAlignment,
  mainAxisAlignment,
  onClickLabel,
  onClickColumn,
  onClickCell,
}) => {
  const [order, setOrder] = useState();
  const [heights, setHeights] = useState([]);
  let heightsArray = [];

  /* Function to take all heights of nested content and get the maximum values */
  /* Needed to keep nested cells in line */
  const setHeightRefs = useCallback((element, index) => {
    if (heightsArray[index]) {
      if (element && heightsArray[index] < element.offsetHeight)
        heightsArray[index] = element.offsetHeight;
    } else heightsArray[index] = element && element.offsetHeight;
    setHeights(heightsArray);
  }, []);

  useEffect(() => {
    if (data) setOrder(data);
  }, [data]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(order);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setOrder(items);
  };

  /* If no label exists within columns, don't render header */
  const displayColumns = columns.some((column) => column.label);

  return (
    <Container
      width={width}
      crossAxisAlignment={crossAxisAlignment}
      mainAxisAlignment={mainAxisAlignment}
    >
      {displayColumns && (
        <HeaderRow>
          <LabelCell />
          {columns.map(
            ({ id, width, clickableColumn, label, tooltip, align }) => {
              return (
                <CellWrapper
                  key={id}
                  click={clickableColumn}
                  width={width}
                  align={align ? align : "center"}
                >
                  <Cell onClick={() => clickableColumn && onClickColumn(id)}>
                    <Label variant="tertiary" color="grey-2">
                      {label}
                    </Label>
                  </Cell>
                  {tooltip && (
                    <Help
                      content={tooltip}
                      variant="question-mark"
                      color="white"
                      size={0.75}
                    />
                  )}
                </CellWrapper>
              );
            }
          )}
          {draggable && <DragCell />}
        </HeaderRow>
      )}
      <Content>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          isDragDisabled={() => !draggable}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {order &&
                  order.map(
                    ({ id, label, height, clickableLabel, cells, note }, o) => {
                      return (
                        <Draggable
                          key={id}
                          draggableId={id}
                          isDragDisabled={!draggable}
                          index={o}
                        >
                          {(provided) => {
                            return (
                              <ContentRow
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                drag={draggable}
                                height={height}
                              >
                                <LabelCell
                                  click={clickableLabel}
                                  onClick={() =>
                                    clickableLabel && onClickLabel(id)
                                  }
                                >
                                  <div>
                                    {displayColumns ? (
                                      <Heading
                                        align="left"
                                        size="small"
                                        variant="tertiary"
                                        color="grey-0"
                                      >
                                        {label}
                                      </Heading>
                                    ) : (
                                      <Label variant="tertiary" color="grey-2">
                                        {label}
                                      </Label>
                                    )}
                                  </div>
                                  {note && (
                                    <Help
                                      background={false}
                                      content={note}
                                      size={2}
                                      variant="align-left"
                                    />
                                  )}
                                </LabelCell>
                                {columns.map((_, index) =>
                                  Array.isArray(cells[index]) &&
                                  cells[index].length > 1 ? (
                                    <NestedRow
                                      key={`${id}${_.id}`}
                                      width={_.width}
                                    >
                                      {cells[index].map((cell, i) => {
                                        /* Adds width to divider to fill flex-gap */
                                        /* Only adds width if not the last item and has another nested column next to it */
                                        const addWidth =
                                          index <=
                                            columns.length -
                                              cells[index].length &&
                                          Array.isArray(cells[index + 1]);

                                        const divider =
                                          cells[index].length > 1 &&
                                          i !== cells[index].length - 1;
                                        return (
                                          <>
                                            <CellWrapper
                                              hover
                                              padding
                                              click={_.clickableCell}
                                              align={
                                                _.align ? _.align : "center"
                                              }
                                              onClick={() =>
                                                _.clickableCell &&
                                                onClickCell(
                                                  label,
                                                  id,
                                                  _.id,
                                                  _.label
                                                )
                                              }
                                            >
                                              <Cell
                                                ref={(element) => {
                                                  setHeightRefs(element, i);
                                                }}
                                                height={heights[i]}
                                              >
                                                <Text
                                                  size="small"
                                                  display="inline-flex"
                                                  gap={0.75}
                                                >
                                                  {cell}
                                                </Text>
                                              </Cell>
                                            </CellWrapper>
                                            {divider && (
                                              <Divider width={addWidth} />
                                            )}
                                          </>
                                        );
                                      })}
                                    </NestedRow>
                                  ) : (
                                    <CellWrapper
                                      key={`${id}${_.id}`}
                                      hover
                                      click={_.clickableCell}
                                      align={_.align ? _.align : "center"}
                                      width={_.width}
                                      onClick={() =>
                                        _.clickableCell &&
                                        onClickCell(label, id, _.id, _.label)
                                      }
                                    >
                                      <Cell>
                                        <Text
                                          size="small"
                                          display="inline-flex"
                                          gap={0.75}
                                        >
                                          {cells[index]}
                                        </Text>
                                      </Cell>
                                    </CellWrapper>
                                  )
                                )}

                                {draggable && (
                                  <DragCell>
                                    <Icon variant="drag" size={3} />
                                  </DragCell>
                                )}
                              </ContentRow>
                            );
                          }}
                        </Draggable>
                      );
                    }
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Content>
    </Container>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      tooltip: PropTypes.tooltip,
      summary: PropTypes.string,
      width: PropTypes.string,
      clickableCell: PropTypes.bool,
      clickableColumn: PropTypes.bool,
      nestedParameters: PropTypes.object,
      nested: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      note: PropTypes.string,
      cells: PropTypes.arrayOf(PropTypes.any),
    })
  ).isRequired,
};

export default Table;

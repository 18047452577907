import React from "react";
import PropTypes from "prop-types";

import Icon from "../../feedback/icon/icon.component";

import { ToggleContainer, ToggleOption } from "./display-toggle.styles";

const DisplayToggle = ({ options, selected, onClick, color }) => {
  return (
    <ToggleContainer>
      {options.map(({ id, icon, iconSize }, index) => (
        <ToggleOption
          color={color}
          onClick={() => onClick(id)}
          selected={selected === id}
          key={`${icon}${index}`}
        >
          <Icon
            color={selected === id ? "white" : "default"}
            variant={icon}
            iconSize={iconSize}
          />
        </ToggleOption>
      ))}
    </ToggleContainer>
  );
};

DisplayToggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      iconSize: PropTypes.number,
      id: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.oneOf(["green", "red", "orange", "pink", "blue", "beige"]),
};

DisplayToggle.defaultProps = {};

export default DisplayToggle;

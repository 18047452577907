import React from "react";

const DragIcon = ({ color }) => (
  <svg
    width="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="16" r="1" fill={color} />
    <circle cx="12" cy="16" r="1" fill={color} />
    <circle cx="18" cy="16" r="1" fill={color} />
    <circle cx="6" cy="8" r="1" fill={color} />
    <circle cx="12" cy="8" r="1" fill={color} />
    <circle cx="18" cy="8" r="1" fill={color} />
  </svg>
);

export default DragIcon;

import React from "react";
import get from "lodash/get";
import Shortlist from "../../../components/data-display/shortlist";
import Box from "../../../components/layout/box";
import Help from "../../../components/feedback/help/help.component";

/* File contains all functions used to create tables based on the prioritised.json */

/* Filter columns to not include any parameters that are for the modals only e.g notes */
const filterColumns = (parameters) =>
  parameters.filter((parameter) => !parameter.modal);

/* Function for building table that contains nested rows */
const buildNestedData = (data, columns) => {
  /* Build cell structure */
  const cells = columns.map((_) => {
    if (_.nested) {
      return ["-"];
    } else return "-";
  });
  let note;

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === "object") {
      const index = columns.findIndex(({ id }) => key === id);
      /* Add nested arrays with length incoming data */
      const row = Array(
        Object.keys(value).length > 0
          ? Object.keys(value).length - 1
          : Object.keys(value).length
      ).fill("-");
      cells.forEach(
        (_, index) => Array.isArray(_) && cells[index].push(...row)
      );
      cells[index] = Object.keys(value).length ? Object.keys(value) : ["-"];

      Object.entries(value).forEach(([_, value], i) => {
        Object.entries(value).forEach(([key, value]) => {
          const index = columns.findIndex(({ id }) => key === id);
          cells[index][i] = value ? value : "-";
        });
      });
    } else if (key !== "note") {
      const index = columns.findIndex(({ id }) => key === id);
      cells[index] = value ? value : "-";
    }
    if (key === "note") {
      note = value;
    }
  });
  return { note, cells };
};

export const buildSummaryTable = (content, points, project, ideasSnapshot) => {
  /* 
      Function builds out the summary table by doing a lookup on each columns summary id 
      and then inserting the data from the db 
  */
  const columns = filterColumns(content.parameters);
  const summary = columns
    .map(({ id, summary }) => {
      return { id, summary };
    })
    .filter((_) => _ != null);

  const data = points.map((_) => {
    const idea = ideasSnapshot.docs.find(({ id }) => id === _.id);
    let fivex;
    const content = summary.map(({ id, summary }) => {
      if (id === "fivex") {
        Object.entries(get(project, "shortlist", [])).forEach(([, value]) => {
          if (value && value.ideas.includes(_.id))
            fivex = (
              <Shortlist
                table
                projectData={project}
                ideaId={_.id}
                border={false}
              />
            );
        });
      }
      const cell = idea
        .data()
        .prioritised?.find(({ parameterId }) => parameterId === id);
      if (cell && Object.keys(cell.values).includes(summary)) {
        const note = cell.values.note;
        return cell.values[summary] ? (
          <>
            {cell.values[summary]}
            {note && (
              <Box crossAxisAlignment="center">
                <Help
                  background={false}
                  variant="align-left"
                  size={2}
                  content={note}
                ></Help>
              </Box>
            )}
          </>
        ) : (
          "-"
        );
      } else return "-";
    });
    /* Custom logic for fivex as it's stored differently */
    if (fivex) content[columns.findIndex(({ id }) => id === "fivex")] = fivex;
    return { ..._, cells: content };
  });
  return { columns, data };
};

export const buildTable = (content, points, ideasSnapshot) => {
  /* Functions builds table by looking up data within an ideas prioritised object */
  const columns = filterColumns(content.parameters);
  /* If table contains nested parameters, add these as column headers */
  columns
    .map(({ nestedParameters }, index) => {
      if (nestedParameters) {
        /* Renders column headers after the parameter index it came from */
        columns.splice(index + 1, 0, ...nestedParameters);
      }
    })
    .filter((_) => _ != null);

  const data = points.map((prioritised) => {
    const empty = Array(columns.length).fill("-");
    const cells = Array(columns.length).fill("-");
    const idea = ideasSnapshot.docs.find(({ id }) => id === prioritised.id);
    const cell = idea
      .data()
      .prioritised?.find((t) => t.parameterId === content.id);
    let note = "";
    if (cell && cell.values) {
      const nested = Object.entries(cell.values).some(
        ([, value]) => typeof value === "object"
      );
      if (nested) {
        const { note, cells } = buildNestedData(cell.values, columns);
        return { ...prioritised, note, cells };
      } else {
        Object.entries(cell.values).map(([key, value]) => {
          if (key !== "note") {
            const index = columns.findIndex(({ id }) => id === key);
            cells[index] = value ? value : "-";
          } else {
            note = value;
          }
        });
        return { ...prioritised, cells, note };
      }
    } else return { ...prioritised, cells: empty, note };
  });
  return { columns, data };
};

export const getPrioritised = (ideasSnapshot, prioritised) => {
  /* Function gets a list of prioritised ideas to populate the table */
  const _ = [];
  ideasSnapshot.docs.map((doc) => {
    if (prioritised?.includes(doc.id)) {
      const { name } = doc.data();
      _.findIndex(({ id }) => id === doc.id) === -1 &&
        _.push({
          id: doc.id,
          label: name,
          clickableLabel: true,
          cells: [""],
        });
    }
  });
  return _;
};

export const onUpdate = (table, values, id, isOverview, column) => {
  /* Function locally updates the table on data update/creation */
  const idea = table.data.findIndex((_) => _.id === id);
  const _ = [...table.data];
  const nested = Object.entries(values).some(
    ([, value]) => typeof value === "object"
  );
  if (isOverview) {
    table.columns.map(({ id, summary }, index) => {
      if (id === column) {
        Object.entries(values).forEach(([key, value]) => {
          const note = values.note;
          if (summary === key) {
            _[idea].cells[index] = (
              <>
                {value}
                {note && (
                  <Box crossAxisAlignment="center">
                    <Help
                      background={false}
                      variant="align-left"
                      size={2}
                      content={note}
                    ></Help>
                  </Box>
                )}
              </>
            );
          }
        });
      }
    });
    return _;
  } else {
    if (nested) {
      const { note, cells } = buildNestedData(values, table.columns);
      _[idea].cells = cells;
      _[idea].note = note;
      return _;
    } else {
      Object.entries(values).forEach(([key, value]) => {
        if (key !== "note") {
          const index = table.columns.findIndex(({ id }) => id === key);
          _[idea].cells[index] = value ? value : "-";
        }
      });
      return _;
    }
  }
};

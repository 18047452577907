import get from 'lodash/get'

import { BLACK_01, DARK_GREY_01, GREY_01, WHITE_01, BLUE_WASH, DARK_GREY_02, GREY_04, GREEN_06 } from './colors'

export const BLACK = {
	keyline: DARK_GREY_02,
	background:{
		primary: BLACK_01,
		secondary: GREEN_06,
		tertiary: BLACK_01,
	},
	foreground:{
		primary: WHITE_01,
		secondary: "rgba(255,255,255,0.7)",
		tertiary: "rgba(255,255,255,0.7)",
	}
}

export const WHITE = {
	keyline: GREY_01,
	background:{
		primary: WHITE_01,
		secondary: BLUE_WASH,
		tertiary: WHITE_01,
	},
	foreground:{
		primary: BLACK_01,
		secondary: DARK_GREY_01,
		tertiary: GREY_04,
	}
}

const stringToTheme = (color) => {
	if(color){
		switch(color.toLowerCase()){
			case "white" :
				return WHITE;
			case "black" :
				return BLACK;
			default:
				return WHITE;
		}
	}

	return WHITE
}

const hexToTheme = (hex) => {
	return [WHITE,BLACK].find(theme => get(theme,"background.primary") === hex)
}

export const getColor = (color,variant="primary") => {
	const theme = stringToTheme(color);
	return get(theme,`background.${variant.toLowerCase()}`);
}

export const getTheme = (color,def) => {
	return stringToTheme(color) || stringToTheme(def);
}

export const getThemeByHex = (hex) => {
	return hexToTheme(hex);
}
import styled from "styled-components";
import Color from "color";

import { spacer, unit } from "../../../theme/metrics";
import { dropShadow } from "../../../theme/general";
import { DARK_GREY_01 } from "../../../theme/colors";

import ModalMenu from "../modal-menu";

export const Inner = styled.div``;

export const Container = styled.div`
  position: fixed;
  top: ${(props) =>
    props.size === "large" || props.size === "xlarge" ? unit(8) : `0`};
  left: 0;
  width: 100%;
  height: ${(props) =>
    props.size === "large" || props.size === "xlarge"
      ? `calc(100% - ${unit(8)})`
      : `100%`};
  display: flex;
  align-items: ${(props) =>
    props.size === "large" || props.size === "xlarge"
      ? "flex-start"
      : "center"};
  justify-content: center;
  backdrop-filter: blur(5px) saturate(60%);
  background: rgba(11, 11, 14, 0.1);
  overflow: scroll;

  ${Inner} {
    padding: ${(props) =>
      props.size === "large" || props.size === "xlarge" ? `0` : spacer(4)};
  }
  z-index: 1;
`;

export const Scrollable = styled.div`
  max-height: 100%;
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${unit(4)};
  height: ${unit(4)};
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  border-radius: ${unit(2)};
  border: 1px solid ${(props) => props.theme.keyline};

  &:hover {
    border: 1px solid ${(props) => Color(DARK_GREY_01).alpha(0.5)};
  }

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    content: "";
    width: ${unit(1.5)};
    height: 1px;
    background-color: ${(props) => props.theme.foreground.primary};
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  right: ${unit(6)};
`;

export const StyledModalMenu = styled(ModalMenu)`
  top: ${(props) =>
    props.size === "large" || props.size === "xlarge" ? unit(8) : unit(2)};
  right: ${(props) =>
    props.size === "large" || props.size === "xlarge" ? unit(13) : unit(7)};
`;

export const Content = styled.div`
  width: ${(props) =>
    props.size === "small" ? 360 : props.size === "large" ? 860 : 464}px;
  background-color: #ffffff;
  position: relative;
  border-radius: ${(props) =>
    props.size === "large" || props.size === "xlarge" ? "0" : unit(2)};
  padding: ${(props) =>
    props.size === "large" || props.size === "xlarge"
      ? `${unit(8)} ${unit(10)}`
      : `${unit(5)} ${unit(4)} ${unit(5)} ${unit(4)}`};
  margin: ${(props) =>
    props.size === "large" || props.size === "xlarge"
      ? `0 0 0 auto`
      : `0 auto`};
  ${(props) =>
    props.size === "large" || props.size === "xlarge"
      ? `min-height: 100vh;`
      : ``};
  ${dropShadow}

  ${CloseButton} {
    top: ${(props) =>
      props.size === "large" || props.size === "xlarge" ? unit(8) : unit(2)};
    right: ${(props) =>
      props.size === "large" || props.size === "xlarge" ? unit(8) : unit(2)};
  }
`;

export const Header = styled.header`
  margin: 0 auto;
  padding: 0 0 ${unit(3)} 0;
  word-break: break-word;
`;

export const HeadingContainer = styled.div`
  max-width: ${spacer(12)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

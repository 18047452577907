import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { Content } from "./heading.styles.js";

const Heading = forwardRef(
  (
    { children, size, level, align, color, spaceBefore, spaceAfter, variant },
    ref
  ) => {
    const getType = (l) => (l && l > 0 ? `h${l}` : `span`);

    return (
      <Content
        variant={variant}
        color={color}
        size={size}
        align={align}
        as={getType(level)}
        spaceAfter={spaceAfter}
        spaceBefore={spaceBefore}
      >
        {children}
      </Content>
    );
  }
);

Heading.displayName = "Heading";

Heading.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  level: PropTypes.oneOf([1, 2, 3]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  color: PropTypes.oneOf([
    "black",
    "grey",
    "grey-0",
    "dark-grey",
    "green",
    "red",
  ]),
  spaceBefore: PropTypes.number,
  spaceAfter: PropTypes.number,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
};

Heading.defaultProps = {
  size: "large",
  level: undefined,
  align: "left",
  color: undefined,
  spaceBefore: 0,
  spaceAfter: 0,
  variant: "primary",
};

export default Heading;
